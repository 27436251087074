import React from 'react'
import PropTypes from 'prop-types'
import LabelWithValue from '../../../components/LabelWithValue'

import IconButton from '../../../components/IconButton'
import ExternalLinkSvg from '../../../components/_svg/ExternalLink'

import './order-tracking-status.scss'

const OrderTrackingStatus = ({
  statusLabel,
  status,
  dateLabel,
  date,
  carrierLabel,
  carrier,

  //TEMP!!
  trackingLinkLabel,
  trackingLabel,
  trackingInfo
}) => (
  <div className="order-tracking-status">
    <div className="order-tracking-status-information">
      <LabelWithValue label={statusLabel} value={status} />

      {date && (
        <LabelWithValue
          label={dateLabel}
          value={new Date(date).toLocaleDateString()}
        />
      )}

      {carrier && <LabelWithValue label={carrierLabel} value={carrier} />}

      {trackingInfo &&
        trackingInfo.map(({ trackingNumber, trackingUrl }) => (
          <div
            className="order-tracking-status__tracking-item"
            key={trackingNumber}
          >
            <LabelWithValue label={trackingLabel} value={trackingNumber} />

            <IconButton
              className="order-tracking-status-information__track-button"
              elementTagName="a"
              href={trackingUrl}
              target="_blank"
              SvgIcon={ExternalLinkSvg}
            >
              {trackingLinkLabel}
            </IconButton>
          </div>
        ))}
    </div>
  </div>
)

OrderTrackingStatus.propTypes = {
  statusLabel: PropTypes.string,
  status: PropTypes.string,
  dateLabel: PropTypes.string,
  date: PropTypes.string,
  carrierLabel: PropTypes.string,
  carrier: PropTypes.string
}

export default OrderTrackingStatus
