import ReactForm from './ReactForm'
import OrderListing from './OrderListing'
import CertificateDownload from './CertificateDownload'
import VideoComponent from './VideoComponent/VideoComponent'
import CarouselBlockComponent from './CarouselBlockComponent'
import ImageBannerCarousel from './ImageBannerCarousel'
import Adyen from './Adyen/Adyen'
import Header from './Header'
// import MegaMenu from './MegaMenu'
import FooterMenu from './Footer/FooterMenu'
import TopHero from './TopHero'
import Newsletter from './Newsletter'
import TopContent from './TopContent'
import ProductCard from './ProductCard'
import ProductCardPage from '../pages/ProductCardPage'
import ContentArea from './ContentArea'
import RichTextBlock from './RichTextBlock'
import BrandCard from './BrandCard'
import NlgBlock from './NlgBlock'
import FlexContainerBLock from './FlexContainerBlock'
import VideoBlock from './VideoBlock'

export default {
  ReactForm,
  OrderListing,
  CertificateDownload,
  VideoComponent,
  ImageBannerCarousel,
  CarouselBlockComponent,
  Adyen,
  Header,
  FooterMenu,
  TopHero,
  Newsletter,
  TopContent,
  ProductCard,
  ProductCardPage,
  ContentArea,
  RichTextBlock,
  BrandCard,
  NlgBlock,
  FlexContainerBLock,
  VideoBlock
}
