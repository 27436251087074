import Box from '@material-ui/core/Box'
import React from 'react'
import './brandCard.scss'
import { classNames } from '../../utils/classNames'
import NordLockBrand from '../../components/_svg/NordLockBrand'
import NordLockBrandInvert from '../../components/_svg/NordLockBrandInvert'
import ExpanderBrand from '../../components/_svg/ExpanderBrand'
import ExpanderBrandInvert from '../../components/_svg/ExpanderBrandInvert'
import BoltightBrand from '../../components/_svg/BoltightBrand'
import BoltightBrandInvert from '../../components/_svg/BoltightBrandInvert'
import SuperBoltBrand from '../../components/_svg/SuperBoltBrand'
import SuperBoltBrandInvert from '../../components/_svg/SuperBoltBrandInvert'

export type BrandCardProps = {
  ctaUrl: string | null
  description: string | null
  image: string | null
  type: string | null
}

type Props = {
  props: BrandCardProps
}

const getBrand = (type: string | null) => {
  switch (type) {
    case '0':
      return <NordLockBrand />
    case '1':
      return <ExpanderBrand />
    case '2':
      return <BoltightBrand />
    case '3':
      return <SuperBoltBrand />
    default:
      return null
  }
}

const getBrandInvert = (type: string | null) => {
  switch (type) {
    case '0':
      return <NordLockBrandInvert />
    case '1':
      return <ExpanderBrandInvert />
    case '2':
      return <BoltightBrandInvert />
    case '3':
      return <SuperBoltBrandInvert />
    default:
      return null
  }
}

const getBackgroundClass = (type: string | null) => {
  switch (type) {
    case '0':
      return 'nordlock'
    case '1':
      return 'expander'
    case '2':
      return 'boltight'
    case '3':
      return 'superbolt'
    default:
      return null
  }
}

const BrandCard = ({ props }: Props) => {
  const { ctaUrl, description, image, type } = props

  const [isHovered, setIsHovered] = React.useState<boolean>(false)

  return (
    <Box
      className={classNames(
        'brandCard',
        isHovered && 'is-active',
        getBackgroundClass(type)
      )}
      onMouseEnter={(e: React.MouseEvent) => setIsHovered(true)}
      onMouseLeave={(e: React.MouseEvent) => setIsHovered(false)}
    >
      {ctaUrl && <a href={ctaUrl} className="brandCard-link" />}
      {image && (
        <img
          src={image}
          alt={description ? description : 'brand'}
          height="256"
          className="brandCard-image"
        />
      )}
      {description && (
        <Box className="brandCard-content">
          {type !== null && (
            <>
              <div className="brand-icon">{getBrand(type)}</div>
              <div className="brand-icon invert">{getBrandInvert(type)}</div>
            </>
          )}
          <p className="brandCard-description">{description}</p>
        </Box>
      )}
    </Box>
  )
}

export default BrandCard
