import React from 'react'
import PropTypes from 'prop-types'
import BaseInput from '../BaseInput'
import { classNames } from '../../../utils/classNames'

NumberInput.propTypes = {
  ...BaseInput.propTypes,

  value: PropTypes.number
}

export default function NumberInput({
  value,

  className,

  onChange,

  ...baseInputProps
}) {
  const elementValue = value === null ? '' : String(value)

  function onBaseInputChange(elementValue, event) {
    const numberValue = elementValue === '' ? null : parseFloat(elementValue)
    onChange(numberValue)
  }

  return (
    <BaseInput
      className={classNames('number-input', className)}
      type="number"
      inputMode="decimal"
      value={elementValue}
      onChange={onBaseInputChange}
      {...baseInputProps}
    />
  )
}
