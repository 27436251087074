import { useMemo } from 'react'
import {
  getElementComponentProps,
  getElementComponentDynamicProps,
  getInputComponent
} from './EpiFormInput.utils'

export function useElementComponentStaticProps(formElement) {
  const elementComponentStaticProps = useMemo(() => {
    return getElementComponentProps(formElement, {})
  }, [formElement])

  return elementComponentStaticProps
}

export function useElementComponentDynamicProps(formElement, inputProps) {
  const { parentFieldName } = formElement
  const parentState = inputProps[parentFieldName]

  const elementComponentDynamicProps = useMemo(() => {
    return getElementComponentDynamicProps(formElement, parentState)
  }, [formElement, parentState])

  return elementComponentDynamicProps
}

export function useInputComponent(
  formElement,
  elementComponentStaticProps,
  elementComponentDynamicProps
) {
  const inputComponent = useMemo(() => {
    return getInputComponent(formElement, {
      ...elementComponentStaticProps,
      ...elementComponentDynamicProps
    })
  }, [formElement, elementComponentStaticProps, elementComponentDynamicProps])

  return inputComponent
}
