import React from 'react'

export function RadioIcon() {
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x=".5" y=".5" width="19" height="19" rx="9.5" stroke="#64666D" />
    </svg>
  )
}

export function RadioCheckedIcon() {
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="10" fill="#009DE0" />
      <circle cx="10" cy="10" r="4" fill="#fff" />
    </svg>
  )
}
