import React from 'react'

const NordLockBrandInvert = () => (
  <svg className="icon">
    <use
      xlinkHref={`/Static/Images/icons.svg?=${process.env.BUILD_VERSION_ID}#icon--nord-lock-brand-invert`}
    />
  </svg>
)

export default NordLockBrandInvert
