import React from 'react'
import { EpiFormInputElement } from '../../types/epi/form'
import { InputPropsMap } from '../../hooks/useForm/useForm.types'
import LabeledNordLockInput from '../LabeledNordLockInput'
import NordLockInput from '../NordLockInput'
import {
  useElementComponentStaticProps,
  useElementComponentDynamicProps,
  useInputComponent
} from './EpiFormInput.hooks'
import { classNames } from '../../utils/classNames'

interface EpiFormInputProps {
  formElement: EpiFormInputElement
  inputProps: InputPropsMap

  className?: string
}

export default function EpiFormInput({
  formElement,
  inputProps,

  className
}: EpiFormInputProps) {
  const { name, label, description, placeHolder } = formElement

  const elementComponentStaticProps = useElementComponentStaticProps(
    formElement
  )
  const elementComponentDynamicProps = useElementComponentDynamicProps(
    formElement,
    inputProps
  )
  const inputComponent = useInputComponent(
    formElement,
    elementComponentStaticProps,
    elementComponentDynamicProps
  )

  const elementInputProps = inputProps[name]

  const { isHidden } = elementInputProps

  if (!inputComponent || isHidden) {
    return null
  }

  const combinedProps = {
    className: classNames('epi-form-input', className),
    name,
    inputComponent,
    placeholder: placeHolder,
    ...elementComponentStaticProps,
    ...elementComponentDynamicProps,
    ...elementInputProps
  }

  if (label) {
    return (
      <LabeledNordLockInput
        label={label}
        description={description}
        {...combinedProps}
      />
    )
  }

  return <NordLockInput {...combinedProps} />
}
