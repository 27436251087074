import React, { useState, useEffect } from 'react'
import './mobileHeader.scss'
import {
  HeaderNavigationLinks,
  LanguageSelections,
  MegaMenuFooterBlock,
  SearchSettingsBlock
} from '../types'
import List from '@material-ui/core/List'
import Button from '@material-ui/core/Button'
import MenuListItem from './MenuListItem'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import MenuFooter from './MenuFooter'
import MenuHeader from './MenuHeader'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import CaretRight from '../../../../../Spa.Core/components/_svg/CaretRight'
import { getCookie } from '../../../../../Brand/js/core/utils/Cookies'
import Drawer from '@material-ui/core/Drawer'
import MobileLanguagePicker from '../../../components/LanguagePicker/MobileLanguagePicker'
import MenuSearch from '../../MegaMenu/MenuSearch'

import './mobileHeader.scss'
import '../../../components/LanguagePicker/language-picker.scss'

type MenuContentProps = {
  contactButtonText: string
  contactButtonUrl: string
  navigationMenuBlocks: HeaderNavigationLinks[]
  megaMenuFooterBlocks: MegaMenuFooterBlock[]
  setMainMenuOpen: (arg: boolean) => void
  languageSelections: LanguageSelections
  setSearchOpen?: (arg: boolean) => void
  isSearchOpen?: boolean
  searchSettingsBlock?: SearchSettingsBlock
  currentLanguageSelected: string
  currentLocale: string
}

const MenuList = ({
  contactButtonText,
  contactButtonUrl,
  navigationMenuBlocks,
  megaMenuFooterBlocks,
  setMainMenuOpen,
  languageSelections,
  setSearchOpen,
  isSearchOpen,
  searchSettingsBlock,
  currentLanguageSelected,
  currentLocale
}: MenuContentProps) => {
  const [open, setOpen] = useState(false)
  const [isSubMenuOpen, setSubMenuOpen] = useState(false)
  const [history, setHistory] = useState<HeaderNavigationLinks>()
  const [isLanguagePickerOpen, setLanguagePickerOpen] = useState(false)

  const allLanguageSelections = [
   ...(Array.isArray(languageSelections.fullVersionLanguageSelections)
     ? languageSelections.fullVersionLanguageSelections
     : []),
   ...(Array.isArray(languageSelections.additionalLanguageSelections)
     ? languageSelections.additionalLanguageSelections
     : [])
  ]

  const flagItem = allLanguageSelections.find(
   item => item.locale === currentLocale
  )

  const handleItemClick = (item: HeaderNavigationLinks) => {
    if (!!item.url?.length) {
      setOpen(false)
    } else if (item.megaMenuBlocks && item.megaMenuBlocks.length > 0) {
      setHistory(item)
      setSubMenuOpen(true)
      setOpen(true)
    }
  }

  return (
    <>
      <Box className="menuContent">
        <List className="menuContent__listWrapper" component="nav">
          <MenuHeader
            setOpen={setOpen}
            setMainMenuOpen={setMainMenuOpen}
            isSubMenuOpen={isSubMenuOpen}
            setSubMenuOpen={setSubMenuOpen}
            setSearchOpen={setSearchOpen}
          />
          {!isSearchOpen &&
            navigationMenuBlocks?.map((item: any) => (
              <MenuListItem
                navigationMenuBlocks={item}
                onClick={() => handleItemClick(item)}
                megaMenuFooterBlocks={megaMenuFooterBlocks}
                setMainMenuOpen={setMainMenuOpen}
                isSubMenuOpen={isSubMenuOpen}
                setSubMenuOpen={setSubMenuOpen}
                history={history?.title as string}
              />
            ))}
        </List>
        {!isSearchOpen && (
          <>
            <ListItem
              button
              className="listItem"
              onClick={() => setLanguagePickerOpen(true)}
            >
              {flagItem?.imageUrl && (
                <img className="list_item__img" src={flagItem?.imageUrl} />
              )}
              <ListItemText
                className="listItem__language-text"
                primary={currentLanguageSelected}
              />
              <ListItemIcon className="menuContent__arrowIcon">
                <CaretRight />
              </ListItemIcon>
            </ListItem>
            <Drawer
              open={isLanguagePickerOpen}
              onClose={() => setLanguagePickerOpen(false)}
              className="linkDrawer"
            >
              <MobileLanguagePicker
                isLanguagePickerOpen={isLanguagePickerOpen}
                setLanguagePickerOpen={setLanguagePickerOpen}
                megaMenuFooterBlocks={megaMenuFooterBlocks}
                languageSelections={languageSelections}
              />
            </Drawer>
            <Button
              className="menuContent__contactButton"
              href={contactButtonUrl}
            >
              {contactButtonText}
            </Button>
          </>
        )}
      </Box>
      {isSearchOpen && ( // This is the only difference between this file and MenuHeader.tsx in the same folder
        <Box my={6} mx={2}>
          <MenuSearch searchSettingsBlock={searchSettingsBlock} />
        </Box>
      )}
      <Divider className="menuContent__divider" />
      <Box className="menuContent__footerContainer">
        {megaMenuFooterBlocks?.map(item => (
          <MenuFooter
            heading={item.heading}
            description={item.description}
            imageUrl={item.imageUrl}
            link={item.link}
          />
        ))}
      </Box>
    </>
  )
}

export default MenuList
