import React from 'react'
import FetchStatus from '../../constants/fetchStatus'
import Download from '../_svg/Download'
import ViewSvg from '../_svg/View'
import Loader from '../Loader'
import './file-list.scss'
import { getCertificateDownloadUrl } from './fileList.utils'

interface FileList {
  status: string
  listData: any[]

  errorMessage: string
  noResultMessage: string
  fileNameLabel: string
  viewLabel: string
  downloadLabel: string
  proxyPath: string
}

const FileList = ({
  status,
  listData,
  errorMessage,
  noResultMessage,
  fileNameLabel,
  viewLabel,
  downloadLabel,
  proxyPath
}: FileList) => {
  return (
    <div className="file-list">
      <div className="file-list__section">
        {status === FetchStatus.Loading && (
          <div className="file-list__loading">
            <Loader />
          </div>
        )}

        {status === FetchStatus.Complete && (
          <div className="file-list__list">
            <div className="file-list__list-header">
              <span className="file-list__list-header-file">
                {fileNameLabel}
              </span>
              <span className="file-list__list-header-download">
                {downloadLabel}
              </span>
              <span className="file-list__list-header-view">{viewLabel}</span>
            </div>
            <div className="file-list__list-wrapper">
              <ul>
                {listData.length > 0 ? (
                  listData.map((file, index) => (
                    <li key={index} className="file-list__list-item">
                      <a
                        className="file-list__list-item-file"
                        href={getCertificateDownloadUrl(proxyPath, file.url)}
                        target="_blank"
                      >
                        {file.fileName}
                      </a>
                      <a
                        className="file-list__list-item-download"
                        href={getCertificateDownloadUrl(proxyPath, file.url)}
                        download={file.fileName}
                        target="_blank"
                      >
                        <Download />
                      </a>
                      <a
                        className="file-list__list-item-view"
                        href={getCertificateDownloadUrl(proxyPath, file.url)}
                        target="_blank"
                      >
                        <ViewSvg />
                      </a>
                    </li>
                  ))
                ) : (
                  <li className="file-list__list-item">
                    <span className="file-list__list-item-file">
                      {noResultMessage}
                    </span>
                  </li>
                )}
              </ul>
            </div>
          </div>
        )}

        {status === FetchStatus.Error && (
          <div className="file-list__error">
            <p className="file-list__error-text">{errorMessage}</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default FileList
