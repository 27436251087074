import {
  getCustomerOrders,
  getOrderDetails,
  requestQuote
} from './orders.fetch'

export const FETCH_ORDERS_REQUEST = 'WASHERS_FETCH_ORDERS_REQUEST'
export const FETCH_ORDERS_SUCCESS = 'WASHERS_FETCH_ORDERS_SUCCESS'
export const FETCH_ORDERS_ERROR = 'WASHERS_FETCH_ORDERS_ERROR'

export const FETCH_ORDERS_DETAILS_REQUEST = 'FETCH_ORDERS_DETAILS_REQUEST'
export const FETCH_ORDERS_DETAILS_SUCCESS = 'FETCH_ORDERS_DETAILS_SUCCESS'
export const FETCH_ORDERS_DETAILS_ERROR = 'FETCH_ORDERS_DETAILS_ERROR'

export const QUOTE_REQUEST = 'QUOTE_REQUEST'
export const QUOTE_REQUEST_SUCCESS = 'QUOTE_REQUEST_SUCCESS'
export const QUOTE_REQUEST_ERROR = 'QUOTE_REQUEST_ERROR'

export const fetchCustomerOrders = () => (dispatch, getState) => {
  const { currentLocale } = getState().translations
  dispatch({ type: FETCH_ORDERS_REQUEST })

  return getCustomerOrders(currentLocale).then(
    orders => {
      dispatch({ type: FETCH_ORDERS_SUCCESS, orders })
    },
    error => {
      dispatch({ type: FETCH_ORDERS_ERROR, error })
    }
  )
}

export const fetchOrderDetails = ({ orderNumber, orderType, moduleId }) => (
  dispatch,
  getState
) => {
  const { currentLocale } = getState().translations
  dispatch({ type: FETCH_ORDERS_DETAILS_REQUEST })

  return getOrderDetails(currentLocale, orderNumber, orderType, moduleId).then(
    orderDetails => {
      dispatch({
        type: FETCH_ORDERS_DETAILS_SUCCESS,
        orderDetails: {
          ...orderDetails,
          orderType,
          orderNumber
        }
      })
    },
    error => {
      dispatch({ type: FETCH_ORDERS_DETAILS_ERROR, error })
    }
  )
}
