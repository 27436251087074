import { connect } from 'react-redux'
import {
  getPageModel,
  getTranslation
} from '../../modules/translations/translations.utils'
import {
  getAllPageModels,
  getOrderListingCommonTranslations
} from '../../modules/translations/translations.selectors'

import QuoteRequest from './QuoteRequest'

const mapStateToProps = (state, { ...props }) => {
  const orderListingCommonTranslations = getOrderListingCommonTranslations(
    state
  )

  return {
    ...props,

    currentOrderDetails: state.customerOrders.currentOrderDetails,
    salesRepresentative: getPageModel(getAllPageModels(state), props.moduleId)
      .salesRepresentative,
    locale: state.translations.currentLocale,
    sendQuoteText: getTranslation(
      orderListingCommonTranslations,
      'submitQuote'
    ),
    quoteQuestion: getTranslation(
      orderListingCommonTranslations,
      'quoteQuestion'
    ),
    quoteInformation: getTranslation(
      orderListingCommonTranslations,
      'quoteInformation'
    ),
    quoteSuccessTitle: getTranslation(
      orderListingCommonTranslations,
      'quoteSuccessTitle'
    ),
    quoteSuccessInformation: getTranslation(
      orderListingCommonTranslations,
      'quoteSuccessInformation'
    ),
    quoteErrorMessage: getTranslation(
      orderListingCommonTranslations,
      'quoteErrorMessage'
    ),
    quoteRequestedDeliveryDateLabel: getTranslation(
      orderListingCommonTranslations,
      'quoteRequestedDeliveryDateLabel'
    )
  }
}

export default connect(mapStateToProps)(QuoteRequest)
