import { connect } from 'react-redux'
import ExpandProductList from '../../../../components/ProductList/ExpandProductList'
import DownloadDocList from '../../../../components/DownloadDocList'
import {
  getPageModel,
  getTranslation
} from '../../../../modules/translations/translations.utils'
import { getAllPageModels } from '../../../../modules/translations/translations.selectors'

const mapStateToProps = (state, { moduleId }) => {
  const { customerOrders, translations } = state
  const portalModule = getPageModel(getAllPageModels(state), moduleId)
  const portalOrderListingTranslations =
    translations.commonTranslations.portalOrderListingTranslationsBlock

  return {
    products: customerOrders.currentOrderDetails.items.map(product => ({
      product,
      DetailsComponent: product.documents ? DownloadDocList : undefined,
      detailsComponentArgs: {
        documents: product.documents,
        requestDocTitle: getTranslation(
          portalModule.content,
          'requestDocTitle'
        ),
        requestDocsItemsText: getTranslation(
          portalModule.content,
          'requestDocsItemsText'
        ),
        emailRecipient:
          (portalModule.salesRepresentative || {}).email ||
          portalModule.content.salesContactEmailFallback
      }
    })),
    title: getTranslation(portalOrderListingTranslations, 'orderContents')
  }
}

export default connect(mapStateToProps)(ExpandProductList)
