import HttpError from './HttpError'

export async function request(url, options = {}) {
  const response = await fetch(url, parseRequestOptions(options))

  const responseData = await response
    .clone()
    .json()
    .catch(() => response.clone().text())

  if (response.ok !== true) {
    throw HttpError.fromResponse(response, responseData)
  }

  return responseData
}

const parseRequestOptions = options => {
  const optionsClone = { ...options }

  optionsClone.headers = optionsClone.headers || {}

  if (typeof optionsClone.body === 'object') {
    optionsClone.body = JSON.stringify(optionsClone.body)
  }

  optionsClone.headers['Content-Type'] = 'application/json'
  optionsClone.credentials = optionsClone.credentials || 'same-origin'

  return optionsClone
}

export const get = (url, options) => request(url, { ...options, method: 'GET' })
export const post = (url, body, options) =>
  request(url, { ...{ body, ...options }, method: 'POST' })
