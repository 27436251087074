import React from 'react'
import PropTypes from 'prop-types'
import BaseInput from '../BaseInput'
import { classNames, addChildClassName } from '../../../utils/classNames'
import Checkmark from '../../_svg/Checkmark'

CheckboxInput.propTypes = {
  value: PropTypes.bool,

  className: PropTypes.string,
  children: PropTypes.node,

  onChange: PropTypes.func.isRequired
}

export default function CheckboxInput({
  value,

  className,
  children,

  onChange,

  ...baseInputProps
}) {
  function onBaseInputChange(value, event) {
    const isChecked = event.target.checked === true
    onChange(isChecked, event)
  }

  return (
    <label className={classNames('checkbox-input', className)}>
      <BaseInput
        className={classNames(
          'checkbox-input__input',
          addChildClassName(className, 'input')
        )}
        type="checkbox"
        checked={value === true}
        onChange={onBaseInputChange}
        {...baseInputProps}
      />

      <span
        className={classNames(
          'checkbox-input__box',
          addChildClassName(className, 'box')
        )}
      >
        <Checkmark className={'icon checkbox-input__checkmark'} />
      </span>

      {children && (
        <span
          className={classNames(
            'checkbox-input__content',
            addChildClassName(className, 'content')
          )}
        >
          {children}
        </span>
      )}
    </label>
  )
}
