export function getContentImageUrl(imageUrl) {
  if (!imageUrl) return null

  if (
    imageUrl.indexOf('http') === -1 &&
    imageUrl.indexOf('data:image/png;base64') === -1
  ) {
    return 'data:image/png;base64,' + (imageUrl || '').replace(/^"(.*)"$/, '$1')
  }

  return imageUrl
}
