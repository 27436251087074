import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './numeric-input.scss'

class NumericInput extends Component {
  static propTypes = {
    value: PropTypes.number.isRequired,
    callBack: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    const { value, callBack } = props

    this.state = {
      value: value,
      onChange: callBack
    }
  }

  handleOnChange = ({ target }) => {
    const newValue = target.valueAsNumber
    if (!isNaN(newValue)) {
      this.setState({ value: newValue })
      this.state.onChange(newValue)
    }
  }

  render() {
    return (
      <div className="numeric-input">
        <input
          className="numeric-input-text"
          type="number"
          value={this.state.value}
          onChange={this.handleOnChange}
          min="0"
        />
      </div>
    )
  }
}

export default NumericInput
