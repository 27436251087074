import React from 'react'

const BoltightBrandInvert = () => (
  <svg className="icon">
    <use
      xlinkHref={`/Static/Images/icons.svg?=${process.env.BUILD_VERSION_ID}#icon--boltight-brand-invert`}
    />
  </svg>
)

export default BoltightBrandInvert
