import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { ElementValuesMap } from 'Core/hooks/useForm/useForm.types'
import { EpiFormElementsArea } from 'Core/types/epi/form/epi-form'
import useEpiForm from 'Core/hooks/useEpiForm'
import { transformElementsAreaToFormElements } from 'Core/utils/epiForms'
import { submitReactForm } from '../../modules/reactForm/reactForm.actions'

export function useReactForm(
  elementsArea: EpiFormElementsArea,
  defaultValues?: ElementValuesMap
) {
  const formElements = useMemo(
    () => transformElementsAreaToFormElements(elementsArea),
    [elementsArea]
  )

  return useEpiForm(formElements, defaultValues)
}

export function useSubmitReactForm() {
  const dispatch = useDispatch()

  return function dispatchSubmitReactForm(
    formValues: ElementValuesMap,
    target: string
  ) {
    dispatch(submitReactForm(formValues, target))
  }
}
