import React from 'react'
import PropTypes from 'prop-types'
import ExpanderIconSvg from '../_svg/ExpanderIcon'
import NordLockIconSvg from '../_svg/NordLockIcon'
import SuperboltIconSvg from '../_svg/SuperboltIcon'

const CompanyIcon = ({ company }) => {
  switch (company) {
    case 'Superbolt':
      return <SuperboltIconSvg />
    case 'Expander':
      return <ExpanderIconSvg />
    case 'Web':
    default:
      return <NordLockIconSvg />
  }
}

CompanyIcon.propTypes = {
  company: PropTypes.string.isRequired
}

export default CompanyIcon
