import React from 'react'
import PropTypes from 'prop-types'
import './button.scss'

const Button = ({ children, onClick, className }) => (
  <button
    // target="_blank"
    // rel="noopener noreferrer"
    // href={link || null}
    onClick={onClick || null}
    className={`btn ${className || ''}`}
  >
    {children}
  </button>
)

Button.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string
}

export default Button
