export function formatTime(time: any) {
  if (isNaN(time)) {
    return '00:00'
  }
  const date = new Date(time * 1000)
  const hours = date.getUTCHours()
  const minutes = date.getUTCMinutes()
  const seconds = date
    .getUTCSeconds()
    .toString()
    .padStart(2, '0')
  if (hours) {
    return `${hours}:${minutes.toString().padStart(2, '0')} `
  } else return `${minutes}:${seconds}`
}
