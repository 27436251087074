import React from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import OrderListingOverview from './OrderListingOverview'
import OrderDetails from './OrderDetails'
import FetchOrderContainer from '../../containers/FetchOrderContainer'
import { getUserOrders } from '../../modules/orders/orders.selectors'

interface OrderListingRouterProps {
  props: Object
}

function OrderListingRouter(props: OrderListingRouterProps) {
  const orders = useSelector(getUserOrders)

  return (
    <FetchOrderContainer>
      {orders.length ? (
        <Router>
          <Switch>
            <Route
              path="/"
              exact
              // @ts-ignore
              render={() => <OrderListingOverview {...props} />}
            />
            <Route
              path="/order/:id/:orderType"
              render={routerProps => (
                // @ts-ignore
                <OrderDetails {...props} {...routerProps} />
              )}
            />
          </Switch>
        </Router>
      ) : (
        ''
      )}
    </FetchOrderContainer>
  )
}

export default OrderListingRouter
