import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { inputPropsPropType } from '../../../_inputs/BaseInput/BaseInput.propTypes'
import DropDownInput from '../../../_inputs/DropDownInput'
import usePrevious from '../../../../hooks/usePrevious'

const DEFAULT_VALUE = ''

SubSelectionInput.propTypes = {
  ...inputPropsPropType,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      name: PropTypes.string.isRequired,
      group: PropTypes.string
    })
  )
}

export default function SubSelectionInput({
  options,

  onChange,

  ...dropDownInputs
}) {
  const previousOptions = usePrevious(options)

  useEffect(() => {
    if (previousOptions && !options) {
      onChange(DEFAULT_VALUE)
    }
  })

  if (!options) {
    return null
  }

  return (
    <DropDownInput options={options} onChange={onChange} {...dropDownInputs} />
  )
}
