import React, { useState } from 'react'
import PropTypes from 'prop-types'

import './icon-button.scss'

const IconButton = ({
  SvgIcon,
  ToggledSvgIcon,
  ToggledText,

  elementTagName: ElementTagName,

  className,
  children,

  onClick,

  ...elementProps
}) => {
  const [toggled, setToggleState] = useState(false)

  return (
    <ElementTagName
      className={`icon-button ${className || ''}`}
      onClick={() => {
        if (onClick) {
          onClick()
        }
        setToggleState(!toggled)
      }}
      {...elementProps}
    >
      {/* If button is toggled and toggle text exist show ToggledText otherwise children(Text) */}
      {toggled && ToggledText ? ToggledText : children}

      <span
        className={`icon-button-icon ${className ? className + '-icon' : ''}`}
      >
        {ToggledSvgIcon && toggled ? <ToggledSvgIcon /> : <SvgIcon />}
      </span>
    </ElementTagName>
  )
}

IconButton.propTypes = {
  SvgIcon: PropTypes.func.isRequired,
  ToggledSvgIcon: PropTypes.func,
  ToggledText: PropTypes.string,

  elementTagName: PropTypes.string,

  children: PropTypes.string.isRequired,
  className: PropTypes.string,

  onClick: PropTypes.func
}

IconButton.defaultProps = {
  elementTagName: 'button'
}

export default IconButton
