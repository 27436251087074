import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Close from '../../../../../Spa.Core/components/_svg/Close'
import ArrowLeft from '../../../../../Spa.Core/components/_svg/ArrowLeft'
import ListSubheader from '@material-ui/core/ListSubheader'
import './MobileHeader.scss'
import { Typography } from '@material-ui/core'
import ColorStripe from '../../../../../Spa.Core/components/ColorStripe'

type MenuHeaderProps = {
  onBackClick?: () => void
  isSubMenuOpen?: boolean
  setOpen?: (arg: boolean) => void
  setMainMenuOpen?: (arg: boolean) => void
  setSubMenuOpen?: (arg: boolean) => void
  history?: string
  setLanguagePickerOpen?: (arg: boolean) => void
  setSearchOpen?: (arg: boolean) => void
}

const MenuHeader = ({
  history,
  setOpen,
  onBackClick,
  isSubMenuOpen,
  setMainMenuOpen,
  setSubMenuOpen,
  setLanguagePickerOpen,
  setSearchOpen
}: MenuHeaderProps) => {
  const handleClose = () => {
    if (setOpen) {
      setOpen(false)
    }
    if (setMainMenuOpen) {
      setMainMenuOpen(false)
    }
    if (setSubMenuOpen) {
      setSubMenuOpen(false)
    }
    if (setLanguagePickerOpen) {
      setLanguagePickerOpen(false)
    }
    if (setSearchOpen) {
      setSearchOpen(false)
    }
  }

  return (
    <ListSubheader className="menuContent__top">
      <ColorStripe />
      {isSubMenuOpen && history && (
        <IconButton onClick={onBackClick} className="menuContent__arrowLeft">
          <ArrowLeft />
          <Typography className="menuContent__arrowLeft-label">
            {history}
          </Typography>
        </IconButton>
      )}
      <IconButton onClick={handleClose} className="menuContent__closeIcon">
        <Close />
      </IconButton>
    </ListSubheader>
  )
}

export default MenuHeader
