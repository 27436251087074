import React from 'react'
import PropTypes from 'prop-types'

InvalidInput.propTypes = {
  formElement: PropTypes.object,
  formElementProps: PropTypes.object
}

export default function InvalidInput({ formElement, formElementProps }) {
  return (
    <div style={{ color: 'red' }}>
      <strong>
        Invalid inputType (
        <i>
          &quot;
          {formElement.inputType}
          &quot;
        </i>
        )
      </strong>

      <div>
        formElement:
        <pre>
          <code>{JSON.stringify(formElement, null, 2)}</code>
        </pre>
      </div>
      <div>
        formElementProps:
        <pre>
          <code>{JSON.stringify(formElementProps, null, 2)}</code>
        </pre>
      </div>
    </div>
  )
}
