import React from 'react'
import { EnumType } from './types'
import {
  MenuItem,
  Button,
  Popover,
  ListItemText,
  MenuList,
  Typography
} from '@material-ui/core'
import ArrowDown from '../../../../Spa.Core/components/_svg/ArrowDown'
import './filter.scss'

import FilterCheckbox from './FilterCheckbox'

type FilterComponentProps = {
  items: EnumType[]
  handleChange: (
    name: string
  ) => (event: React.ChangeEvent<{ value: unknown }> | number) => void
  selectedItems: number[]
  isClicked: boolean
  handleClick: (event: React.MouseEvent<HTMLElement>) => void
  handleClose: () => void
  anchorEl: null | HTMLElement
  filterType: string
  filterName: string
}

const FilterComponent = ({
  items,
  handleChange,
  selectedItems,
  isClicked,
  handleClick,
  handleClose,
  anchorEl,
  filterType,
  filterName
}: FilterComponentProps) => {
  return (
    <>
      <Button
        className={isClicked ? 'popover-open' : ''}
        aria-controls={`${filterName}-menu`}
        onClick={handleClick}
        endIcon={
          <ArrowDown
            style={{
              transform: isClicked ? 'rotate(180deg)' : 'rotate(0deg)'
            }}
          />
        }
      >
        {filterName}
      </Button>
      <Popover
        style={{ padding: '16px' }}
        id={`${filterName}-menu`}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <MenuList style={{ padding: '16px 16px 8px 8px', width: '217px' }}>
          {items.map((item: any) => (
            <MenuItem
              style={{
                height: '20px',
                marginBottom: '8px',
                paddingLeft: '0px',
                paddingRight: '0px'
              }}
              key={item.id}
              onClick={() => handleChange(filterType)(item.id)}
            >
              <FilterCheckbox
                checked={selectedItems && selectedItems.includes(item.id)}
              />
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="body2" style={{ fontSize: '14px' }}>
                    {item.name}
                  </Typography>
                }
              />
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </>
  )
}

export default FilterComponent
