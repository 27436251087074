import React from 'react'
import PropTypes from 'prop-types'

const TextLinkAction = ({ className, children, onClick }) => {
  const linkTag = 'link'
  const firstSectionIndex = children.indexOf(`{${linkTag}}`)
  const endSectionIndex = children.indexOf(`{/${linkTag}}`)

  const linkText = children.slice(
    firstSectionIndex + `{${linkTag}}`.length,
    endSectionIndex
  )

  return (
    <span className={`text-link-action ${className || ''}`}>
      <span
        dangerouslySetInnerHTML={{
          __html: children.substr(0, firstSectionIndex)
        }}
      />
      <button href="#" onClick={onClick}>
        {linkText}
      </button>
      <span
        dangerouslySetInnerHTML={{
          __html: children.substr(
            endSectionIndex + `{/${linkTag}}`.length,
            children.length
          )
        }}
      />
    </span>
  )
}

TextLinkAction.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string
}

export default TextLinkAction
