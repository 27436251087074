import React from 'react'
import PropTypes from 'prop-types'
import LabelWithValue from '../../../LabelWithValue'
import CompanyIcon from '../../../CompanyIcon'
// import IconButton from '../../../IconButton'
// import ExternalLinkSvg from '../../../_svg/ExternalLink'

import './detailed-product-info.scss'

const DetailedProdutInfo = ({
  statusLabel,
  dateLabel,
  // trackingLinkLabel,
  // trackingLabel,
  imageUrl,
  name,
  itemNumber,
  quantity,
  unit,
  itemsTranslation,
  company,
  status,
  date
  // trackingInfo
}) => (
  <div className="detailed-product-info">
    <div className="detailed-product-info__image">
      {imageUrl ? (
        <img src={imageUrl} />
      ) : (
        <span className="detailed-product-info__icon">
          <CompanyIcon company={company} />
        </span>
      )}
    </div>
    <div className="detailed-product-info__container">
      <span className="detailed-product-info__title">{itemNumber}</span>
      <span className="detailed-product-info__name">{name}</span>
      <span className="detailed-product-info__quantity">
        {quantity} {unit || itemsTranslation}
      </span>

      <div className="detailed-product-info__shipping">
        {status && <LabelWithValue label={statusLabel} value={status} />}
        {date && (
          <LabelWithValue
            label={dateLabel}
            value={new Date(date).toLocaleDateString()}
          />
        )}
        {/*
        {trackingInfo &&
          trackingInfo.map(({ trackingNumber, trackingUrl }) => (
            <div
              className="detailed-product-info__tracking-item"
              key={trackingNumber}
            >
              <LabelWithValue label={trackingLabel} value={trackingNumber} />

              <IconButton
                className="order-tracking-status-information__track-button"
                elementTagName="a"
                href={trackingUrl}
                target="_blank"
                SvgIcon={ExternalLinkSvg}
              >
                {trackingLinkLabel}
              </IconButton>
            </div>
          ))} */}
      </div>
    </div>
  </div>
)

DetailedProdutInfo.propTypes = {
  statusLabel: PropTypes.string,
  dateLabel: PropTypes.string,
  trackingLinkLabel: PropTypes.string,
  trackingLabel: PropTypes.string,
  status: PropTypes.string,
  date: PropTypes.string,
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  itemNumber: PropTypes.string,
  quantity: PropTypes.number,
  unit: PropTypes.string,
  itemsTranslation: PropTypes.string,
  company: PropTypes.string.isRequired
  // trackingInfo: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     trackingNumber: PropTypes.string,
  //     trackingUrl: PropTypes.string
  //   })
  // )
}

export default DetailedProdutInfo
