interface FileObject {
  base64: string
  batchnumber: string
  fileName: string
  metadata_storage_path: string
  productnumber: string
}

export interface TransformFileObject {
  fileName: string
  url: string
}

export function fileListTransform(
  fileData: Array<FileObject>
): TransformFileObject[] {
  return fileData.map(file => {
    return {
      fileName: decodeURIComponent(file.fileName),
      url: file.metadata_storage_path
    }
  })
}
