import React, { useState, ChangeEvent } from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  Box,
  IconButton,
  Typography,
  ListSubheader,
  MenuList,
  MenuItem,
  ListItemText,
  Divider,
  Button
} from '@material-ui/core'
import FilterCheckbox from './FilterCheckbox'
import { EnumType, GridTranslationsType } from './types'

import ColorStripe from '../../../../Spa.Core/components/ColorStripe'
import Close from '../../../../Spa.Core/components/_svg/Close'
import AddIcon from '../../../../Spa.Core/components/_svg/AddIcon'
import ExpandedIcon from '../../../../Spa.Core/components/_svg/ExpandedIcon'
import './filter.scss'
import SmallClose from '../../components/_svg/SmallClose'

type FilterMobileProps = {
  handleClose: (arg: boolean) => void
  categoriesByPageNode: EnumType[]
  brandsByEnum: EnumType[]
  handleChange: (
    name: string
  ) => (
    event:
      | React.ChangeEvent<{
          value: unknown
        }>
      | number
  ) => void
  category: number[]
  brand: number[]
  gridTranslations: GridTranslationsType
}

type AccordionItemProps = {
  expanded: boolean
  onToggle: () => void
  title: string
  items: EnumType[]
  selectedItems: number[]
  handleChange: (
    name: string
  ) => (
    event:
      | React.ChangeEvent<{
          value: unknown
        }>
      | number
  ) => void
}

const AccordionItem = ({
  expanded,
  onToggle,
  title,
  items,
  selectedItems,
  handleChange
}: AccordionItemProps) => (
  <Accordion
    expanded={expanded}
    onChange={onToggle}
    className="filterContent__accordion"
  >
    <AccordionSummary
      className="filterContent__accordion-summery"
      expandIcon={expanded ? <ExpandedIcon /> : <AddIcon />}
    >
      <Typography
        style={{ fontWeight: '500' }}
        className="filterContent__accordion__title"
      >
        {title}
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <MenuList className="filterContent__accordion__item">
        {items.map(item => (
          <MenuItem
            className="filterContent__accordion__item__menuItem"
            style={{
              height: '20px',
              marginBottom: '16px',
              paddingLeft: '0px',
              paddingRight: '0px'
            }}
            key={item.id}
            onClick={() => handleChange(title.toLowerCase())(item.id)}
          >
            <FilterCheckbox
              className="filterContent__accordion__item__checkbox"
              checked={selectedItems && selectedItems.includes(item.id)}
            />
            <ListItemText
              disableTypography
              primary={
                <Typography variant="body2" style={{ fontSize: '14px' }}>
                  {item.name}
                </Typography>
              }
            />
          </MenuItem>
        ))}
      </MenuList>
    </AccordionDetails>
  </Accordion>
)

const FilterMobile = ({
  handleClose,
  categoriesByPageNode,
  brandsByEnum,
  brand,
  handleChange,
  category,
  gridTranslations
}: FilterMobileProps) => {
  const [isCategoryExpanded, setIsCategoryExpanded] = useState(false)
  const [isBrandExpanded, setIsBrandExpanded] = useState(false)
  const selectedItems = [
    ...brand.map(id => brandsByEnum.find(item => item.id === id)?.name),
    ...category.map(
      id => categoriesByPageNode.find(item => item.id === id)?.name
    )
  ].filter(Boolean)
  return (
    <Box className="filterContent">
      <List className="filterContent__listWrapper">
        <ListSubheader className="filterContent__top">
          <ColorStripe />
          {/* set the correct prop for title */}
          <Typography className="filterContent__title">
            {gridTranslations.filters}
          </Typography>
          <IconButton
            onClick={() => handleClose(false)}
            className="filterContent__closeIcon"
          >
            <Close />
          </IconButton>
        </ListSubheader>
        <Box width="100%" px={2}>
          {selectedItems.map(item => (
            <button
              className="filterContent__selectedItem"
              key={item}
              onClick={() => {
                const brandId = brandsByEnum.find(
                  brandItem => brandItem.name === item
                )?.id
                const categoryId = categoriesByPageNode.find(
                  categoryItem => categoryItem.name === item
                )?.id

                if (brandId !== undefined && brand.includes(brandId)) {
                  handleChange('brand')(brandId)
                } else if (
                  categoryId !== undefined &&
                  category.includes(categoryId)
                ) {
                  handleChange('category')(categoryId)
                }
              }}
            >
              {item}
              <SmallClose />
            </button>
          ))}
        </Box>
        <Divider />
        <Box>
          <AccordionItem
            expanded={isCategoryExpanded}
            onToggle={() => setIsCategoryExpanded(!isCategoryExpanded)}
            title="Category"
            items={categoriesByPageNode}
            selectedItems={category}
            handleChange={handleChange}
          />
          <AccordionItem
            expanded={isBrandExpanded}
            onToggle={() => setIsBrandExpanded(!isBrandExpanded)}
            title="Brand"
            items={brandsByEnum}
            selectedItems={brand}
            handleChange={handleChange}
          />
        </Box>
      </List>
    </Box>
  )
}

export default FilterMobile
