import { connect } from 'react-redux'
import StickySalesContact from './StickySalesContact'
import {
  getAllPageModels,
  getOrderListingCommonTranslations
} from '../../../modules/translations/translations.selectors'
import {
  getPageModel,
  getTranslation
} from '../../../modules/translations/translations.utils'

const createFallbackContact = (state, pageModelContent) => ({
  header: getTranslation(
    getOrderListingCommonTranslations(state),
    'salesContact'
  ),
  salesRepresentative: {
    name: pageModelContent.salesContactNameFallback,
    phoneNumber: pageModelContent.salesContactPhoneFallback,
    email: pageModelContent.salesContactEmailFallback
  }
})

const mapStateToPros = (state, { moduleId }) => {
  const pageModel = getPageModel(getAllPageModels(state), moduleId)
  const salesRepresentative = pageModel.salesRepresentative
  const pageModelContent = pageModel.content

  let contacts = []

  if (salesRepresentative) {
    contacts.push({
      header: getTranslation(
        getOrderListingCommonTranslations(state),
        'salesRepresentative'
      ),
      salesRepresentative
    })
  }

  if (
    !salesRepresentative ||
    salesRepresentative.type.toLowerCase() === 'user'
  ) {
    contacts.push(createFallbackContact(state, pageModelContent))
  }

  return {
    contacts: contacts
  }
}

export default connect(mapStateToPros)(StickySalesContact)
