import React from 'react'
import Box from '@material-ui/core/Box'
import { FooterContactBlockProps } from './types'
import { Typography, Button, useMediaQuery, Divider } from '@material-ui/core'
import Link from '@material-ui/core/Link'

const FooterContact = ({ ...props }) => {
  const isMobile = useMediaQuery('(max-width: 768px)')

  const data = props.props as FooterContactBlockProps
  const formattedAddress = data.address.replace(/\n/g, '<br />')
  const formattedCompany = data.companyName.replace('<br />', '')

  return (
    <>
      {isMobile && <Divider className="divider" />}
      <Box className="footer-contact">
        <Typography
          className="footer-contact__company"
          dangerouslySetInnerHTML={{
            __html: isMobile ? formattedCompany : data.companyName
          }}
        ></Typography>
        <Typography
          className="footer-contact__address"
          dangerouslySetInnerHTML={{ __html: formattedAddress }}
        ></Typography>
        {(data.emailUrl || data.phoneUrl) && (
          <Box className="footer-contact__link-container">
            {data.phoneUrl && (
              <Box className="footer-contact__link">
                <span>{data.phoneLabel}: </span>
                <Link href={data.phoneUrl}>
                  {data.phoneUrl?.replace('tel:', '')}
                </Link>
              </Box>
            )}
            {data.emailUrl && (
              <Box className="footer-contact__link">
                <span>{data.emailLabel}: </span>
                <Link href={data.emailUrl}>
                  {data.emailUrl?.replace('mailto:', '')}
                </Link>
              </Box>
            )}
          </Box>
        )}
        <Button className="footer-contact__cta" href={data.contactButtonUrl}>
          {data.contactButtonText}
        </Button>
      </Box>
    </>
  )
}

export default FooterContact
