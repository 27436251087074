export const orderDetailsTransform = orderDetails => {
  if (orderDetails.items && orderDetails.items.length > 0) {
    orderDetails.items.forEach(detail => {
      if (detail.invoiceLine && detail.discountPercentage) {
        detail.invoiceLine.discountPercentage = detail.discountPercentage
      }
    })
  }

  return orderDetails
}
