import React from 'react'
import {
  EpiFormColumnsContainer,
  EpiFormElementsArea
} from 'Core/types/epi/form'
import { InputPropsMap } from 'Core/hooks/useForm/useForm.types'
import BoltightFormInput from '../../ReactFormInput'
import useIsContainerElementHidden from '../../../hooks/useIsContainerElementHidden'
import './column-container.scss'

interface ColumnContainerProps {
  formElement: EpiFormColumnsContainer
  inputProps: InputPropsMap
}

export default function ColumnContainer({
  formElement,
  inputProps
}: ColumnContainerProps) {
  const { column1ElementsArea, column2ElementsArea } = formElement

  const isHidden = useIsContainerElementHidden(formElement, inputProps)

  function renderElementsAreaChildren(elementsArea: EpiFormElementsArea) {
    return elementsArea.map(elementDefinition => (
      <BoltightFormInput
        key={elementDefinition.name}
        formElement={elementDefinition}
        inputProps={inputProps}
      />
    ))
  }

  if (isHidden) {
    return null
  }

  return (
    <div className="column-container">
      {column1ElementsArea && (
        <div className="column-container__column">
          {renderElementsAreaChildren(column1ElementsArea)}
        </div>
      )}

      {column2ElementsArea && (
        <div className="column-container__column">
          {renderElementsAreaChildren(column2ElementsArea)}
        </div>
      )}
    </div>
  )
}
