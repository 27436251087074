import React from 'react'
import './top-hero.scss'

type ScrollDownArrowProps = {
  onClick?: () => void
  topHeroHeight?: number
}

const ScrollDownArrow = ({ onClick, topHeroHeight }: ScrollDownArrowProps) => {
  const arrowStyle = {
    bottom: '0px'
  }

  return (
    <>
      <a
        href="#"
        style={arrowStyle}
        className="custom-scroll-down"
        onClick={event => {
          event.preventDefault()
          onClick?.()
        }}
      >
        <span className="custom-scroll-down__arrow">
          <span></span>
          <span></span>
        </span>
        <span className="custom-scroll-down__line"></span>
      </a>
    </>
  )
}

export default ScrollDownArrow
