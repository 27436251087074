import React from 'react'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { BottomLinks } from './types'

type FooterLegalProps = {
  copyright: string
  bottomLinks: BottomLinks[]
}

const FooterLegal = ({ copyright, bottomLinks }: FooterLegalProps) => {
  return (
    <>
      <Box className="footer-legal">
        <Box></Box>
        <Box className="footer-legal__copywright">
          <Typography>{copyright}</Typography>
        </Box>
        <Box className="footer-legal__link-container">
          {bottomLinks &&
            bottomLinks.map((item, i) => (
              <Link key={i} href={item.href}>
                {item.text}
              </Link>
            ))}
        </Box>
      </Box>
    </>
  )
}

export default FooterLegal
