import React from 'react'

const NordLockIcon = () => (
  <svg className="icon">
    <use
      xlinkHref={`/Static/Images/icons.svg?=${
        process.env.BUILD_VERSION_ID
      }#icon--nord-lock_icon`}
    />
  </svg>
)

export default NordLockIcon
