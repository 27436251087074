import { connect } from 'react-redux'
import OrderListingOverview from './OrderListingOverview'
import {
  getOrdersAsLinkListItem,
  getAllOrders
} from '../../../modules/orders/orders.selectors'
import { getPageModel } from '../../../modules/translations/translations.utils'
import { getAllPageModels } from '../../../modules/translations/translations.selectors'

const mapStateToProps = (state, props) => ({
  linkListOrders: getOrdersAsLinkListItem(state),
  moduleId: props.moduleId,
  ordersCount: getAllOrders(state).length,
  heading: getPageModel(getAllPageModels(state), props.moduleId).content
    .listHeading
})

export default connect(mapStateToProps)(OrderListingOverview)
