import { connect } from 'react-redux'
import { fetchCustomerOrders } from '../../modules/orders/orders.action'
import { getTranslation } from '../../modules/translations/translations.utils'
import { getCommonTranslations } from '../../modules/translations/translations.selectors'
import FetchContainer from '../FetchContainer'

const mapStateToProps = state => ({
  isFetching: state.customerOrders.isFetchingOrders,
  fetchingError: state.customerOrders.fetchingOrdersError,
  errorMessageTranslation: getTranslation(
    getCommonTranslations(state),
    'somethingWentWrong'
  ),
  redirectUserErrorMessage: getTranslation(
    getCommonTranslations(state),
    'redirectUserErrorMessage'
  )
})

const mapDispatchToProps = { fetchFn: fetchCustomerOrders }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FetchContainer)
