import React from 'react'

const ArrowLeft = props => (
  <svg width="28" height="29" fill="none" {...props}>
    <path
      d="M22 14.5H7M13 21.5l-7-7 7-7"
      stroke="#000"
      stroke-width="1.5"
      stroke-linecap="square"
      stroke-linejoin="round"
    />{' '}
  </svg>
)

export default ArrowLeft
