import React from 'react'
import BaseProductList from '../BaseProductList'
import QuantitySelectProductItem from '../../ProductListItems/QuantitySelectProductItem'

const QuantitySelectProductList = ({ title, products, onChange }) => (
  <BaseProductList
    className="quantity-select-product-list"
    title={title}
    products={products}
    ProductItemComponent={QuantitySelectProductItem}
    onChange={onChange}
  />
)

export default QuantitySelectProductList
