import { connect } from 'react-redux'
import InvoiceItemDetails from './InvoiceItemDetails'
import { getTranslation } from '../../modules/translations/translations.utils'
import {
  getOrderListingCommonTranslations,
  getCommonTranslations
} from '../../modules/translations/translations.selectors'

const mapPropsToState = (state, ownProps) => {
  const { invoiceLine, status } = ownProps

  var portalTranslations = getOrderListingCommonTranslations(state)
  var commonTranslation = getCommonTranslations(state)

  return {
    itemPriceLabel: getTranslation(portalTranslations, 'itemPrice'),
    itemPrice: invoiceLine.salesPrice,
    discountPercentageLabel: getTranslation(portalTranslations, 'discount'),
    discountPercentage:
      invoiceLine.discountPercentage && invoiceLine.discountPercentage > 0
        ? `${invoiceLine.discountPercentage}%`
        : null,
    invoicePriceLabel: getTranslation(portalTranslations, 'invoicePrice'),
    invoicePrice: invoiceLine.lineAmount,
    statusLabel: getTranslation(commonTranslation, 'status'),
    status: status
  }
}

export default connect(mapPropsToState)(InvoiceItemDetails)
