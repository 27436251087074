import React, { useState, useRef } from 'react'
import ReactPlayer from 'react-player'
import Box from '@material-ui/core/Box'
import Controls from './Controls'
import { makeStyles } from '@material-ui/core/styles'
import { OnProgressProps } from 'react-player/base'

type VideoComponentProps = {
  url: string
}
export type VideoState = {
  playing: boolean
  muted: boolean
  volume: number
  played: number
  seeking: boolean
}
export default function VideoComponent({ url }: VideoComponentProps) {
  const [videoState, setVideoState] = useState<VideoState>({
    playing: true,
    muted: true,
    volume: 0,
    played: 0,
    seeking: false
  })
  const [showControls, setShowControls] = useState(false)
  const { playing, muted, volume, played, seeking } = videoState
  const playerRef = useRef<ReactPlayer>(null)
  const classes = useStyles()

  // Handle play or pause video
  const handlePlayPause = () => {
    setVideoState({ ...videoState, playing: !videoState.playing })
  }
  //Handle the progress of the video
  const progressHandler = (e: OnProgressProps) => {
    if (!seeking) {
      setVideoState({ ...videoState, ...e })
    }
  }
  //Handle the seek of the video
  const seekHandler = (e: React.ChangeEvent, value: any) => {
    setVideoState({ ...videoState, played: parseFloat(value) / 100 })
  }
  //Handle the seek of the video when releasing mouse
  const seekMouseUpHandler = (e: React.ChangeEvent, value: any) => {
    setVideoState({ ...videoState, seeking: false })
    playerRef.current?.seekTo(value / 100)
  }
  //Handle the volume of the video
  const volumeChangeHandler = (e: any, value: any) => {
    const newVolume = parseFloat(value) / 100
    setVideoState({
      ...videoState,
      volume: newVolume,
      muted: Number(newVolume) === 0 ? true : false // volume === 0 then muted
    })
  }
  //Handle the volume of the video when releasing mouse
  const volumeSeekUpHandler = (e: any, value: any) => {
    const newVolume = parseFloat(value) / 100
    setVideoState({
      ...videoState,
      volume: newVolume,
      muted: newVolume === 0 ? true : false
    })
  }
  //Handle the mute of the video
  const muteHandler = () => {
    setVideoState({
      ...videoState,
      muted: !videoState.muted,
      volume: videoState.volume > 0 ? 0 : 0.5
    })
  }
  //Get the current time of the video
  const currentTime = playerRef.current
    ? playerRef.current.getCurrentTime()
    : '00:00'
  //Get the duration of the video
  const duration = playerRef.current ? playerRef.current.getDuration() : '00:00'
  //Format the time of the video
  const formatTime = (time: any) => {
    //formarting duration of video
    if (isNaN(time)) {
      return '00:00'
    }
    const date = new Date(time * 1000)
    const hours = date.getUTCHours()
    const minutes = date.getUTCMinutes()
    const seconds = date
      .getUTCSeconds()
      .toString()
      .padStart(2, '0')
    if (hours) {
      //if video have hours
      return `${hours}:${minutes.toString().padStart(2, '0')} `
    } else return `${minutes}:${seconds}`
  }
  //variables for the current time and duration of the video to pass to controls
  const formatCurrentTime = formatTime(currentTime)
  const formatDuration = formatTime(duration)

  return (
    <Box
      className={classes.boxContainer}
      onMouseEnter={() => setShowControls(true)}
      onMouseLeave={() => setShowControls(false)}
    >
      <ReactPlayer
        width="100%"
        height="100%"
        ref={playerRef}
        url={url}
        loop
        playing={playing}
        muted={muted}
        volume={volume}
        onProgress={progressHandler}
      />
      {showControls && (
        <Controls
          playing={playing}
          muted={muted}
          handlePlayPause={handlePlayPause}
          played={played}
          seekMouseUpHandler={seekMouseUpHandler}
          seekHandler={seekHandler}
          volume={volume}
          onVolumeChangeHandler={volumeChangeHandler}
          onVolumeSeekUp={volumeSeekUpHandler}
          muteHandler={muteHandler}
          duration={formatDuration}
          currentTime={formatCurrentTime}
        />
      )}
    </Box>
  )
}
const useStyles = makeStyles(theme => ({
  boxContainer: {
    position: 'relative',
    marginBottom: '-5px',
    [theme.breakpoints.up('xl')]: {
      height: '100vh'
    }
  }
}))
