import * as React from 'react'
import Button from '@material-ui/core/Button'
import { RichTextBlockProps } from './types'
import HtmlContent from './HtmlContent'
import './richTextBlock.scss'
import { classNames } from 'Core/utils/classNames'
import IconListComponent from '../NlgBlock/IconListComponent'

type Props = {
  props: RichTextBlockProps
}

const RichTextBlock = ({
  props: {
    text,
    buttonUrl,
    buttonText,
    transparent,
    imageUrls,
    textColor,
    contentAreaBgColor,
    contentAreaFgColor,
    textAlignLeft
  }
}: Props) => {
  const contrastOnBlue = contentAreaBgColor === 2 && transparent

  return (
    <div
      className={classNames(
        'container',
        transparent && 'noMaxWidth',
        textAlignLeft && 'left-align'
      )}
    >
      {text !== null && (
        <HtmlContent
          html={text}
          textColor={textColor}
          contentAreaFgColor={contentAreaFgColor}
          contrastOnBlue={!!contrastOnBlue}
        >
          {buttonText && buttonUrl && (
            <Button
              href={buttonUrl}
              className={classNames(
                'container__button',
                contrastOnBlue && 'button_style_blue'
              )}
            >
              {buttonText}
            </Button>
          )}
          {imageUrls && <IconListComponent icons={imageUrls} />}
        </HtmlContent>
      )}
    </div>
  )
}

export default RichTextBlock
