import { connect } from 'react-redux'
import ExpandProductList from '../../../../components/ProductList/ExpandProductList'
import InvoiceItemDetails from '../../../../components/InvoiceItemDetails'
import { getTranslation } from '../../../../modules/translations/translations.utils'

const mapStateToProps = ({ customerOrders, translations }) => {
  const portalOrderListingTranslations =
    translations.commonTranslations.portalOrderListingTranslationsBlock

  return {
    products: customerOrders.currentOrderDetails.items.map(product => ({
      product,
      DetailsComponent: product.invoiceLine ? InvoiceItemDetails : undefined,
      detailsComponentArgs: product
    })),
    title: getTranslation(portalOrderListingTranslations, 'orderContents')
  }
}

export default connect(mapStateToProps)(ExpandProductList)
