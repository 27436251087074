export const classNames = (...classNameList) =>
  classNameList.filter(Boolean).join(' ')

export const addChildClassName = (baseClassName, childExtension) => {
  if (!baseClassName || !childExtension) return null
  return splitAndAddToClassName(baseClassName, splitClassName => {
    const separator = splitClassName.indexOf('__') === -1 ? '__' : '-'
    return separator + childExtension
  })
}

export const addStateClassName = (baseClassName, stateExtension) => {
  if (!baseClassName || !stateExtension) return null
  return splitAndAddToClassName(baseClassName, `--${stateExtension}`)
}

const splitAndAddToClassName = (baseClassName, extension) => {
  const splitClassNames = baseClassName.split(' ')
  const isFunction = typeof extension === 'function'
  return splitClassNames
    .map(
      className => className + (isFunction ? extension(className) : extension)
    )
    .join(' ')
}
