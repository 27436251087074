import React, { useState } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import {
  AdditionalLanguageSelections,
  LanguageSelections
} from 'src/blocks/Header/types'
import ListItemLink from './ListItemLink'
import { Grid } from '@material-ui/core'
import chunkArray from './utils'

const DesktopLanguagePickerTabs = ({ ...props }) => {
  const [value, setValue] = React.useState(0)

  const data = props.data as LanguageSelections

  const handleTabChange = (_e: any, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box className="list-layout">
      <Tabs
        className="list_container__tabs"
        value={value}
        onChange={handleTabChange}
      >
        <Tab label={data.fullVersionTabTitle} />
        <Tab label={data.additionalVersionTabTitle} />
      </Tabs>
      <Box className="list_container">
        {value === 0 && (
          <List>
            <Grid
              container
              justifyContent="center"
              style={{ paddingTop: '24px', maxWidth: '1350px', margin: 'auto' }}
            >
              {chunkArray(data.fullVersionLanguageSelections, 5).map(
                (chunk, chunkIndex) => (
                  <Grid item xs={2} key={chunkIndex}>
                    {chunk.map((item, index: number) => (
                      <ListItemLink
                        key={index}
                        imageUrl={item.imageUrl}
                        displayName={item.displayName}
                        urlTarget={item.urlTarget}
                      />
                    ))}
                  </Grid>
                )
              )}
            </Grid>
          </List>
        )}
        {value === 1 && (
          <List>
            <Grid
              container
              justifyContent="center"
              style={{ paddingTop: '24px', maxWidth: '1350px', margin: 'auto' }}
            >
              {chunkArray(data.additionalLanguageSelections, 5).map(
                (chunk, chunkIndex) => (
                  <Grid item xs={2} key={chunkIndex}>
                    {chunk.map(
                      (item: AdditionalLanguageSelections, index: number) => (
                        <ListItemLink
                          key={index}
                          imageUrl={item.imageUrl}
                          displayName={item.displayName}
                          urlTarget={item.urlTarget}
                        />
                      )
                    )}
                  </Grid>
                )
              )}
            </Grid>
          </List>
        )}
      </Box>
    </Box>
  )
}

export default DesktopLanguagePickerTabs
