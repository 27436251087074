import React from 'react'

const KakaoTalkIcon = ({ ...props }) => (
  <svg width="24" height="24" fill="currentColor" {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22 19.778A2.222 2.222 0 0 1 19.778 22H4.222A2.222 2.222 0 0 1 2 19.778V4.222C2 2.994 2.994 2 4.222 2h15.556C21.006 2 22 2.994 22 4.222v15.556ZM12 6c-3.866 0-7 2.471-7 5.52 0 1.97 1.31 3.7 3.281 4.676-.107.37-.689 2.378-.712 2.536 0 0-.014.119.063.164.077.045.167.01.167.01.22-.03 2.554-1.67 2.958-1.954a8.86 8.86 0 0 0 1.243.086c3.866 0 7-2.47 7-5.519C19 8.471 15.866 6 12 6Zm-3.87 7.446a.395.395 0 0 1-.404-.386v-2.399h-.63a.396.396 0 0 1 0-.79h2.068a.396.396 0 0 1 0 .79h-.63v2.4a.395.395 0 0 1-.404.385Zm3.541-.005c-.168 0-.297-.069-.336-.179l-.2-.523H9.904l-.2.524c-.04.11-.168.178-.336.178a.616.616 0 0 1-.257-.056c-.111-.051-.218-.193-.096-.573l.966-2.543a.609.609 0 0 1 .538-.399.61.61 0 0 1 .539.4l.965 2.54c.123.383.016.524-.095.575a.618.618 0 0 1-.257.056Zm-.748-1.418-.404-1.146-.403 1.146h.807Zm1.75 1.364a.38.38 0 0 1-.387-.37v-2.743c0-.223.185-.404.412-.404.228 0 .413.181.413.404v2.373h.858a.38.38 0 0 1 .387.37.38.38 0 0 1-.387.37h-1.296Zm2.244.054a.404.404 0 0 1-.404-.404v-2.763a.404.404 0 0 1 .808 0v.868l1.126-1.127a.313.313 0 0 1 .224-.09c.101 0 .202.044.278.12.071.07.113.161.119.256a.315.315 0 0 1-.09.246l-.92.92.995 1.317a.401.401 0 0 1-.08.565.4.4 0 0 1-.423.04.4.4 0 0 1-.142-.119l-.947-1.255-.14.14v.882a.405.405 0 0 1-.404.404Z"
    />
  </svg>
)

export default KakaoTalkIcon
