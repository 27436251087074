import React from 'react'

type HighlightedQueryProps = {
  text?: string
  highlight: string
}

export const HighlightedQuery = ({
  text,
  highlight
}: HighlightedQueryProps) => {
  const parts = text?.split(new RegExp(`(${highlight})`, 'gi'))
  return (
    <span>
      {parts?.map((part, i) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <span key={i} style={{ fontWeight: 500 }}>
            {part}
          </span>
        ) : (
          part
        )
      )}
    </span>
  )
}
