import React from 'react'
import PropTypes from 'prop-types'
import FetchOrderDetailsContainer from '../../../containers/FetchOrderDetailsContainer'
import DetailWrapper from '../../../components/DetailWrapper/DetailWrapper'
import Tabs from '../../../components/Tabs/Tabs'
import TabContent from '../../../components/Tabs/TabContent'
import QuoteRequest from '../../../components/QuoteRequest'
import InvoiceProductList from './InvoiceProductList'
import DocProductList from './DocProductList'
import StatusProductList from './StatusProductList'

import SalesContact from '../../../components/SalesContact'
import InvoiceSummary from '../InvoiceSummary'
import OrderTrackingStatus from '../OrderTrackingStatus'

import { getTranslation } from '../../../modules/translations/translations.utils'

import './order-details.scss'

const OrderDetails = ({
  currentOrderId,
  currentOrderType,
  currentOrderDetails,
  currentOrderName,
  moduleId,
  commonTranslations,
  orderLabel,
  salesRepresentative
}) => {
  return (
    <DetailWrapper path="/">
      <FetchOrderDetailsContainer
        currentOrderId={currentOrderId}
        currentOrderType={currentOrderType}
        currentOrderName={currentOrderName}
        moduleId={moduleId}
      >
        <div className="order-details">
          <h2 className="order-details__title">{`${orderLabel} ${currentOrderId}`}</h2>

          <Tabs>
            <TabContent label={getTranslation(commonTranslations, 'status')}>
              <OrderTrackingStatus />
              <StatusProductList />
            </TabContent>
            <TabContent label={getTranslation(commonTranslations, 'payment')}>
              <InvoiceSummary moduleId={moduleId} />
              <InvoiceProductList />
            </TabContent>
            <TabContent label={getTranslation(commonTranslations, 'docs')}>
              <DocProductList moduleId={moduleId} />
            </TabContent>
            <TabContent label={getTranslation(commonTranslations, 'reOrder')}>
              <QuoteRequest moduleId={moduleId} />
            </TabContent>
          </Tabs>

          {salesRepresentative && (
            <SalesContact
              className="order-details__sales-contact"
              moduleId={moduleId}
              salesRepresentative={salesRepresentative}
            />
          )}
        </div>
      </FetchOrderDetailsContainer>
    </DetailWrapper>
  )
}

OrderDetails.propTypes = {
  currentOrderId: PropTypes.string.isRequired,
  currentOrderType: PropTypes.string.isRequired,
  currentOrderName: PropTypes.string,
  moduleId: PropTypes.number.isRequired,
  commonTranslations: PropTypes.object.isRequired,
  orderLabel: PropTypes.string,
  currentOrderDetails: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        confirmedShippingDate: PropTypes.string,
        description: PropTypes.string,
        imageUrl: PropTypes.string,
        invoiceLine: PropTypes.object,
        name: PropTypes.string.isRequired,
        productUrl: PropTypes.string,
        quantity: PropTypes.number,
        trackingInfo: PropTypes.arrayOf(
          PropTypes.shape({
            trackingNumber: PropTypes.string,
            trackingUrl: PropTypes.string
          })
        )
      })
    ),
    invoiceSummary: PropTypes.shape({
      address: PropTypes.shape({
        city: PropTypes.string,
        countryCode: PropTypes.string,
        countryName: PropTypes.string,
        line1: PropTypes.string,
        line2: PropTypes.string,
        postalCode: PropTypes.string,
        regionCode: PropTypes.string,
        regionName: PropTypes.string
      }),
      currencyCode: PropTypes.string,
      invoiceDate: PropTypes.string,
      invoiceHeaderTaxAmount: PropTypes.string,
      invoiceNumber: PropTypes.string,
      totalChargeAmount: PropTypes.string,
      totalDiscountAmount: PropTypes.string,
      totalDiscountCustomerGroupCode: PropTypes.number,
      totalInvoiceAmount: PropTypes.string,
      totalTaxAmount: PropTypes.string
    })
  }),
  salesRepresentative: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    profileImageUrl: PropTypes.string,
    title: PropTypes.stringl
  })
}

export default OrderDetails
