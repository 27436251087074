import React from 'react'
import PropTypes from 'prop-types'
import PhoneSvg from '../_svg/Phone'
import EnvelopeSvg from '../_svg/Envelope'
import SpeechBubbleSvg from '../_svg/SpeechBubble'
import { classNames } from '../../utils/classNames'

import './sales-contact.scss'

const SalesContact = ({
  profileImageUrl,
  name,
  title,
  phoneNumber,
  email,

  className
}) => (
  <div className={classNames('sales-contact', className)}>
    <div className="sales-contact__content">
      {profileImageUrl ? (
        <img className="sales-contact__image" src={profileImageUrl} />
      ) : (
        <div className="sales-contact__image">
          <SpeechBubbleSvg className="sales-contact__image-icon" />
        </div>
      )}

      <div className="sales-contact__texts">
        <div className="sales-contact__name">{name}</div>

        {title && <div className="sales-contact__title">{title}</div>}
      </div>
    </div>

    <div className="sales-contact__contact-information">
      {phoneNumber && (
        <a href={`tel:${phoneNumber}`} className="sales-contact__phone-number">
          <PhoneSvg className="sales-contact__phone-number-icon" />
          <span className="sales-contact__phone-number-value">
            {phoneNumber}
          </span>
        </a>
      )}

      {email && (
        <a href={`mailto:${email}`} className="sales-contact__email-address">
          <EnvelopeSvg className="sales-contact__email-address-icon" />
          <span className="sales-contact__email-address-value">{email}</span>
        </a>
      )}
    </div>
  </div>
)

SalesContact.propTypes = {
  profileImageUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,

  className: PropTypes.string
}

export default SalesContact
