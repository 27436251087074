// Imports
import React, { createRef, useEffect, useMemo, useRef, useState } from 'react'

// Create interface for button properties
export interface ButtonProps<ButtonElement extends HTMLElement>
  extends Pick<
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<ButtonElement>,
      ButtonElement
    >,
    | 'onKeyDown'
    | 'onClick'
    | 'tabIndex'
    | 'role'
    | 'aria-haspopup'
    | 'aria-expanded'
    | 'onMouseEnter'
  > {
  ref: React.RefObject<ButtonElement>
  onMouseLeave?: React.MouseEventHandler<ButtonElement> | undefined
  megaMenuContentRef?: React.RefObject<HTMLElement>
}

// A custom Hook that abstracts away the listeners/controls for dropdown menus
export interface DropdownMenuOptions {
  disableFocusFirstItemOnClick?: boolean
  hasSearch?: boolean
  disableCloseOnClick?: boolean
}

export type ItemProp = {
  onKeyDown: (e: React.KeyboardEvent<HTMLAnchorElement>) => void
  tabIndex: number
  role: string
  ref: React.RefObject<HTMLAnchorElement>
}

interface DropdownMenuResponse<ButtonElement extends HTMLElement> {
  readonly buttonProps: ButtonProps<ButtonElement>
  readonly itemProps: {
    onKeyDown: (e: React.KeyboardEvent<HTMLAnchorElement>) => void
    tabIndex: number
    role: string
    ref: React.RefObject<HTMLAnchorElement>
  }[]
  readonly isOpen: boolean
  readonly setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  readonly moveFocus: (itemIndex: number) => void
  readonly allProductsButtonProps: ButtonProps<HTMLButtonElement>
}

export default function useMegaMenuButton<
  ButtonElement extends HTMLElement = HTMLButtonElement
>(
  itemCount: number,
  options?: DropdownMenuOptions
): DropdownMenuResponse<ButtonElement> {
  // Use state
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const currentFocusIndex = useRef<number | null>(null)
  const firstRun = useRef(true)
  const clickedOpen = useRef(false)

  // Create refs
  const allProductsButtonRef = useRef<HTMLButtonElement>(null)
  const buttonRef = useRef<ButtonElement>(null)
  const megaMenuContentRef = useRef<ButtonElement>(null)
  const itemRefs = useMemo<React.RefObject<HTMLAnchorElement>[]>(
    () =>
      Array.from({ length: itemCount }, () => createRef<HTMLAnchorElement>()),
    [itemCount]
  )
  const allRefs = [allProductsButtonRef, ...itemRefs]

  // Create type guard
  const isKeyboardEvent = (
    e: React.KeyboardEvent | React.MouseEvent
  ): e is React.KeyboardEvent => (e as React.KeyboardEvent).key !== undefined

  // Handles moving the focus between menu items
  const moveFocus = (itemIndex: number): void => {
    currentFocusIndex.current = itemIndex
    allRefs[itemIndex].current?.focus()
  }

  useEffect(() => {
    if (isOpen) {
      dialogOpen()
    } else {
      dialogClose()
    }
  }, [isOpen])

  useEffect(() => {
    // Stop if this is the first fire of the Hook, and update the ref
    if (firstRun.current) {
      firstRun.current = false
      return
    }

    // If the menu is currently open focus on the first item in the menu
    if (isOpen && !options?.disableFocusFirstItemOnClick) {
      window.requestAnimationFrame(() => moveFocus(0))
    } else if (!isOpen) {
      clickedOpen.current = false
    }

    if (isOpen && options?.hasSearch) {
      window.requestAnimationFrame(() => {
        const searchInput = document.querySelector<HTMLInputElement>(
          '.search-input input'
        )
        if (searchInput) {
          searchInput.focus()
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  // Handle listening for clicks and auto-hiding the menu
  useEffect(() => {
    // Ignore if the menu isn't open
    if (!isOpen) {
      return
    }

    // Initialize object to track if the removal happens before the addition of the event listener
    //  -> I'm using an object here so that arrow functions below capture the reference and not the value
    const removalTracker = {
      removed: false
    }

    // This function is designed to handle every click
    const handleEveryHover = (event: MouseEvent): void => {
      // Make this happen asynchronously
      window.requestAnimationFrame(() => {
        // Type guard
        if (!(event.target instanceof Element)) {
          return
        }
        // Ignore if clicking inside the menu or over the button
        if (
          event.target.closest('.megaMenuContent') ||
          event.target === buttonRef.current
        ) {
          return
        }

        // Hide dropdown
        setIsOpen(false)
      })
    }
    const handleEveryClick = (event: MouseEvent): void => {
      // Make this happen asynchronously
      window.requestAnimationFrame(() => {
        // Type guard
        if (!(event.target instanceof Element)) {
          return
        }

        // Ignore if clicking inside the menu or over the button
        if (
          event.target.closest('[role="menu"]') instanceof Element ||
          event.target === buttonRef.current
        ) {
          return
        }

        // Hide dropdown
        setIsOpen(false)
      })
    }

    // Add listener
    //  -> Force it to be async to fix: https://github.com/facebook/react/issues/20074
    window.requestAnimationFrame(() => {
      if (removalTracker.removed) {
        return
      }

      document.addEventListener('click', handleEveryClick)
      document.addEventListener('mouseover', handleEveryHover)
    })

    // Return function to remove listener
    return (): void => {
      removalTracker.removed = true

      document.removeEventListener('click', handleEveryClick)
      document.removeEventListener('mouseover', handleEveryHover)
    }
  }, [isOpen, options?.disableCloseOnClick])

  // Disable scroll when the menu is opened, and revert back when the menu is closed
  useEffect(() => {
    const disableArrowScroll = (event: KeyboardEvent): void => {
      if (isOpen && (event.key === 'ArrowDown' || event.key === 'ArrowUp')) {
        event.preventDefault()
      }
    }

    document.addEventListener('keydown', disableArrowScroll)

    return (): void =>
      document.removeEventListener('keydown', disableArrowScroll)
  }, [isOpen])

  // Create a handler function for the button's clicks and keyboard events
  const buttonListener = (e: React.KeyboardEvent | React.MouseEvent): void => {
    // Detect if event was a keyboard event or a mouse event
    if (isKeyboardEvent(e)) {
      const { key } = e

      if (!['Enter', ' ', 'Tab', 'ArrowDown', 'Escape'].includes(key)) {
        return
      }

      if (
        (key === 'Tab' || key === 'ArrowDown') &&
        clickedOpen.current &&
        isOpen
      ) {
        e.preventDefault()
        moveFocus(0)
      }

      if (key === 'Enter' || key === ' ' || key === 'ArrowDown') {
        e.preventDefault()
        setIsOpen(true)
      }

      if (key === 'Escape') {
        e.preventDefault()
        setIsOpen(false)
      }
    } else {
      if (options?.disableFocusFirstItemOnClick) {
        clickedOpen.current = !isOpen
      }
      setIsOpen(!isOpen)
    }
  }

  // Create a function that handles menu logic based on keyboard events that occur on menu items
  const itemListener = (
    e: React.KeyboardEvent<HTMLAnchorElement | HTMLButtonElement>
  ): void => {
    // Destructure the key property from the event object
    const { key } = e

    // Handle keyboard controls
    if (
      ['Tab', 'Shift', 'Enter', 'Escape', 'ArrowUp', 'ArrowDown', ' '].includes(
        key
      )
    ) {
      // Create mutable value that initializes as the currentFocusIndex value
      let newFocusIndex = currentFocusIndex.current

      // Controls whether the menu is open or closed, if the button should regain focus on close, and if a handler function should be called
      if (key === 'Escape') {
        setIsOpen(false)
        buttonRef.current?.focus()
        return
      } else if (key === 'Tab') {
        setIsOpen(false)
        return
      } else if (key === 'Enter' || key === ' ') {
        e.preventDefault()
        e.currentTarget.click()
        !options?.disableCloseOnClick && setIsOpen(false)
        return
      }

      // Controls the current index to focus
      if (newFocusIndex !== null) {
        if (key === 'ArrowUp') {
          newFocusIndex -= 1
        } else if (key === 'ArrowDown') {
          newFocusIndex += 1
        }

        if (newFocusIndex > allRefs.length - 1) {
          newFocusIndex = 0
        } else if (newFocusIndex < 0) {
          newFocusIndex = allRefs.length - 1
        }
      }

      // After any modification set state to the modified value
      if (newFocusIndex !== null) {
        moveFocus(newFocusIndex)
      }

      return
    }
  }
  const onHoverMenuBtn = () => {
    setIsOpen(true)
  }
  const dialogOpen = () => {
    const dialog = document.getElementById('dialog')
    if (dialog && megaMenuContentRef.current) {
      dialog.style.height = megaMenuContentRef.current.clientHeight + 80 + 'px'
    }
  }
  const dialogClose = () => {
    const dialog = document.getElementById('dialog')
    if (dialog) {
      dialog.style.height = '0px'
    }
    setIsOpen(false)
  }

  // Define spreadable props for button and items
  const buttonProps: ButtonProps<ButtonElement> = {
    onKeyDown: buttonListener,
    onClick: buttonListener,
    onMouseEnter: onHoverMenuBtn,
    tabIndex: 0,
    ref: buttonRef,
    role: 'button',
    'aria-haspopup': true,
    'aria-expanded': isOpen,
    megaMenuContentRef
  }

  const allProductsButtonProps: ButtonProps<HTMLButtonElement> = {
    onKeyDown: itemListener,
    tabIndex: 0,
    ref: allProductsButtonRef,
    role: 'button'
  }

  const itemProps: ItemProp[] = Array.from(
    { length: itemCount },
    (_ignore, index) => ({
      onKeyDown: itemListener,
      tabIndex: -1,
      role: 'menuitem',
      ref: itemRefs[index]
    })
  )

  // Return a listener for the button, individual list items, and the state of the menu
  return {
    allProductsButtonProps,
    buttonProps,
    itemProps,
    isOpen,
    setIsOpen,
    moveFocus
  } as const
}
