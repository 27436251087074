import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { classNames, addChildClassName } from '../../utils/classNames'
import SVGClose from '../_svg/Close'
import './information-box.scss'

export default class InformationBox extends Component {
  static propTypes = {
    infoHtml: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    className: PropTypes.string
  }

  state = {
    isActive: false
  }

  onInfoButtonClick = e => {
    e.preventDefault()
    this.setState({ isActive: true })
  }

  onCloseIconClick = () => {
    this.setState({ isActive: false })
  }

  onOverlayClick = () => {
    this.setState({ isActive: false })
  }

  render() {
    const { infoHtml, className, children } = this.props
    const { isActive } = this.state

    const isLarger = Boolean(infoHtml) && infoHtml.length > 400

    let clickableElement = null
    const clickableElementClassName = classNames(
      'information-box__button',
      addChildClassName(className, 'button'),
      isActive && 'information-box__button--is-active',
      isActive && addChildClassName(className, 'button--is-active')
    )

    if (typeof children === 'string' || children instanceof String) {
      clickableElement = (
        <button
          className={clickableElementClassName}
          onClick={this.onInfoButtonClick}
        >
          {children}
        </button>
      )
    } else {
      clickableElement = React.cloneElement(children, {
        className: classNames(
          clickableElementClassName,
          children.props.className
        ),
        onClick: this.onInfoButtonClick
      })
    }

    return (
      <div className={classNames('information-box', className)}>
        {clickableElement}

        {isActive && (
          <div
            className="information-box__overlay"
            onClick={this.onOverlayClick}
          />
        )}

        <div
          className={classNames(
            'information-box__popup',
            isLarger && 'information-box__popup--is-larger',
            isActive && 'information-box__popup--is-active'
          )}
        >
          <button
            className="information-box__popup-close"
            onClick={this.onCloseIconClick}
          >
            <SVGClose className="information-box__popup-close-icon" />
          </button>

          <div
            className="information-box__popup-content"
            dangerouslySetInnerHTML={{ __html: infoHtml }}
          />
        </div>
      </div>
    )
  }
}
