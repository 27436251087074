import React, { useState, FormEvent } from 'react'
import { searchRequest } from './Search.hook'
import BaseInput from 'Core/components/_inputs/BaseInput'
import Button from 'Core/components/_buttons/PrimaryButton'
import './search.scss'

export interface SearchInput {
  id?: string
  label: string
  placeholder?: string
  type: string
  defaultValue?: string
}

interface SearchProps {
  searchTitle?: string
  targetApi: string
  searchLabel: string
  searchInputs: SearchInput[]
  onSearch: (request: Promise<any>, scrollInto?: boolean | null) => void
}

interface ISearchParameters {
  [elementId: string]: string
}

export default function Search({
  searchTitle,
  searchInputs,
  searchLabel,
  targetApi,
  onSearch
}: SearchProps) {
  const [searchParameters, setSearchParamters] = useState<ISearchParameters>(
    initializeSearchParameters
  )

  function initializeSearchParameters(): ISearchParameters {
    const defaultSearchParameters: ISearchParameters = {}

    searchInputs.forEach(searchInputs => {
      if (searchInputs.id) {
        defaultSearchParameters[searchInputs.id] =
          searchInputs.defaultValue || ''
      }
    })

    return defaultSearchParameters
  }

  function onChange(value: string, event: React.ChangeEvent<HTMLInputElement>) {
    if (event) {
      const parameters = searchParameters
      parameters[event.target.id] = event.target.value
      setSearchParamters(parameters)
    }
  }

  function search(event: FormEvent<HTMLFormElement>) {
    event.preventDefault()
    onSearch(searchRequest(searchParameters, targetApi))
  }

  function createInputElements(searchInput: SearchInput[]) {
    return searchInput.map((input, index) => {
      return (
        <div className="search__inputs-container" key={index}>
          <label className="search__inputs-label" htmlFor={input.id}>
            {input.label}
          </label>
          <BaseInput
            id={input.id}
            className="search__inputs-input"
            onChange={onChange}
            {...input}
          />
        </div>
      )
    })
  }

  return (
    <form className="search" onSubmit={search}>
      <div className="search__header">
        <h2 className="search__header-title">{searchTitle}</h2>
      </div>

      <div className="search__inputs">{createInputElements(searchInputs)}</div>

      <div className="search__submit">
        <Button className="search__submit-button" type="submit">
          {searchLabel}
        </Button>
      </div>
    </form>
  )
}
