import React from 'react'
import PropTypes from 'prop-types'
import { classNames } from '../../../utils/classNames'

BaseButton.propTypes = {
  element: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
}

BaseButton.defaultProps = {
  element: 'button'
}

export default function BaseButton({
  element: Element,

  className,

  ...elementProps
}) {
  return <Element className={classNames('btn', className)} {...elementProps} />
}
