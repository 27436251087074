/** @typedef { import('./useForm.types').ElementDefinitionsMap } ElementDefinitionsMap */
/** @typedef { import('./useForm.types').InputPropsMap } InputPropsMap */
/** @typedef { import('./useForm.types').GetValuesFunc } GetValuesFunc */
/** @typedef { import('./useForm.types').FormState } FormState */

import { useRef } from 'react'
import useOnMount from '../useOnMount'
import useElementNames from './useForm.useElementNames'
import useInitialElementStates from './useForm.useInitialElementStates'
import useFormStateReducer from './useForm.useFormStateReducer'
import useElementHandlers from './useForm.useElementHandlers'
import { isObject } from '../../utils/jsTypeUtils'

/**
 * @param {ElementDefinitionsMap} elementDefinitions
 * @returns {FormState}
 */
export default function useForm(elementDefinitions) {
  const elementRefsContainer = useRef({})

  const elementNames = useElementNames(elementDefinitions)
  const initialElementStates = useInitialElementStates(
    elementDefinitions,
    elementNames
  )

  const [_innerFormState, dispatch] = useFormStateReducer(
    initialElementStates,
    isValidElement
  )

  const elementHandlers = useElementHandlers(
    elementNames,
    dispatch,
    elementRefsContainer
  )

  const inputProps = elementNames.reduce(
    /**
     * @param {InputPropsMap} inputProps
     * @returns {InputPropsMap}
     **/
    (inputProps, name) => {
      inputProps[name] = {
        ..._innerFormState.elementStates[name],
        ...elementHandlers[name]
      }
      return inputProps
    },
    {}
  )

  useOnMount(() => {
    elementNames.forEach(name =>
      dispatch({
        type: 'set-valid',
        isValid: isValidElement(
          name,
          _innerFormState.elementStates[name].value
        ),
        name
      })
    )
  })

  function isValidElement(name, value) {
    let isValid = true

    const refElement = elementRefsContainer.current[name]
    if (refElement) {
      isValid = refElement.validity.valid
    }

    const definition = elementDefinitions[name]
    if (!isObject(definition)) {
      return isValid
    }

    const validation = definition.validation
    if (validation) {
      isValid = isValid && validation(value, getValues)
    }

    if (isValid) {
      return isValid
    }

    const isRequired = definition.isRequired === true
    if (isRequired) {
      const isEmpty = Boolean(value)
      return !isEmpty
    }

    return false
  }

  /** @type {GetValuesFunc} */
  function getValues(name) {
    if (name) {
      const elementState = _innerFormState.elementStates[name]
      return elementState ? elementState.value : null
    }

    return elementNames.reduce(
      (values, name) => ({
        ...values,
        [name]: _innerFormState.elementStates[name].value
      }),
      {}
    )
  }

  return {
    inputProps,
    isValid: _innerFormState.isValid,
    getValues
  }
}
