import { connect } from 'react-redux'
import SalesContact from './SalesContact'
import {
  getTranslation,
  getPageModel
} from '../../modules/translations/translations.utils'
import {
  getOrderListingCommonTranslations,
  getAllPageModels
} from '../../modules/translations/translations.selectors'
import { getContentImageUrl } from '../../utils/content'

const mapStateToProps = (state, ownProps) => {
  const { moduleId, salesRepresentative } = ownProps

  const pageModel = getPageModel(getAllPageModels(state), moduleId)
  const content = pageModel.content

  return {
    profileImageUrl: getContentImageUrl(salesRepresentative.profileImageUrl),
    name: salesRepresentative.name,
    title: salesRepresentative.title
      ? salesRepresentative.title
      : getTranslation(
          getOrderListingCommonTranslations(state),
          'salesRepresentative'
        ),
    phoneNumber:
      salesRepresentative.phoneNumber || content.salesContactPhoneFallback,
    email: salesRepresentative.email || content.salesContactEmailFallback
  }
}

export default connect(mapStateToProps)(SalesContact)
