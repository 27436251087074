import React from 'react'
import ContentMenu from '../ContentMenu'
import './topContent.scss'
import { ContentMenuProps } from '../ContentMenu/ContentMenu'
import Box from '@material-ui/core/Box'
import ContentArea from '../../blocks/ContentArea'
import {
  ContentAreaProps,
  SectionBlock
} from '../../blocks/ContentArea/ContentArea'

type TopContentProps = {
  contentArea: SectionBlock[]
  image: string | null
  menu: ContentMenuProps[]
  title: string
}

type Props = {
  props: TopContentProps
}

const TopContent = ({ props }: Props) => {
  const { contentArea, image, menu, title } = props
  const contentAreaProps = {
    sectionBlock: contentArea
  } as ContentAreaProps

  return (
    <Box className="topContent">
      <Box className="topContent--title">
        {image && <img src={image} alt={title} />}
        {title && <h2 className="teaser-layout__heading">{title}</h2>}
      </Box>
      {contentArea && contentArea.length > 0 && (
        <ContentArea props={contentAreaProps} menu={menu} isTopContent={true} />
      )}
    </Box>
  )
}

export default TopContent
