import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TextLinkAction from '../../../../components/TextLinkAction'
import LabelWithValue from '../../../../components/LabelWithValue'
import { requestNewInvoice } from '../../../../modules/orders/orders.fetch'
import Loader from '../../../../components/Loader'

import './invoice-summary-details.scss'

const InvoiceSummaryDetails = ({
  invoiceNumber,
  invoiceDateLabel,
  invoiceDate,
  poNumberLabel,
  poNumber,
  invoiceNumberLabel,
  totalInvoiceAmount,
  totalInvoiceAmountLabel,
  newInvoiceRequestData,
  requestNewInvoiceTitle,
  requestNewInvoiceText,
  requestNewInvoiceSuccessMessage,
  isPaid,
  dueDateLabel,
  dueDate,
  isOverdue,
  statusLabel,
  status,
  amountDueLabel,
  amountDue,
  settledAmountLabel,
  settledAmount,
  payedStatusUncertain,
  isWebOrder
}) => {
  const [hasRequestedInvoice, setHasRequestedInvoice] = useState(false)
  const [isFakeLoading, setIsFakeLoading] = useState(false)

  const onTextLinkAction = () => {
    setHasRequestedInvoice(true)
    setIsFakeLoading(true)
    // Start a fake loader

    setTimeout(() => {
      setIsFakeLoading(false)
    }, 1500)

    requestNewInvoice(
      newInvoiceRequestData.locale,
      newInvoiceRequestData.invoiceNumber,
      newInvoiceRequestData.orderNumber,
      newInvoiceRequestData.orderType,
      newInvoiceRequestData.moduleId
    ).then(() => {})
  }

  return (
    <div className="invoice-summary-details">
      {!payedStatusUncertain && (
        <LabelWithValue label={statusLabel} value={status} />
      )}

      {!isWebOrder &&
        ((
          <LabelWithValue
            label={invoiceDateLabel}
            value={new Date(invoiceDate).toLocaleDateString()}
          />
        ) && (
          <LabelWithValue label={invoiceNumberLabel} value={invoiceNumber} />
        ))}

      <LabelWithValue label={poNumberLabel} value={poNumber} />

      {!isWebOrder && (
        <LabelWithValue
          className={
            isOverdue && !isPaid ? 'invoice-summary-details__warning' : ''
          }
          label={dueDateLabel}
          value={dueDate ? new Date(dueDate).toLocaleDateString() : '-'}
        />
      )}

      {!payedStatusUncertain && (
        <LabelWithValue label={amountDueLabel} value={amountDue} />
      )}

      {!payedStatusUncertain && (
        <LabelWithValue label={settledAmountLabel} value={settledAmount} />
      )}

      <LabelWithValue
        label={totalInvoiceAmountLabel}
        value={totalInvoiceAmount}
      />

      {requestNewInvoiceTitle ? (
        <div className="invoice-summary-details__action">
          {!hasRequestedInvoice ? (
            <div>
              <span className="invoice-summary-details__action-title">
                {requestNewInvoiceTitle}
              </span>
              <TextLinkAction onClick={onTextLinkAction}>
                {requestNewInvoiceText}
              </TextLinkAction>
            </div>
          ) : (
            <span className="invoice-summary-details__action-title">
              {isFakeLoading ? <Loader /> : requestNewInvoiceSuccessMessage}
            </span>
          )}
        </div>
      ) : null}
    </div>
  )
}

InvoiceSummaryDetails.propTypes = {
  requestDocsText: PropTypes.string,
  invoiceNumber: PropTypes.string,
  invoiceDateLabel: PropTypes.string,
  invoiceDate: PropTypes.string,
  poNumberLabel: PropTypes.string,
  poNumber: PropTypes.string,
  invoiceNumberLabel: PropTypes.string,
  totalInvoiceAmountLabel: PropTypes.string,
  currencyCode: PropTypes.string,
  totalTaxAmount: PropTypes.string,
  totalDiscountCustomerGroupCode: PropTypes.number,
  totalChargeAmount: PropTypes.string,
  totalDiscountAmount: PropTypes.string,
  invoiceHeaderTaxAmount: PropTypes.string,
  totalInvoiceAmount: PropTypes.string,
  adress: PropTypes.shape({
    line1: PropTypes.string,
    line2: PropTypes.string,
    city: PropTypes.string,
    countryCode: PropTypes.string,
    countryName: PropTypes.string,
    postalCode: PropTypes.string,
    regionCode: PropTypes.string,
    regionName: PropTypes.string
  }),
  newInvoiceRequestData: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    invoiceNumber: PropTypes.string.isRequired,
    orderNumber: PropTypes.string.isRequired,
    orderType: PropTypes.string.isRequired,
    moduleId: PropTypes.number.isRequired
  }).isRequired,
  requestNewInvoiceTitle: PropTypes.string,
  requestNewInvoiceText: PropTypes.string,
  requestNewInvoiceSuccessMessage: PropTypes.string,
  isPaid: PropTypes.bool,
  dueDateLabel: PropTypes.string,
  dueDate: PropTypes.string,
  isOverdue: PropTypes.bool,
  statusLabel: PropTypes.string,
  status: PropTypes.string,
  amountDueLabel: PropTypes.string,
  amountDue: PropTypes.string,
  settledAmountLabel: PropTypes.string,
  settledAmount: PropTypes.string,
  payedStatusUncertain: PropTypes.bool,
  isWebOrder: PropTypes.bool
}

export default InvoiceSummaryDetails
