import { connect } from 'react-redux'
import Search from './Search'
import { getTranslation } from '../../modules/translations/translations.utils'
import { getCommonTranslations } from '../../modules/translations/translations.selectors'

const mapStateToProps = (state: any) => {
  const translations = getCommonTranslations(state)
  return {
    searchLabel: getTranslation(translations, 'search')
  }
}

export default connect(mapStateToProps)(Search)
