import React, { useState, useEffect } from 'react'
import { Typography, Box, Link, Button } from '@material-ui/core'
import './top-hero.scss'
import useMediaQuery from '@material-ui/core/useMediaQuery'

type MainContentProps = {
  ctaText?: string
  ctaUrl?: string
  textAlignment?: number
  textColor?: number
  title?: string
  description?: string
  useHeadingUnderMedia?: boolean
  useCompactLayout?: boolean
  useHeadingInsideMediaMobile?: boolean
  buttonVariant?: number
  nlgHero?: boolean
}

const colors = ['#ffffff', '#009DE0', '#000000']

const colorMapping: Record<number, string> = {
  0: colors[0],
  1: colors[1],
  2: colors[2],
  3: colors[2],
  4: colors[2]
}

const MainContent = ({
  ctaText,
  ctaUrl,
  textAlignment = 0,
  textColor = 0,
  title,
  description,
  useHeadingUnderMedia,
  useCompactLayout,
  useHeadingInsideMediaMobile,
  buttonVariant = 0,
  nlgHero = false
}: MainContentProps) => {
  const [contentWidth, setContentWidth] = useState('100%')
  const isMobile = useMediaQuery('(max-width:600px)')

  useEffect(() => {
    const handleScroll = () => {
      const newWidth = Math.max(100 - window.scrollY / 3, 95) + '%'
      setContentWidth(newWidth)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const alignmentStyles = [
    {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      paddingLeft:
        useHeadingUnderMedia && !isMobile
          ? 0
          : useHeadingUnderMedia && isMobile
          ? ''
          : '40px'
    },
    { display: 'flex', justifyContent: 'center', alignItems: 'center' },
    { display: 'flex', justifyContent: 'center', alignItems: 'flex-end' },
    {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      paddingTop: '160px',
      paddingLeft: '40px'
    },
    {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingTop: nlgHero ? '32px' : '160px'
    },
    {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
      paddingTop: '160px'
    },
    {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      paddingBottom: '50px',
      paddingLeft: '40px'
    },
    {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      paddingBottom: '50px'
    },
    {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      paddingBottom: '50px'
    }
  ]

  const whiteOrNot =
    (useHeadingInsideMediaMobile && isMobile) ||
    (!useHeadingUnderMedia && !isMobile)

  const headingColor = whiteOrNot ? colorMapping[textColor] : '#000000'
  const descriptionColor = whiteOrNot ? colorMapping[textColor] : '#64666D'
  const linkColor = whiteOrNot
    ? colorMapping[textColor]
    : nlgHero && isMobile && buttonVariant === 1
    ? colorMapping[textColor]
    : '#009DE0'

  const alignmentStyle = alignmentStyles[textAlignment]

  const colorAndAlignment = {
    color: useHeadingUnderMedia || isMobile ? '#000000' : colors[textColor],
    alignSelf:
      useHeadingUnderMedia || isMobile
        ? 'flex-start'
        : [1, 4, 7].includes(textAlignment)
        ? 'center'
        : 'flex-start'
  }

  const isHeadingUnderMediaAndNotMobile =
    useHeadingUnderMedia && !isMobile && !useHeadingInsideMediaMobile
  const isHeadingInsideMediaMobileAndIsMobile =
    useHeadingInsideMediaMobile && isMobile
  const isNotHeadingUnderMediaAndNotMobile = !useHeadingUnderMedia && !isMobile

  let position = 'relative'

  if (isHeadingUnderMediaAndNotMobile) {
    position = 'relative'
  } else if (
    isHeadingInsideMediaMobileAndIsMobile ||
    isNotHeadingUnderMediaAndNotMobile
  ) {
    position = 'absolute'
  }

  return (
    <Box
      className="main-content"
      width={
        (!useHeadingUnderMedia && !useCompactLayout && !isMobile) ||
        (useHeadingInsideMediaMobile && isMobile)
          ? contentWidth
          : !isMobile && useHeadingUnderMedia
          ? '1350px'
          : (useCompactLayout && isMobile) ||
            (isMobile && !useHeadingUnderMedia && !useHeadingInsideMediaMobile)
          ? '100%'
          : '100%'
      }
      position={position}
      display="flex"
      flexDirection="column"
      style={
        (useHeadingUnderMedia || isMobile) && !useHeadingUnderMedia
          ? {
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              paddingBottom:
                isMobile && useHeadingInsideMediaMobile
                  ? '42px'
                  : !isMobile && !useHeadingUnderMedia
                  ? '50px'
                  : '0px'
            }
          : alignmentStyle
      }
      p={isMobile && nlgHero ? '0px' : isMobile ? '16px' : '0px'}
      mt={useHeadingUnderMedia && !isMobile ? '16px' : '0px'}
    >
      <Box display="flex" flexDirection="column" align-items="flex-start">
        {title && (
          <Typography
            className="main-content__title"
            style={{
              ...colorAndAlignment,
              color: headingColor,
              lineHeight: nlgHero && !isMobile ? '34px' : '70px',
              fontSize: isMobile ? '48px' : nlgHero ? '24px' : '68px'
            }}
          >
            {title}
          </Typography>
        )}
        {description && (
          <Typography
            className="main-content__description"
            style={{
              ...colorAndAlignment,
              color: descriptionColor,
              width: isMobile ? '100%' : '600px',
              paddingTop: nlgHero ? '12px' : 'inherit',
              fontSize:
                (isMobile && useHeadingInsideMediaMobile) ||
                !useHeadingUnderMedia
                  ? '16px'
                  : '28px',
              textAlign:
                nlgHero && isMobile
                  ? 'left'
                  : [1, 4, 7].includes(textAlignment)
                  ? 'center'
                  : 'left'
            }}
          >
            {description}
          </Typography>
        )}

        {ctaText && ctaUrl && (
          <ButtonStyle
            ctaUrl={ctaUrl}
            colorAndAlignment={colorAndAlignment}
            linkColor={linkColor}
            ctaText={ctaText}
            buttonVariant={buttonVariant}
          />
        )}
      </Box>
    </Box>
  )
}

type ButtonStyleProps = {
  ctaUrl: string
  colorAndAlignment: any
  linkColor: string
  ctaText: string
  buttonVariant: number
}

const ButtonStyle = ({
  ctaUrl,
  colorAndAlignment,
  linkColor,
  ctaText,
  buttonVariant
}: ButtonStyleProps) => {
  return (
    <>
      {buttonVariant === 0 ? (
        <Link
          className="main-content__cta"
          href={ctaUrl}
          style={{ ...colorAndAlignment, color: linkColor, fontSize: '14px' }}
        >
          {ctaText}
        </Link>
      ) : (
        <Button
          href={ctaUrl}
          style={{
            ...colorAndAlignment,
            color: linkColor,
            fontSize: '14px',
            backgroundColor: '#009DE0',
            marginTop: '24px',
            padding: '12px 24px',
            borderRadius: '0px'
          }}
        >
          {ctaText}
        </Button>
      )}
    </>
  )
}

export default MainContent
