/** @typedef { import('./useForm.types').ElementDefinitionsMap } ElementDefinitionsMap */
/** @typedef { import('./useForm.types').ElementStatesMap } ElementStatesMap */

import { useMemo } from 'react'
import { isObject } from '../../utils/jsTypeUtils'

/**
 * @param {ElementDefinitionsMap} elementDefinitions
 * @param {string[]} elementNames
 * @returns {ElementStatesMap}
 */
export default function useInitialElementStates(
  elementDefinitions,
  elementNames
) {
  return useMemo(
    () =>
      elementNames.reduce((initalValues, name) => {
        let definition = elementDefinitions[name]

        if (!isObject(definition)) {
          definition = { defaultValue: definition }
        }

        const value =
          typeof definition.defaultValue === 'undefined'
            ? ''
            : definition.defaultValue

        const required = definition.required === true

        const elementState = {
          value,
          required,
          isValid: true,
          isHidden: false
        }

        return { ...initalValues, [name]: elementState }
      }, {}),
    [elementNames, elementDefinitions]
  )
}
