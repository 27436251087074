import React from 'react'

const WeiboIcon = ({ ...props }) => (
  <svg width="20" height="16" fill="currentColor" {...props}>
    <path
      d="M1.47 11.005c0 2.3 3.033 4.165 6.774 4.165s6.773-1.865 6.773-4.165S11.985 6.84 8.244 6.84c-3.74 0-6.773 1.865-6.773 4.165Z"
      fill="#fff"
    />
    <path d="M8.402 14.83c-3.311.322-6.17-1.156-6.385-3.303-.215-2.146 2.296-4.148 5.606-4.471 3.312-.324 6.17 1.154 6.385 3.3.214 2.147-2.295 4.15-5.606 4.473Zm6.622-7.127c-.281-.083-.475-.14-.327-.506.32-.793.352-1.478.006-1.966-.65-.916-2.426-.867-4.462-.025 0-.001-.64.277-.476-.224.313-.995.266-1.828-.222-2.308-1.104-1.092-4.042.04-6.561 2.528C1.095 7.065 0 9.04 0 10.748 0 14.014 4.241 16 8.39 16c5.44 0 9.058-3.121 9.058-5.6 0-1.496-1.277-2.346-2.424-2.697ZM18.636 1.726a5.334 5.334 0 0 0-5.04-1.611.77.77 0 0 0-.483.328.75.75 0 0 0 .227 1.046.771.771 0 0 0 .576.105 3.797 3.797 0 0 1 3.583 1.145c.443.485.749 1.076.887 1.714a3.672 3.672 0 0 1-.1 1.923.748.748 0 0 0 .232.809.775.775 0 0 0 .845.098.759.759 0 0 0 .38-.44v-.004a5.177 5.177 0 0 0-1.107-5.113Z" />
    <path d="M16.618 3.523a2.596 2.596 0 0 0-2.455-.783.664.664 0 0 0-.429.278.646.646 0 0 0 .443 1.004c.087.014.175.01.261-.01a1.28 1.28 0 0 1 1.2.383 1.239 1.239 0 0 1 .263 1.219.644.644 0 0 0 .041.497c.08.153.218.27.384.323a.667.667 0 0 0 .503-.04.654.654 0 0 0 .327-.38 2.519 2.519 0 0 0-.538-2.49ZM8.584 10.943c-.116.196-.372.29-.572.208-.197-.08-.26-.299-.147-.491.115-.191.361-.285.558-.208.2.072.272.294.161.49ZM7.53 12.28c-.32.505-1.006.726-1.523.493-.509-.229-.659-.815-.338-1.307.316-.49.979-.709 1.492-.496.518.218.684.8.37 1.31ZM8.733 8.71c-1.576-.405-3.357.37-4.041 1.742-.697 1.398-.023 2.95 1.568 3.458 1.65.525 3.593-.28 4.268-1.79.667-1.475-.165-2.994-1.795-3.41Z" />
  </svg>
)

export default WeiboIcon
