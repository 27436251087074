export const toggleClass = (element, className, toggle) => {
  const hasClass = element.classList.contains(className)

  if (toggle && !hasClass) {
    element.classList.add(className)
  }

  if (!toggle && hasClass) {
    element.classList.remove(className)
  }
}

export const toggleNoScrollBody = toggle => {
  const BODY = document.body
  const noScrollClass = 'no-scroll'
  toggleClass(BODY, noScrollClass, toggle)
}
