import { getAllOrders } from './orders.selectors'

export const convertOrdersToLinkListItems = orders => {
  return orders.map(order => ({
    title: order.orderId,
    description: order.orderType,
    created: order.created,
    orderName: order.orderName,
    path: `/order/${order.orderId}/${order.orderType}`
  }))
}

export const getOrder = (state, orderId) => {
  return getAllOrders(state).find(x => x.orderId === orderId)
}
