import React from 'react'

export default function ExpandedIcon(svgProps) {
  return (
    <svg width="28px" height="28px" {...svgProps}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          transform="translate(5 14) skewX(.068)"
          stroke="#000"
          stroke-width="1.5"
          d="M0-.75h18.644"
        />
      </g>
    </svg>
  )
}
