import React from 'react'
import PropTypes from 'prop-types'
import ArrowDownSvg from '../_svg/ArrowDown'
import TextLinkAction from '../TextLinkAction'

import './downloads-doc-list.scss'

const DownloadDocList = ({
  documents,
  requestDocTitle,
  requestDocsItemsText,
  emailRecipient
}) => {
  const onClickTextLinkAction = event => {
    event.preventDefault()
    const hrefQuery = `Subject=${encodeURIComponent('Request for document')}`
    document.location.href = `mailto:${emailRecipient}?${hrefQuery}`
  }

  return (
    <ul className="downloads-doc-list">
      {documents.map((item, index) => (
        <li className="downloads-doc-list__item" key={index}>
          <a className="downloads-doc-list__link" href={item.url} download>
            <span className="downloads-doc-list__icon">
              <ArrowDownSvg />
            </span>
            <span className="downloads-doc-list__name">{item.name}</span>
          </a>
        </li>
      ))}

      {requestDocTitle && emailRecipient ? (
        <div className="downloads-doc-list__action">
          <span className="downloads-doc-list__action-title">
            {requestDocTitle}
          </span>
          <TextLinkAction onClick={onClickTextLinkAction}>
            {requestDocsItemsText}
          </TextLinkAction>
        </div>
      ) : (
        ''
      )}
    </ul>
  )
}

DownloadDocList.propTypes = {
  requestDocTitle: PropTypes.string,
  requestDocsItemsText: PropTypes.string,
  emailRecipient: PropTypes.string,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string
    })
  )
}

export default DownloadDocList
