import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import Loader from '../../components/Loader'

import './fetch-container.scss'

const useOnMount = fn => {
  useEffect(fn, [])
}

const onPropertyUpdate = (properties, fn) => {
  useEffect(fn, properties)
}

const FetchContainer = ({
  isFetching = false,
  fetchingError = {},
  children = {},
  fetchFn = null,
  fetchFnArgs = {},
  errorMessageTranslation = '',
  redirectUserErrorMessage = ''
}) => {
  const [startedFetching, setFetchingStatus] = useState(false)
  const [redirectPath, setRedirectPath] = useState(undefined)
  // const [isMounted, setIsMounted] = useState(false)

  useOnMount(() => {
    fetchFn(fetchFnArgs)
    setFetchingStatus(true)
    // setIsMounted(true)

    return () => {
      // setIsMounted(false)
      // console.log(isMounted)
    }
  })

  onPropertyUpdate([fetchingError], () => {
    let timer
    if (fetchingError && !isFetching) {
      timer = setTimeout(() => {
        setRedirectPath('/')
      }, 2000)
    }

    return () => {
      clearTimeout(timer)
    }
  })

  if (
    fetchingError &&
    fetchingError.status === 403 &&
    fetchingError.data.redirectUrl
  ) {
    // Redirect to external url
    window.location.href = fetchingError.data.redirectUrl
    return (
      <div className="fetch-container-error">
        <span>{redirectUserErrorMessage || errorMessageTranslation}</span>
        <Loader />
      </div>
    )
  }

  if (redirectPath) {
    return <Redirect to={redirectPath} />
  }

  if (fetchingError && !isFetching) {
    return (
      <div className="fetch-container-error">{errorMessageTranslation}</div>
    )
  }

  if (!startedFetching || isFetching || fetchingError != null) {
    return <Loader />
  }

  return children
}

FetchContainer.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  fetchingError: PropTypes.object,
  children: PropTypes.object.isRequired,
  fetchFn: PropTypes.func.isRequired,
  fetchFnArgs: PropTypes.object,
  errorMessageTranslation: PropTypes.string,
  redirectUserErrorMessage: PropTypes.string
}

export default FetchContainer
