import React from 'react'

export function CheckboxIcon() {
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x=".5" y=".5" width="19" height="19" rx="1.5" stroke="#64666D" />
    </svg>
  )
}

export function CheckedIcon() {
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#a)">
        <rect width="20" height="20" rx="2" fill="#009DE0" />
        <path d="m15.667 6.667-6.6 6.266L5 10" stroke="#fff" />
      </g>
      <defs>
        <clipPath id="a">
          <rect width="20" height="20" rx="2" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  )
}
