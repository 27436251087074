/** @typedef { import('./useForm.types').ElementDefinitionsMap } ElementDefinitionsMap */

import { useMemo } from 'react'

const { keys } = Object

/**
 * @param {ElementDefinitionsMap} elementDefinitions
 * @returns {string[]}
 **/
export default function useElementNames(elementDefinitions) {
  return useMemo(() => keys(elementDefinitions), [elementDefinitions])
}
