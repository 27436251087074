import { connect } from 'react-redux'
import DetailedProdutInfo from './DetailedProdutInfo'
import { getOrderListingCommonTranslations } from '../../../../modules/translations/translations.selectors'
import { getTranslation } from '../../../../modules/translations/translations.utils'
import { isDateMinValue } from 'Core/utils/dateUtils'
// import { addUrlProtocol } from 'Core/utils/url'

const mapPropsToState = (
  state,
  { confirmedShippingDate, requestedShippingDate, trackingInfo }
) => {
  const portalTranslations = getOrderListingCommonTranslations(state)
  const { customerOrders } = state

  const hasConfirmedShippingDate =
    isDateMinValue(new Date(confirmedShippingDate)) === false

  return {
    statusLabel: getTranslation(portalTranslations, 'lineItemStatus'),
    date: hasConfirmedShippingDate
      ? confirmedShippingDate
      : requestedShippingDate,
    dateLabel: hasConfirmedShippingDate
      ? getTranslation(portalTranslations, 'confirmedShippingDate')
      : getTranslation(portalTranslations, 'requestShippingDate'),
    itemsTranslation: getTranslation(
      getOrderListingCommonTranslations(state),
      'items'
    ),
    company: customerOrders.currentOrderDetails.orderType
    // trackingLabel: getTranslation(portalTranslations, 'tracking'),
    // trackingLinkLabel: getTranslation(portalTranslations, 'track'),
    // trackingInfo: trackingInfo.map(item => ({
    //   ...item,
    //   trackingUrl: addUrlProtocol(item.trackingUrl)
    // }))
  }
}

export default connect(mapPropsToState)(DetailedProdutInfo)
