import React from 'react'

const Search = ({ color, ...props }) => (
  <svg width="28" height="29" fill={color} {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.497 7.746a5.826 5.826 0 0 1 .005 8.246 5.842 5.842 0 0 1-8.255.004 5.826 5.826 0 0 1-.005-8.245 5.842 5.842 0 0 1 8.255-.005Zm1.807 8.44a7.325 7.325 0 0 0-.746-9.5A7.344 7.344 0 0 0 6.18 6.69a7.324 7.324 0 0 0 .006 10.366 7.345 7.345 0 0 0 10.113.246l4.97 4.969 1.06-1.06-5.025-5.025Z"
    />
  </svg>
)

export default Search
