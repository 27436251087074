import React from 'react'
import PropTypes from 'prop-types'
import DefaultProductInfo from '../ProductInfo/DefaultProductInfo'
import NumericInput from '../../NumericInput'

import './quantity-select-product-item.scss'

const QuantitySelectProductItem = ({ product, onChange }) => {
  const handleOnChange = quantity => {
    onChange({ name: product.name, quantity })
  }

  return (
    <li className="quantity-select-product-item">
      <div className="quantity-select-product-item-left-section">
        <DefaultProductInfo {...product} />
      </div>
      <div className="quantity-select-product-item-right-section">
        <NumericInput value={product.quantity} callBack={handleOnChange} />
      </div>
    </li>
  )
}

QuantitySelectProductItem.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    quantity: PropTypes.number.isRequired,
    invoiceLine: PropTypes.object
  }).isRequired,
  onChange: PropTypes.func.isRequired
}

export default QuantitySelectProductItem
