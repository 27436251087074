import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import Close from '../../../../Spa.Core/components/_svg/Close'
import Box from '@material-ui/core/Box'
import './language-picker.scss'
import { LanguageSelections } from '../../blocks/Header/types'
import List from '@material-ui/core/List'
import LanguageListTabs from './DesktopLanguagePickerTabs'
import ListItemLink from './ListItemLink'
import Grid from '@material-ui/core/Grid'
import chunkArray from './utils'

type DesktopLanguagePickerProps = {
  open: boolean
  handleClose: (arg: boolean) => void
  languageSelections: LanguageSelections
}

const DesktopLanguagePicker = ({
  open,
  handleClose,
  languageSelections
}: DesktopLanguagePickerProps) => {
  const data = languageSelections

  return (
    <Box className="language-picker-desktop">
      <Dialog
        open={open}
        fullScreen={true}
        fullWidth={true}
        className="language-picker-desktop__dialog"
      >
        <DialogActions>
          <IconButton
            className="language-picker-desktop__close"
            onClick={() => handleClose(false)}
            color="inherit"
          >
            <Close />
          </IconButton>
        </DialogActions>
        <DialogTitle className="language-picker-desktop__dialog-title">
          {data.title}
        </DialogTitle>
        {data.additionalLanguageSelections?.length > 0 ? (
          <LanguageListTabs data={data} />
        ) : (
          <List>
            <Grid container justifyContent="center">
              {chunkArray(data.fullVersionLanguageSelections, 5).map(
                (chunk, chunkIndex) => (
                  <Grid item xs={2} key={chunkIndex}>
                    {chunk.map((item: any, index: number) => (
                      <ListItemLink
                        key={index}
                        imageUrl={item.imageUrl}
                        displayName={item.displayName}
                        urlTarget={item.urlTarget}
                      />
                    ))}
                  </Grid>
                )
              )}
            </Grid>
          </List>
        )}
      </Dialog>
    </Box>
  )
}

export default DesktopLanguagePicker
