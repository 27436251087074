import React from 'react'

const HamburgerMenu = ({ color, ...props }) => (
    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" {...props}>
    <line x1="2" y1="7.75" x2="26" y2="7.75" stroke={color} stroke-width="1.5"/>
    <line x1="2" y1="13.75" x2="26" y2="13.75" stroke={color} stroke-width="1.5"/>
    <line x1="2" y1="19.75" x2="26" y2="19.75" stroke={color} stroke-width="1.5"/>
    </svg>    
)

export default HamburgerMenu