import React from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import List from '@material-ui/core/List'
import { LanguageSelections } from 'src/blocks/Header/types'
import ListItemLink from './ListItemLink'

const MobileLanguagePickerTabs = ({ ...props }) => {
  const [value, setValue] = React.useState(0)

  const data = props.languageSelections as LanguageSelections

  const handleTabChange = (_e: any, newValue: number) => {
    setValue(newValue)
  }

  return (
    <>
      <Tabs centered value={value} onChange={handleTabChange}>
        <Tab label={data.fullVersionTabTitle} />
        <Tab label={data.additionalVersionTabTitle} />
      </Tabs>
      {value === 0 && (
        <List className="language-tab-list">
          {data.fullVersionLanguageSelections.map((item, index) => (
            <ListItemLink
              imageUrl={item.imageUrl}
              displayName={item.displayName}
              urlTarget={item.urlTarget}
            />
          ))}
        </List>
      )}
      {value === 1 && (
        <List className="language-tab-list">
          {data.additionalLanguageSelections.map((item, index) => (
            <ListItemLink
              imageUrl={item.imageUrl}
              displayName={item.displayName}
              urlTarget={item.urlTarget}
            />
          ))}
        </List>
      )}
    </>
  )
}

export default MobileLanguagePickerTabs
