import {
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_REQUEST,
  FETCH_ORDERS_ERROR,
  FETCH_ORDERS_DETAILS_SUCCESS,
  FETCH_ORDERS_DETAILS_REQUEST,
  FETCH_ORDERS_DETAILS_ERROR
} from './orders.action'
import { orderDetailsTransform } from './orders.transform'

const initialState = {
  isFetchingOrders: false,
  fetchingOrdersError: null,
  orders: [],

  isFetchingOrderDetails: false,
  fetchingOrderDetailsError: null,
  currentOrderDetails: {
    items: [],
    payment: {},
    invoiceSummary: {},
    orderType: null,
    orderNumber: null,
    orderName: null
  }
}

export default function ordersReducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_ORDERS_REQUEST:
      return {
        ...state,
        isFetchingOrders: true,
        fetchingOrdersError: null
      }

    case FETCH_ORDERS_ERROR:
      return {
        ...state,
        isFetchingOrders: false,
        fetchingOrdersError: action.error
      }

    case FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        isFetchingOrders: false,
        orders: action.orders
      }

    case FETCH_ORDERS_DETAILS_REQUEST:
      return {
        ...state,
        isFetchingOrderDetails: true,
        fetchingOrderDetailsError: null
      }

    case FETCH_ORDERS_DETAILS_ERROR:
      return {
        ...state,
        isFetchingOrderDetails: false,
        fetchingOrderDetailsError: action.error
      }

    case FETCH_ORDERS_DETAILS_SUCCESS:
      return {
        ...state,
        isFetchingOrderDetails: false,
        currentOrderDetails: orderDetailsTransform(action.orderDetails)
      }

    default:
      return state
  }
}
