import { useEffect, useMemo } from 'react'
import { ElementDefinitionsMap, ElementValue } from '../useForm/useForm.types'
import {
  EpiFormElementBase,
  EpiFormInputElement,
  EpiFormElementPredefinedValue
} from '../../types/epi/form'
import { EpiFormElementsMap } from './useEpiForm.types'
import useForm from '../useForm'
import {
  getFormInputTypeDefaultValue,
  isElementConditionsSatisfied
} from './useEpiForm.utils'
import EpiFormSatisifedActions from '../../constants/EpiFormSatisifedActions'

interface DefaultValues {
  [name: string]: EpiFormElementPredefinedValue
}

const defaultDefaultValues: DefaultValues = {}

export default function useEpiForm(
  formElements: EpiFormElementBase[],
  defaultValues = defaultDefaultValues
) {
  const elementDefinitions = useMemo(
    () =>
      formElements.reduce((definitions, formElement) => {
        const { name } = formElement

        const inputFormElement = formElement as EpiFormInputElement
        const validators = (inputFormElement.validators || '').toLowerCase()

        let defaultValue
        if (defaultValues[name]) {
          defaultValue = defaultValues[name]
        } else if (inputFormElement.predefinedValue) {
          defaultValue = inputFormElement.predefinedValue
        } else {
          defaultValue = getFormInputTypeDefaultValue(formElement)
        }

        const required = validators.indexOf('required') !== -1

        definitions[name] = {
          name,
          defaultValue,
          required
        }

        return definitions
      }, {} as ElementDefinitionsMap),
    [formElements, defaultValues]
  )

  const formElementsMap = useMemo(
    () =>
      formElements.reduce(
        (formElementsMap: EpiFormElementsMap, formElement) => {
          formElementsMap[formElement.name] = formElement
          return formElementsMap
        },
        {}
      ),
    [formElements]
  )

  const dynamicElementNames = useMemo(() => {
    const satisfiedActionValues = Object.keys(EpiFormSatisifedActions).map(
      item => item
    )

    return formElements.reduce((dynamicElements: string[], formElement) => {
      const isDynamic =
        formElement.conditions &&
        formElement.conditions.length > 0 &&
        formElement.satisfiedAction &&
        satisfiedActionValues.includes(formElement.satisfiedAction)

      if (isDynamic) {
        dynamicElements.push(formElement.name)
      }
      return dynamicElements
    }, [])
  }, [formElements])

  const formState = useForm(elementDefinitions)

  useEffect(() => {
    const { inputProps } = formState

    dynamicElementNames.forEach(dynamicElementName => {
      const inputProp = inputProps[dynamicElementName]
      const formElement = formElementsMap[dynamicElementName]
      const { satisfiedAction } = formElement

      const isSatisfied = isElementConditionsSatisfied(formElement, inputProps)

      switch (satisfiedAction) {
        case EpiFormSatisifedActions.Hide:
          inputProp.onHiddenChange(isSatisfied)
          break

        case EpiFormSatisifedActions.Shown:
          inputProp.onHiddenChange(!isSatisfied)
          break
      }
    })
  })

  return formState
}
