import React from 'react'
import { ImageBannerItemProps } from './types'
import Box from '@material-ui/core/Box'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import './imageBanner.scss'

type Props = {
  imageAlignment: number
  isOneSlide?: boolean
} & ImageBannerItemProps

const ImageBannerItem = ({
  buttonLink,
  buttonTitle,
  description,
  heading,
  imageUrl,
  subheading,
  imageAlignment,
  isOneSlide
}: Props) => {
  const isSmall = useMediaQuery('(max-width: 1140px)')

  return (
    <Box
      width={isSmall && !isOneSlide ? '326px' : '100%'}
      mr={isSmall && !isOneSlide ? '8px' : ''}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: isSmall
            ? 'column'
            : imageAlignment === 2
            ? 'row-reverse'
            : 'row'
        }}
      >
        <div style={{ flex: isSmall ? '0 0 100%' : '0 0 50%' }}>
          <img
            src={imageUrl}
            alt={heading}
            width="100%"
            className="image-banner-item__image"
          />
        </div>
        <div
          style={{
            flex: isSmall ? '0 0 100%' : '0 0 50%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Box width={isSmall ? '100%' : '459px'}>
            <h2 className="image-banner-item__heading">{heading}</h2>
            <h2 className="image-banner-item__subheading">{subheading}</h2>
            <p className="image-banner-item__description">{description}</p>
            <button className="image-banner-item__button">
              <a href={buttonLink}>{buttonTitle}</a>
            </button>
          </Box>
        </div>
      </div>
    </Box>
  )
}
export default ImageBannerItem
