import { get, post } from '../../api'

const API_ENDPOINT_FETCH_ORDERS = '/api/order/getCustomerOrderSummaries'
const API_ENDPOINT_FETCH_ORDERS_ITEMS = '/api/order/getCustomerOrderItems'
const API_ENDPOINT_REQUEST_NEW_INVOICE = '/api/order/requestNewInvoice'
const API_ENDPOINT_REQUEST_QUOTE = '/api/order/requestNewQuote'

const createDefaultHeaders = locale => ({
  Accept: 'application/json',
  'X-Locale': locale
})

export const getCustomerOrders = locale => {
  return get(API_ENDPOINT_FETCH_ORDERS, {
    headers: createDefaultHeaders(locale)
  })
}

export const getOrderDetails = (locale, orderNumber, orderType, moduleId) => {
  return post(
    API_ENDPOINT_FETCH_ORDERS_ITEMS,
    {
      orderNumber,
      orderType,
      moduleId
    },
    { headers: createDefaultHeaders(locale) }
  )
}

export const requestNewInvoice = (
  locale,
  invoiceNumber,
  orderNumber,
  orderType,
  moduleId
) => {
  return post(
    API_ENDPOINT_REQUEST_NEW_INVOICE,
    {
      orderNumber,
      invoiceNumber,
      orderType,
      moduleId
    },
    { headers: createDefaultHeaders(locale) }
  )
}

export const requestQuote = (
  locale,
  orderNumber,
  moduleId,
  requestedDeliveryDate,
  items
) => {
  return post(
    API_ENDPOINT_REQUEST_QUOTE,
    {
      orderNumber,
      moduleId,
      requestedDeliveryDate,
      items
    },
    { headers: createDefaultHeaders(locale) }
  )
}
