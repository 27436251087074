import React from 'react'

const BoltightBrand = () => (
  <svg className="icon">
    <use
      xlinkHref={`/Static/Images/icons.svg?=${process.env.BUILD_VERSION_ID}#icon--boltight-brand`}
    />
  </svg>
)

export default BoltightBrand
