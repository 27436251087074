import { connect } from 'react-redux'
import QuantitySelectProductList from '../../../../components/ProductList/QuantitySelectProductList'
import { getTranslation } from '../../../../modules/translations/translations.utils'

const mapStateToProps = ({ customerOrders, translations }, qouteList) => {
  const portalOrderListingTranslations =
    translations.commonTranslations.portalOrderListingTranslationsBlock

  return {
    products: customerOrders.currentOrderDetails.items.map(product => ({
      product
    })),
    title: getTranslation(portalOrderListingTranslations, 'orderContents')
  }
}

export default connect(mapStateToProps)(QuantitySelectProductList)
