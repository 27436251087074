import Box from '@material-ui/core/Box'
import React, { useState, useEffect } from 'react'
import './contentMenu.scss'

export type ContentMenuProps = {
  href: string | null
  target: string | null
  title: string
  text: string
}

type Props = {
  menu: ContentMenuProps[]
}

const ContentMenu = ({ menu }: Props) => {
  const [activeItem, setActiveItem] = useState('')

  const handleItemClick = (item: ContentMenuProps) => {
    setActiveItem(item.title)
  }

  useEffect(() => {
    const currentPath = window.location.pathname
    const activeMenuItem = menu.find(item => item.href === currentPath)
    if (activeMenuItem) {
      setActiveItem(activeMenuItem.title)
    }
  }, [menu])

  return (
    <Box component="ul" className="contentMenu--container">
      {menu.map((item, i) => {
        const isActive = item.title === activeItem

        return (
          <li
            key={i}
            className={`contentMenu--item ${isActive ? 'active' : ''}`}
            onClick={() => handleItemClick(item)}
          >
            {item.href !== null && <a href={item.href}>{item.title}</a>}
            {item.target !== null && (
              <a href="#" target={item.target}>
                {item.title}
              </a>
            )}
            {item.href === null && item.target === null && (
              <span>{item.title}</span> // only for debugging
            )}
          </li>
        )
      })}
    </Box>
  )
}

export default ContentMenu
