import React, {useRef, useEffect} from "react";
import AdyenCheckout from '@adyen/adyen-web';
import './adyenStyle.scss'
import '@adyen/adyen-web/dist/adyen.css';

type OnCompletedProps = {
    orderGroupId: number
    transactionId: string
    locale: string   
    resultCode: string
}

type Adyen = {
    config: Config
}

type Config = {
    orderGroupId: number
    transactionId: string
    locale: string   
    domain: string,
    environment: string
    clientKey: string
    session: Session,
}

type AdyenProps = {
    environment: string
    clientKey: string
    session: Session,
    onPaymentCompleted: (result: any, component: any) => void
    onError: (error: any, component: any) => void
    paymentMethodsConfiguration: any
}

type Session = {
    id: string
    sessionData: string
}

type Props = OnCompletedProps & Adyen

export default function Adyen({ 
    config
    }: Props)  {

    const  style = {
        base: {
            'caret-color': 'black'
        }
    }

    const adyenElementRef = useRef(null)

    useEffect(() => {
        const adyenConfig: AdyenProps = {
            clientKey: config.clientKey,
            environment: config.environment,
            session: config.session,
            paymentMethodsConfiguration: {
                card: {
                  styles: style,
                },
                storedCard: {
                  styles: style,
                }
              },
            onPaymentCompleted: (result, component) => {
                component.setStatus('loading');
                
                const spinnerElement = document.getElementById("spinner");
                if (spinnerElement) {
                    spinnerElement.style.position = "absolute";
                    spinnerElement.style.display = "block";
                }

                const data: OnCompletedProps = {
                    locale: config.locale,
                    orderGroupId: config.orderGroupId,
                    transactionId: config.transactionId,
                    resultCode: result.resultCode,
                }
                console.log("result, state: ", result)
                fetch(config.domain + '/api/adyen/verifypayment', {
                    method: 'POST', 
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                })
                .then(res => res.json())
                .then(data => {
                    console.log("data: ", data)
                    window.location.href = data.redirectUrl
                    component.setStatus('loading');
                })
                .catch(error => {
                    console.log("error: ", error)
                    window.history.back()

                }) 
                console.info(result, component);
            },
            onError: (error, component) => {
                console.error(error.name, error.message, error.stack, component);
                window.history.back()
            },
        }
        if(adyenElementRef && adyenElementRef.current) {
            AdyenCheckout(adyenConfig).then((checkout) => {
                checkout.create('dropin').mount(adyenElementRef.current ? adyenElementRef.current : "")
            })
        }
    }, [adyenElementRef, config])

    return(
        <div id="dropin-container" ref={adyenElementRef}></div>
    )
}


