import useMenuButton from './useMegaMenuButton'
import React, { useEffect, useRef, useState } from 'react'
import MegaMenuContent from './MegaMenuContent'
import {
  HeaderNavigationLinks,
  MegaMenuFooterBlock,
  SearchSettingsBlock
} from '../Header/types'
import Button from '@material-ui/core/Button'
import { HEADER_HEIGHT } from '../Header/DesktopHeader/DesktopHeader'
import { classNames } from '../../utils/classNames'

type Props = {
  item?: HeaderNavigationLinks
  menuFooterBlock?: MegaMenuFooterBlock[]
  isFirstItem?: boolean
  isEmpty?: boolean
  isSearch?: boolean
  children?: React.ReactNode
  searchSettingsBlock?: SearchSettingsBlock
}

const MegaMenuButton = ({
  item,
  menuFooterBlock,
  children,
  isEmpty = false,
  isSearch = false,
  searchSettingsBlock
}: Props) => {
  const { buttonProps, itemProps, isOpen, setIsOpen } = useMenuButton(
    (item !== undefined &&
      item.megaMenuBlocks !== null &&
      item.megaMenuBlocks.length) ||
      0,
    {
      disableCloseOnClick: true,
      hasSearch: isSearch
    }
  )

  useEffect(() => {
    // Will activate/deactivate the global backdrop, CSS rules in global css found in theme file
    if (isOpen) {
      document.body.classList.add(`menuOpen`)
      // document.body.classList.add(`menuOpen-${itemIdClass}`)
    } else {
      document.body.classList.remove(`menuOpen`)
      // document.body.classList.remove(`menuOpen-${itemIdClass}`)
    }
  }, [isOpen])

  const elementRef = useRef<HTMLButtonElement>(null)

  const handleMegaMenuItemClick = (
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (isEmpty) {
      setIsOpen(false)
    } else {
      setIsOpen(true)
    }
  }

  return (
    <>
      <Button
        {...buttonProps}
        style={{
          padding: isSearch ? '0' : '0 21px',
          height: HEADER_HEIGHT
        }}
        disableRipple={true}
        className={classNames(
          'megamenu-button',
          isSearch ? 'search-button' : ''
        )}
        onClick={() => handleMegaMenuItemClick(setIsOpen)}
        ref={elementRef}
        onMouseOver={() => (isEmpty ? setIsOpen(false) : setIsOpen(true))}
        href={item?.url}
      >
        {children ? children : item!.title}
      </Button>
      <MegaMenuContent
        item={isEmpty ? null : item!}
        isSearch={isSearch}
        isOpen={isOpen}
        itemProps={itemProps}
        menuFooterBlock={isEmpty ? undefined : menuFooterBlock}
        buttonRef={elementRef}
        searchSettingsBlock={searchSettingsBlock}
      />
    </>
  )
}

export default MegaMenuButton
