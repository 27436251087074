import React from 'react'
import './styles/main.scss'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import PageModelContext from './contexts/PageModelContext'
import reactBlockComponents from './blocks'
import { getPageModel } from './modules/translations/translations.utils'
import { getAllPageModels } from './modules/translations/translations.selectors'
import Bootstrapper from './setup/Bootstrapper'
import createStore from './store/createStore'

const store = createStore(window.NordLock)
const bootstrapper = new Bootstrapper()
bootstrapper.addComponentDefinitions(reactBlockComponents)

const renderComponent = (Component, containerElement, dataAttr) => {
  const { moduleId } = dataAttr
  const pageModel = getPageModel(getAllPageModels(store.getState()), moduleId)

  render(
    <Provider store={store}>
      <PageModelContext.Provider value={pageModel}>
        <Component {...dataAttr} />
      </PageModelContext.Provider>
    </Provider>,
    containerElement
  )
}

bootstrapper.getComponentsToRender().forEach(component => {
  renderComponent(
    component.componentConstructor,
    component.containerElement,
    component.dataAttr
  )
})
