import React from 'react'

export default function Close(svgProps) {
  return (
    <svg height="32" width="32" viewBox="0 0 32 32" {...svgProps}>
      <title>Close</title>
      <path
        className="svg-fill"
        d="M32.067 28.067l-12.24-12.12 12.12-12.24-3.88-3.64-12.080 12.173-12.24-12.107-3.68 3.747 12.187 12.187-12.187 12.187 3.8 3.813 12.2-12.32 12.24 12.133z"
      ></path>
    </svg>
  )
}
