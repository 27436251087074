import { connect } from 'react-redux'
import OrderDetails from './OrderDetails'
import {
  getPageModel,
  getTranslation
} from '../../../modules/translations/translations.utils'
import {
  getAllPageModels,
  getCommonTranslations,
  getOrderListingCommonTranslations
} from '../../../modules/translations/translations.selectors'

const mapStateToProps = (state, ownProps) => ({
  currentOrderId: ownProps.match.params.id,
  currentOrderDetails: state.customerOrders.currentOrderDetails,
  currentOrderType: ownProps.match.params.orderType,
  currentOrderName: ownProps.match.params.orderName,
  moduleId: ownProps.moduleId,
  commonTranslations: getCommonTranslations(state),
  orderLabel: getTranslation(getOrderListingCommonTranslations(state), 'order'),
  salesRepresentative: getPageModel(getAllPageModels(state), ownProps.moduleId)
    .salesRepresentative
})

export default connect(mapStateToProps)(OrderDetails)
