import React, { useEffect } from 'react'
import DelayLink from '../DelayLink'
import { inActiveWrapper } from './DetailWrapper.utils'
import { makeHeaderSolid, isHeaderSolid } from '../../utils/headerHelper'
import { toggleNoScrollBody } from '../../utils/domHelper'
import PropTypes from 'prop-types'
import SliderArrowLeftSvg from '../_svg/SliderArrowLeft'

import './DetailWrapper.scss'

const DetailWrapper = ({ children, path }) => {
  let headerSolidOnMount

  useEffect(() => {
    // Mount
    headerSolidOnMount = isHeaderSolid()
    toggleNoScrollBody(true)
    makeHeaderSolid(true)

    return () => {
      // Unmount

      // if header was solid when we wrapped => keep it solid
      if (!headerSolidOnMount) {
        makeHeaderSolid(false)
      }

      toggleNoScrollBody(false)
    }
  }, [])

  return (
    <div className="detail-wrapper">
      <div className="detail-wrapper__component">
        <DelayLink
          to={path}
          delay={200}
          onDelayStart={() => {
            inActiveWrapper('detail-wrapper')
          }}
        >
          <div className="detail-wrapper-back-arrow">
            <SliderArrowLeftSvg />
          </div>
        </DelayLink>

        {children}
      </div>
    </div>
  )
}

DetailWrapper.propTypes = {
  children: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired
}

export default DetailWrapper
