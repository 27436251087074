import React from 'react'
import { classNames } from '../../../utils/classNames'

export interface BaseInputProps<ElementType, ValueType = any> {
  name?: string
  value?: ValueType
  type?: string
  disabled?: boolean
  required?: boolean

  isValid?: boolean
  isTouched?: boolean
  isHidden?: boolean

  className?: string
  children?: React.ReactNode

  element?:
    | string
    | React.ComponentType<{
        className: string
        onChange: (event: React.ChangeEvent<ElementType>) => void
        ref?: React.RefObject<any>
      }>
  forwardRef?: React.RefObject<any>

  onChange?: (value: ValueType, event: React.ChangeEvent<ElementType>) => void
  onBlur?: (event: React.FocusEvent<ElementType>) => void
  onFocus?: (event: React.FocusEvent<ElementType>) => void
  onHiddenChange?: () => void
}

export default function BaseInput<
  ElementType extends { value: any } = HTMLInputElement
>({
  // Just don't pass through
  isValid,
  isTouched,
  isHidden,
  onHiddenChange,
  className,

  element: Element = 'input',
  forwardRef,

  onChange,

  ...elementProps
}: BaseInputProps<ElementType>) {
  function onElementChange(event: React.ChangeEvent<ElementType>) {
    if (onChange) {
      onChange(event.target.value, event)
    }
  }

  if (isHidden) {
    return null
  }

  return (
    <Element
      className={classNames('base-input', className)}
      ref={forwardRef}
      onChange={onElementChange}
      {...elementProps}
    />
  )
}
