import { useState, useEffect } from 'react'
import { EpiFormElementBase } from 'Core/types/epi/form'
import { InputPropsMap } from 'Core/hooks/useForm/useForm.types'
import { isElementConditionsSatisfied } from 'Core/hooks/useEpiForm/useEpiForm.utils'
import EpiFormSatisifedActions from 'Core/constants/EpiFormSatisifedActions'

export default function useIsContainerElementHidden(
  formElement: EpiFormElementBase,
  inputProps: InputPropsMap
) {
  const [isHidden, setIsHidden] = useState(false)

  useEffect(() => {
    const { satisfiedAction } = formElement
    const isSatisified = isElementConditionsSatisfied(formElement, inputProps)

    const shouldHide =
      (satisfiedAction === EpiFormSatisifedActions.Hide && isSatisified) ||
      (satisfiedAction === EpiFormSatisifedActions.Shown && !isSatisified)

    if (shouldHide !== isHidden) {
      setIsHidden(shouldHide)
    }
  }, [formElement, inputProps])

  return isHidden
}
