import React from 'react'

const Close = props => (
  <svg width="28px" height="29px" viewBox="0 0 28 29" {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="m14.188 15.13 6.271 6.278 1.06-1.06-6.27-6.28 5.84-5.846-1.06-1.062-5.84 5.847-5.845-5.851-1.06 1.061 5.844 5.851-6.275 6.283 1.06 1.062 6.275-6.283Z"
      fill="currentColor"
    />
  </svg>
)

export default Close
