import React from 'react'

const SliderArrowRight = () => (
  <svg className="icon">
    <use
      xlinkHref={`/Static/Images/icons.svg?=${
        process.env.BUILD_VERSION_ID
      }#icon--slider-arrow-right`}
    />
  </svg>
)

export default SliderArrowRight
