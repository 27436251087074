export default class HttpError extends Error {
  static fromResponse(response, responseData) {
    return new HttpError(response.statusText, response.status, responseData)
  }

  constructor(message, status = 500, data = {}) {
    super(message)

    this.name = this.constructor.name
    this.status = status
    this.data = data

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor)
    } else {
      this.stack = new Error(message).stack
    }
  }
}
