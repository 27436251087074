import React, { useState, useEffect } from 'react';
import { IconButton } from '@material-ui/core';
import CartIcon from '../_svg/CartIcon';
import CartService from '../../../../Brand/js/services/commerce/CartService';
import './cart.scss';

type CartProps = {
  hasHero: boolean;
  scrolled: boolean;
  isMouseOver: boolean;
  headerCartActionButtonText: string;
  checkoutPageUrl: string;
  quantityShort: string;
  cartIsEmpty: string;
  showBasket: boolean;
  isShopAvailable: boolean;
};

const Cart: React.FC<CartProps> = ({
  hasHero,
  scrolled,
  isMouseOver,
  headerCartActionButtonText,
  checkoutPageUrl,
  quantityShort,
  cartIsEmpty,
  showBasket,
  isShopAvailable,
}) => {
  const [lineItems, setLineItems] = useState<any[]>([]);
  const [totalQuantity, setTotalQuantity] = useState<number>(0);
  const [isCartOpen, setIsCartOpen] = useState(false);

  useEffect(() => {
    const handleCartStateUpdate = (state: any) => {
      if (state && state.lineItems) {
        setLineItems(state.lineItems);
        calculateTotalQuantity(state.lineItems);
      }
    };

    const onCartCountChanged = async () => {
      const currentState = await CartService.getCurrentCartState();
      handleCartStateUpdate(currentState);
    };

    CartService.on(CartService.CART_UPDATED, handleCartStateUpdate);
    CartService.on(CartService.CART_ITEM_CHANGED_QUANTITY, handleCartStateUpdate);
    CartService.on(CartService.CART_ITEM_REMOVED, handleCartStateUpdate);

    onCartCountChanged();

    window.addEventListener('cartCountChanged', onCartCountChanged);

    return () => {
      CartService.off(CartService.CART_UPDATED, handleCartStateUpdate);
      CartService.off(CartService.CART_ITEM_CHANGED_QUANTITY, handleCartStateUpdate);
      CartService.off(CartService.CART_ITEM_REMOVED, handleCartStateUpdate);
      window.removeEventListener('cartCountChanged', onCartCountChanged);
    };
  }, []);

  const calculateTotalQuantity = (lineItems: any[]) => {
    const total = lineItems.reduce(
      (acc: number, lineItem: { quantity: number }) => acc + lineItem.quantity,
      0
    );
    setTotalQuantity(total);
  };

  const triggerHeaderCartToggle = () => {
    setIsCartOpen(!isCartOpen);
  };

  const handleMouseEnter = () => {
    setIsCartOpen(true);
  };

  const handleMouseLeave = () => {
    setIsCartOpen(false);
  };

  const getCartIconColor = () =>
    hasHero && !scrolled && !isMouseOver ? '#ffffff' : '#000000';

  return (
    <div>
      {(isShopAvailable && (showBasket || totalQuantity > 0)) && <>
        <IconButton
          className="cart__button"
          onClick={triggerHeaderCartToggle}
          aria-label="Cart"
          onMouseEnter={handleMouseEnter}
        >
          <CartIcon color={getCartIconColor()} />
          {totalQuantity > 0 && (
            <span className="cart__item-count">{totalQuantity}</span>
          )}
        </IconButton>
        <div
          className={`content-toggle-container__expand header-cart__expand ${isCartOpen ? 'open' : 'closed'
            }`}
          style={{ display: isCartOpen ? 'block' : 'none' }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="header-cart-details" data-module="HeaderCartDetails">
            {totalQuantity === 0 ? (
              <p className="header-cart-details__empty no-items" style={{ display: 'block' }}>{cartIsEmpty}</p>
            ) : (
              <>
                <ul className="header-cart-details__list">
                  {lineItems.map((lineItem: any, index: number) => (
                    <li
                      key={index}
                      className="header-cart-details__list-item"
                    >
                      <a className="header-cart-details__list-item-link" href={lineItem.url}>
                        <span className="header-cart-details__list-item-img">
                          <img src={lineItem.imageUrl} alt={lineItem.productName} />
                        </span>
                        <span className="header-cart-details__list-item-name">
                          {lineItem.productName}
                        </span>
                        <span className="header-cart-details__list-item-qty">
                          <span>{lineItem.quantity}</span>
                          <span> {quantityShort}</span>
                        </span>
                      </a>
                    </li>
                  ))}
                </ul>
                <div className="header-cart-details__checkoutbtn">
                  <a
                    href={checkoutPageUrl}
                    className="btn btn--is-primary"
                  >
                    {headerCartActionButtonText}
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
      </>
      }
    </div>
  );
};

export default Cart;
