import React from 'react'

const ExpanderIcon = () => (
  <svg className="icon">
    <use
      xlinkHref={`/Static/Images/icons.svg?=${
        process.env.BUILD_VERSION_ID
      }#icon--expander_icon`}
    />
  </svg>
)

export default ExpanderIcon
