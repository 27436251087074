import React from 'react'

const View = () => (
  <svg className="icon">
    <use
      xlinkHref={`/Static/Images/icons.svg?=${process.env.BUILD_VERSION_ID}#icon--view`}
    />
  </svg>
)

export default View
