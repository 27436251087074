import React from 'react'
import PropTypes from 'prop-types'
import LinkList from '../../../components/LinkList'

const OrderListing = ({ heading, linkListOrders, moduleId }) => (
  <LinkList title={heading} linkListData={linkListOrders} moduleId={moduleId} />
)

OrderListing.propTypes = {
  linkListOrders: PropTypes.array.isRequired,
  ordersCount: PropTypes.number,
  heading: PropTypes.string,
  moduleId: PropTypes.number
}

export default OrderListing
