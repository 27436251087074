import React, { PureComponent } from 'react'
import Proptypes from 'prop-types'

class TabContent extends PureComponent {
  static propTypes = {
    label: Proptypes.string.isRequired,
    children: Proptypes.any.isRequired
  }

  render() {
    const { children } = this.props
    return <div className="swipeable-tabs__content-item">{children}</div>
  }
}

export default TabContent
