import { connect } from 'react-redux'
import ExpandableProductItem from './ExpandableProductItem'
import { getOrderListingCommonTranslations } from '../../../modules/translations/translations.selectors'
import { getTranslation } from '../../../modules/translations/translations.utils'

const mapStateToProps = state => {
  const translations = getOrderListingCommonTranslations(state)

  return {
    viewTranslation: getTranslation(translations, 'view'),
    hideTranslation: getTranslation(translations, 'hide')
  }
}

export default connect(mapStateToProps)(ExpandableProductItem)
