import React, { useState, useEffect } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import ColorStripe from '../../../../../Spa.Core/components/ColorStripe'
import NordLockGroupIcon from '../../../components/_svg/NordLockGroupIcon'
import HamburgerMenu from '../../../../../Spa.Core/components/_svg/HamburgerMenu'
import Search from '../../../../../Spa.Core/components/_svg/Search'
import ColorScroll from '../ColorScroll'
import Drawer from '@material-ui/core/Drawer'
import MenuList from './MenuList'
import '../../TopHero/top-hero.scss'

import {
  HeaderNavigationLinks,
  LanguageSelections,
  MegaMenuFooterBlock,
  SearchSettingsBlock
} from '../types'

import './mobileHeader.scss'
import Cart from '../../../components/Cart'

type MobileHeaderProps = {
  hasHero: boolean
  contactButtonText: string
  contactButtonUrl: string
  navigationMenuBlocks: HeaderNavigationLinks[]
  megaMenuFooterBlocks: MegaMenuFooterBlock[]
  languageSelections: LanguageSelections
  searchSettingsBlock?: SearchSettingsBlock
  iconUrl: string
  currentLanguageSelected: string
  currentLocale: string
  headerCartActionButtonText: string
  checkoutPageUrl: string
  quantityShort: string
  cartIsEmpty: string
  showBasket: boolean
  isShopAvailable: boolean
}

const MobileHeader = ({
  contactButtonText,
  contactButtonUrl,
  navigationMenuBlocks,
  megaMenuFooterBlocks,
  languageSelections,
  hasHero,
  searchSettingsBlock,
  iconUrl,
  currentLanguageSelected,
  currentLocale,
  headerCartActionButtonText,
  checkoutPageUrl,
  quantityShort,
  cartIsEmpty,
  showBasket,
  isShopAvailable
}: MobileHeaderProps) => {
  const [iconColor, setIconColor] = useState('#ffffff')
  const [isMainMenuOpen, setMainMenuOpen] = useState(false)
  const [isSearchOpen, setSearchOpen] = useState(false)

  const handleScroll = () => {
    const scrollPos = window.scrollY
    if (scrollPos > 1) {
      setIconColor('#000000')
    } else {
      setIconColor('#ffffff')
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <>
      <ColorStripe />
      <ColorScroll>
        <AppBar className="mobileHeader">
          <Toolbar className="mobileHeader__toolbar">
            <IconButton
              onClick={() => setMainMenuOpen(true)}
              className="mobileHeader__menuIcon"
            >
              <HamburgerMenu color={!hasHero ? '#000000' : iconColor} />
            </IconButton>
            <Drawer
              open={isMainMenuOpen}
              onClose={() => setMainMenuOpen(false)}
            >
              <MenuList
                contactButtonText={contactButtonText}
                contactButtonUrl={contactButtonUrl}
                navigationMenuBlocks={navigationMenuBlocks}
                megaMenuFooterBlocks={megaMenuFooterBlocks}
                setMainMenuOpen={setMainMenuOpen}
                languageSelections={languageSelections}
                setSearchOpen={setSearchOpen}
                isSearchOpen={isSearchOpen}
                searchSettingsBlock={searchSettingsBlock}
                currentLanguageSelected={currentLanguageSelected}
                currentLocale={currentLocale}
              />
            </Drawer>
            <a href={iconUrl} className="mobileHeader__nordLockLink">
              <NordLockGroupIcon />
            </a>
            <Box display="flex" alignItems="center">
              <IconButton
                className="mobileHeader__searchIcon"
                onClick={() => {
                  setMainMenuOpen(true);
                  setSearchOpen(true);
                }}
              >
                <Search color={!hasHero ? '#000000' : iconColor} />
              </IconButton>
              <Cart
                hasHero={hasHero}
                scrolled={false}
                isMouseOver={false}
                headerCartActionButtonText={headerCartActionButtonText}
                checkoutPageUrl={checkoutPageUrl}
                quantityShort={quantityShort}
                cartIsEmpty={cartIsEmpty}
                showBasket={showBasket}
                isShopAvailable={isShopAvailable}
              />
            </Box>         
          </Toolbar>
        </AppBar>
      </ColorScroll>
    </>
  )
}

export default MobileHeader
