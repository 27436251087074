import React from 'react'

const TwitterIcon = ({ ...props }) => (
  <svg width="24" height="24" fill="currentColor" {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.778 22H4.222A2.222 2.222 0 0 1 2 19.778V4.222C2 2.994 2.994 2 4.222 2h15.556C21.006 2 22 2.994 22 4.222v15.556A2.222 2.222 0 0 1 19.778 22Zm-2.17-16-4.466 5.081L18 18h-3.573l-3.272-4.66L7.059 18H6l4.685-5.328L6 6h3.573l3.098 4.412L16.551 6h1.058Zm-6.391 6.066.475.664 3.233 4.526h1.626l-3.962-5.546-.475-.664L9.066 6.78H7.44l3.777 5.286Z"
    />
  </svg>
)

export default TwitterIcon
