import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ContentCardProps } from './types'

type Props = {
  item: ContentCardProps
  index?: number
  cardContainerClass?: string
  childrenBackgroundColor?: number
}

const ContentCard = ({
  item,
  index,
  cardContainerClass,
  childrenBackgroundColor
}: Props) => {
  const classes = useStyles()

  return (
    <Box
      key={index}
      className={`${cardContainerClass}`}
      style={{
        backgroundColor: childrenBackgroundColor === 0 ? '#ffffff' : '#FAFAFA'
      }}
      height="460px"
    >
      <img src={item.teaserImageUrl} className={classes.image} />
      <Box className={classes.contentContainer}>
        {item.teaserTag && (
          <Box className={classes.badge}>{item.teaserTag}</Box>
        )}
        <Box>
          <Typography component="h4" className={classes.title}>
            {item.teaserTitle}
          </Typography>
        </Box>
        <Box className={classes.description}>
          <Typography component="p">{item.teaserText}</Typography>
        </Box>
        <Box className={classes.readMore}>
          <Typography component="p">
            <a href={item.teaserTargetUrl}>{item.teaserLinkText}</a>
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(({ breakpoints }) => ({
  image: {
    width: '100%',
    objectFit: 'cover',
    height: '200px',
    borderTopLeftRadius: '2px',
    borderTopRightRadius: '2px'
  },
  contentContainer: {
    padding: '16px',
    borderRadius: '2px'
  },
  title: {
    textTransform: 'uppercase',
    color: '#000',
    fontSize: '18px',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
    fontFamily: 'Oswald',
    marginBottom: '12px',
    height: '60px',
    [breakpoints.down('sm')]: {
      fontSize: '21px'
    }
  },
  badge: {
    borderRadius: '2px',
    border: '1px solid #C5C5C5',
    fontSize: '12px',
    lineHeight: '126%',
    padding: '4px 8px',
    display: 'inline-flex',
    marginBottom: '8px',
    [breakpoints.down('sm')]: {
      fontSize: '12px'
    }
  },
  description: {
    fontSize: '14px',
    lineHeight: '17px',
    marginBottom: '24px',
    height: '75px',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden',
    [breakpoints.down('sm')]: {
      fontSize: '16px'
    }
  },
  readMore: {
    color: '#009DE0',
    borderBottom: '1px solid #009DE0',
    display: 'inline-flex',
    position: 'relative',
    '& a': {
      fontSize: '14px',
      lineHeight: '120%',
      [breakpoints.down('sm')]: {
        fontSize: '16px'
      },
      '&:hover': {
        color: '#007AC0'
      }
    }
  }
}))

export default ContentCard
