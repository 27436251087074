import React from 'react'
import { Theme, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import DesktopHeader from './DesktopHeader/DesktopHeader'
import MobileHeader from './MobileHeader/MobileHeader'
import { Model } from './types'

export default function Header({ props }: Model) {
  const theme = useTheme<Theme>()
  const isMobile = useMediaQuery('(max-width:1080px)')

  return isMobile ? (
    <MobileHeader
      hasHero={props.pageHasTopHero}
      contactButtonText={props.contactButtonText}
      contactButtonUrl={props.contactButtonUrl}
      navigationMenuBlocks={props.navigationMenuBlocks}
      megaMenuFooterBlocks={props.megaMenuFooterBlocks}
      languageSelections={props.languageSelections}
      searchSettingsBlock={props.searchSettingsBlock}
      iconUrl={props.iconUrl}
      currentLanguageSelected={props.currentLanguageSelected}
      currentLocale={props.currentLocale}
      headerCartActionButtonText={props.headerCartActionButtonText} 
      checkoutPageUrl={props.checkoutPageUrl} 
      quantityShort={props.quantityShort} 
      cartIsEmpty={props.cartIsEmpty} 
      showBasket={props.showBasket}
      isShopAvailable={props.isShopAvailable}
    />
  ) : (
    <DesktopHeader
      hasHero={props.pageHasTopHero}
      contactButtonText={props.contactButtonText}
      contactButtonUrl={props.contactButtonUrl}
      navigationMenuBlocks={props.navigationMenuBlocks}
      megaMenuFooterBlock={props.megaMenuFooterBlocks}
      languageSelections={props.languageSelections}
      searchSettingsBlock={props.searchSettingsBlock}
      iconUrl={props.iconUrl}
      currentLanguageSelected={props.currentLanguageSelected}
      headerCartActionButtonText={props.headerCartActionButtonText} 
      checkoutPageUrl={props.checkoutPageUrl} 
      quantityShort={props.quantityShort} 
      cartIsEmpty={props.cartIsEmpty} 
      showBasket={props.showBasket}
      isShopAvailable={props.isShopAvailable}
    />
  )
}
