import React from 'react'

const Download = () => (
  <svg className="icon">
    <use
      xlinkHref={`/Static/Images/icons.svg?=${process.env.BUILD_VERSION_ID}#icon--download`}
    />
  </svg>
)

export default Download
