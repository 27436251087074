import { connect } from 'react-redux'
import InvoiceSummaryDetails from './InvoiceSummaryDetails'
import {
  getPageModel,
  getTranslation
} from '../../../../modules/translations/translations.utils'
import {
  getAllPageModels,
  getOrderListingCommonTranslations,
  getCommonTranslations
} from '../../../../modules/translations/translations.selectors'
import { paymentStatus } from '../../../../constants/status'
import orderTypes from '../../../../constants/orderTypes'

const mapStateToProps = (state, { moduleId }) => {
  const { customerOrders } = state
  const pageModel = getPageModel(getAllPageModels(state), moduleId)
  const orderTranslations = getOrderListingCommonTranslations(state)
  const commonTranslation = getCommonTranslations(state)
  const payment = customerOrders.currentOrderDetails.payment || {}

  const checkOverdue = payment => {
    return payment.dueDate && new Date(payment.dueDate) < new Date()
  }

  const getPaymentAlias = status => {
    if (status === paymentStatus.PAID) return 'paid'
    else if (status === paymentStatus.NOTPAID) return 'notPaid'
    else if (status === paymentStatus.PARTAILYPAID) return 'partallyPaid'
  }

  return {
    ...customerOrders.currentOrderDetails.invoiceSummary,
    requestNewInvoiceTitle: pageModel.content.requestNewInvoiceTitle,
    requestNewInvoiceText: pageModel.content.requestNewInvoiceText,
    requestNewInvoiceSuccessMessage: getTranslation(
      pageModel.content,
      'requestNewInvoiceSuccessMessage'
    ),
    newInvoiceRequestData: {
      invoiceNumber:
        customerOrders.currentOrderDetails.invoiceSummary.invoiceNumber,
      orderNumber: customerOrders.currentOrderDetails.orderNumber,
      orderType: customerOrders.currentOrderDetails.orderType,
      moduleId: moduleId,
      locale: state.translations.currentLocale
    },
    invoiceDateLabel: getTranslation(orderTranslations, 'invoiceDate'),
    invoiceNumberLabel: getTranslation(orderTranslations, 'invoiceNumber'),
    totalInvoiceAmountLabel: getTranslation(orderTranslations, 'totalAmount'),
    poNumberLabel: getTranslation(orderTranslations, 'poNumber'),
    poNumber:
      customerOrders.currentOrderDetails.invoiceSummary.customersOrderReference,
    isPaid: payment && payment.status === paymentStatus.PAID,
    dueDateLabel: checkOverdue(payment)
      ? getTranslation(orderTranslations, 'overdue')
      : getTranslation(orderTranslations, 'due'),
    dueDate: payment.dueDate,
    isOverdue: checkOverdue(payment),
    statusLabel: getTranslation(commonTranslation, 'status'),
    status: getTranslation(orderTranslations, getPaymentAlias(payment.status)),
    amountDueLabel: getTranslation(orderTranslations, 'amountDue'),
    amountDue: payment.amountDue || '-',
    settledAmountLabel: getTranslation(orderTranslations, 'amountPaid'),
    settledAmount: payment.settledAmount || '-',
    isWebOrder: customerOrders.currentOrderDetails.orderType === orderTypes.Web
  }
}

export default connect(mapStateToProps)(InvoiceSummaryDetails)
