import React from 'react'
import PropTypes from 'prop-types'
import { classNames, addChildClassName } from '../../../utils/classNames'

RadioButton.propTypes = {
  element: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,

  className: PropTypes.string,
  children: PropTypes.node
}

RadioButton.defaultProps = {
  element: 'input'
}

export default function RadioButton({
  element: Element,

  className,
  children,

  ...inputElementProps
}) {
  return (
    <label className={classNames('radio-button', className)}>
      <Element
        className={classNames(
          'radio-button__input',
          addChildClassName(className, 'input')
        )}
        type="radio"
        name={name}
        {...inputElementProps}
      />

      <span
        className={classNames(
          'radio-button__icon',
          addChildClassName(className, 'icon')
        )}
      />

      <span
        className={classNames(
          'radio-button__content',
          addChildClassName(className, 'content')
        )}
      >
        {children}
      </span>
    </label>
  )
}
