import React, { useState } from 'react'
import { EpiFormInputElement } from 'Core/types/epi/form'
import { InputPropsMap } from 'Core/hooks/useForm/useForm.types'
import { FileUploadInputValue } from 'Core/components/_inputs/FileUploadInput/FileUploadInput'
import EpiFormInput from 'Core/components/EpiFormInput'
import { getUpdatedFiles } from 'Core/components/_inputs/FileUploadInput/FileUploadInput.utils'
import { classNames } from 'Core/utils/classNames'
import './file-upload.scss'

interface FileUploadProps {
  formElement: EpiFormInputElement
  inputProps: InputPropsMap
}

export default function FileUpload({
  formElement,
  inputProps
}: FileUploadProps) {
  const [isDragTarget, setIsDragTarget] = useState(false)

  const { name } = formElement
  const { label, description, ...strippedFormElement } = formElement

  const elementInputProps = inputProps[name]

  const { onChange } = elementInputProps
  const selectedFiles = elementInputProps.value as FileUploadInputValue

  const hasSelectedFiles = selectedFiles && selectedFiles.length > 0

  function onFileRemove(removeFile: File) {
    const updatedFiles = selectedFiles.filter(file => file !== removeFile)

    onChange(updatedFiles)
  }

  function onFileDragOver(event: React.DragEvent<HTMLElement>) {
    event.preventDefault()
    setIsDragTarget(true)
  }

  function onFileDragLeave() {
    setIsDragTarget(false)
  }

  function onFileDrop(event: React.DragEvent<HTMLElement>) {
    event.preventDefault()

    setIsDragTarget(false)

    const dropFiles = Array.from(event.dataTransfer.items)
      .filter(file => file.kind === 'file')
      .map(file => file.getAsFile() as File)

    const updatedFiles = getUpdatedFiles(selectedFiles, dropFiles)

    onChange(updatedFiles)
  }

  return (
    <section
      className="file-upload"
      onDragOver={onFileDragOver}
      onDragLeave={onFileDragLeave}
      onDrop={onFileDrop}
    >
      <div className="file-upload__content">
        <label
          className={classNames(
            'file-upload__label',
            isDragTarget && 'file-upload__label--is-drag-target'
          )}
        >
          <EpiFormInput
            className="file-upload__epi-form-input"
            formElement={strippedFormElement}
            inputProps={inputProps}
          />

          {label && (
            <div
              className="file-upload__label-texts"
              dangerouslySetInnerHTML={{ __html: label }}
            />
          )}
        </label>

        {!hasSelectedFiles && <div>No files attached</div>}

        {hasSelectedFiles && (
          <ul className="file-upload__selected-list">
            {selectedFiles.map(file => (
              <li key={file.name} className="file-upload__selected-item">
                <span className="file-upload__selected-item-name">
                  {file.name}
                </span>

                <button
                  className="file-upload__selected-item-remove"
                  onClick={() => onFileRemove(file)}
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </section>
  )
}
