import React from 'react'

const SmallClose = () => {
  return (
    <svg width="10" height="11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="m5 6.3 3.6 3.6.707-.707-3.6-3.6L9.4 1.9l-.707-.707L5 4.886 1.307 1.193.6 1.9l3.693 3.693-3.6 3.6.707.707L5 6.3Z"
        fill="#000"
      />
    </svg>
  )
}

export default SmallClose
