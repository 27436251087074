import React, { useState, useRef, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import { Button, Collapse, Typography, useMediaQuery } from '@material-ui/core'
import { NewsletterProps } from './types'
import './newsletter.scss'

type Props = {
  props: NewsletterProps
}

type ColorType = {
  [key: number]: string
}

const Newsletter = ({
  props: {
    backgroundColor,
    backgroundImageUrl,
    title,
    text,
    isCollapsed,
    buttonText,
    formToHtml
  }
}: Props) => {
  const [isFormVisible, setIsFormVisible] = useState(false)
  const isMobile = useMediaQuery('(max-width: 600px)')
  const formRef = useRef(null)

  const handleButtonClick = () => {
    setIsFormVisible(true)
  }

  const color: ColorType = {
    1: '#007AC0',
    2: '#074276',
    3: '#fafafa'
  }

  useEffect(() => {
    const button = document.querySelector(
      '.click-dimension-form__form-submit'
    ) as HTMLElement
    if (button && backgroundColor === 1) {
      button.classList.add('darkButton')
    } else {
      button?.classList.add('lightButton')
    }
  }, [backgroundColor])

  const textStyle = {
    color:
      backgroundColor === 1 || backgroundColor === 2 ? '#ffffff' : '#000000'
  }

  return (
    <Box
      style={{
        backgroundColor: backgroundColor ? color[backgroundColor] : '#fafafa',
        backgroundImage: backgroundImageUrl
          ? isMobile
            ? 'none'
            : `url(${backgroundImageUrl})`
          : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100%'
      }}
      display="flex"
      flexDirection="column"
      className="newsletter"
      justifyContent="center"
      alignItems="center"
    >
      <Box className="newsletter__content">
        <Typography
          style={{
            ...textStyle,
            fontSize: isMobile ? '24px' : '48px'
          }}
          className="newsletter__title"
          variant="h3"
        >
          {title}
        </Typography>
        <Typography
          style={textStyle}
          className="newsletter__text"
          variant="body1"
        >
          {text}
        </Typography>
        {isCollapsed && !isFormVisible && (
          <Button
            className={`newsletter__button ${
              backgroundColor === 1 ? 'expandButtonDark' : 'expandButtonLight'
            }`}
            onClick={handleButtonClick}
          >
            {buttonText}
          </Button>
        )}
        {isCollapsed ? (
          <Collapse in={isFormVisible}>
            <div
              className={`newsletterForm ${
                isFormVisible ? 'form--visible' : ''
              }`}
            >
              <div dangerouslySetInnerHTML={{ __html: formToHtml }}></div>
            </div>
          </Collapse>
        ) : (
          <div
            className={`newsletterForm ${isFormVisible ? 'form--visible' : ''}`}
          >
            <div
              ref={formRef}
              dangerouslySetInnerHTML={{ __html: formToHtml }}
            ></div>
          </div>
        )}
      </Box>
    </Box>
  )
}

export default Newsletter
