export function getUpdatedFiles(selectedFiles: File[], filesToAdd: File[]) {
  const newFiles = filesToAdd.filter(newFile =>
    selectedFiles.every(selectedFile => newFile.name !== selectedFile.name)
  )

  if (newFiles.length === 0) {
    return selectedFiles
  }

  const updatedFiles = [...selectedFiles, ...newFiles].sort((fileA, fileB) =>
    fileA.name.toLowerCase() > fileB.name.toLowerCase() ? 1 : -1
  )

  return updatedFiles
}
