import $ from 'jquery'

export default class Bootstrapper {
  componentDefinitions = {}
  components = []

  getComponentsToRender() {
    let components = []
    const $pageComponents = $('[data-react-component]')

    $pageComponents.each((index, componentElement) => {
      const $componentElement = $(componentElement)

      const componentName = $componentElement.data('reactComponent')
      const componentConstructor = this.componentDefinitions[componentName]

      if (componentConstructor) {
        components.push({
          containerElement: $componentElement[0],
          componentConstructor: componentConstructor,
          dataAttr: $componentElement.data()
        })
      }
    })

    return components
  }

  addComponentDefinitions(componentDefinitions) {
    Object.keys(componentDefinitions).forEach(componentName => {
      const componentConstructor = componentDefinitions[componentName]
      this.componentDefinitions[componentName] = componentConstructor
    })
  }
}
