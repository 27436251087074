import React from 'react'
import PropTypes from 'prop-types'
import './invoice-summary.scss'
// import IconButton from '../../../components/IconButton'
import InvoiceSummaryDetails from './InvoiceSummaryDetails'
// import MaterialArrowDownSvg from '../../../components/_svg/MaterialArrowDown'
// import MaterialArrowUpSvg from '../../../components/_svg/MaterialArrowUp'

const Invoicesummary = ({
  invoiceDateLabel,
  invoiceDate,
  isInvoiceOverdue,
  invoiceTranslation,
  moduleId,
  hasInvoice,
  noInvoiceText
}) => {
  // const [toggled, setToggle] = useState(false)
  const toggled = true

  if (!hasInvoice) {
    return (
      <div className="invoice-summary invoice-summary--no-invoice">
        {noInvoiceText}
      </div>
    )
  }

  return (
    <div className="invoice-summary">
      <div className="invoice-summary__header">
        {/* <div
          className={`invoice-summary__header-title ${isInvoiceOverdue
            ? 'invoice-summary__header-title--overdue'
            : ''}`}
        >
          <span className="invoice-summary__header-title-text">
            {`${invoiceDateLabel}: `}
          </span>
          <span className="invoice-summary__header-title-date">
            {invoiceDate.toLocaleDateString()}
          </span>
        </div>
        <div>
          <IconButton
            SvgIcon={MaterialArrowDownSvg}
            ToggledSvgIcon={MaterialArrowUpSvg}
            onClick={() => setToggle(!toggled)}
          >
            {invoiceTranslation}
          </IconButton>
        </div> */}
      </div>
      <div
        className={`invoice-summary__expand-container ${
          toggled ? 'invoice-summary__expand-container--active' : ''
        }`}
      >
        <InvoiceSummaryDetails moduleId={moduleId} />
      </div>
    </div>
  )
}

Invoicesummary.propTypes = {
  moduleId: PropTypes.number.isRequired,
  invoiceDateLabel: PropTypes.string.isRequired,
  invoiceDate: PropTypes.instanceOf(Date).isRequired,
  isInvoiceOverdue: PropTypes.bool.isRequired,
  invoiceTranslation: PropTypes.string.isRequired,
  hasInvoice: PropTypes.bool.isRequired,
  noInvoiceText: PropTypes.string
}

export default Invoicesummary
