/** @typedef { import('./useForm.types').ElementValue } ElementValue */
/** @typedef { import('./useForm.types').ElementStatesMap } ElementStatesMap */
/** @typedef { import('./useForm.types').ElementDefinitionsMap } ElementDefinitionsMap */
/** @typedef { import('./useForm.types').GetValuesFunc } GetValuesFunc */

/** @param {ElementStatesMap} elementStates */
export function isFormValid(elementStates) {
  for (let name in elementStates) {
    if (elementStates[name].isValid === false) {
      return false
    }
  }

  return true
}
