import React from 'react'
import { classNames, addChildClassName } from '../../utils/classNames'
import NordLockInput from '../NordLockInput'

interface LabeledNordLockInputProps {
  label: string
  description?: string
  inputComponent: React.ComponentType<any>

  className?: string
}

function LabeledNordLockInput({
  label,
  description,
  className,
  ...inputProps
}: LabeledNordLockInputProps) {
  return (
    <label className={classNames('nord-lock-input-wrapper', className)}>
      <span
        className={classNames(
          'nord-lock-input-label',
          addChildClassName(className, 'label')
        )}
      >
        {label}
      </span>

      {description && (
        <span
          className={classNames(
            'nord-lock-input-description',
            addChildClassName(className, 'description')
          )}
        >
          {description}
        </span>
      )}

      <NordLockInput
        className={addChildClassName(className, 'input')}
        {...inputProps}
      />
    </label>
  )
}

export default React.memo(LabeledNordLockInput)
