import React from 'react'

const LinkedInIcon = ({ ...props }) => (
  <svg width="24" height="24" fill="currentColor" {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.778 22H4.222A2.222 2.222 0 0 1 2 19.778V4.222C2 2.994 2.994 2 4.222 2h15.556C21.006 2 22 2.994 22 4.222v15.556A2.222 2.222 0 0 1 19.778 22Zm-3.522-2.778H19.225v-6.097c0-2.58-1.464-3.828-3.506-3.828-2.041 0-2.902 1.592-2.902 1.592V9.592H9.956v9.63h2.86v-5.055c0-1.356.623-2.161 1.817-2.161 1.095 0 1.623.775 1.623 2.16v5.056ZM4.778 6.552a1.77 1.77 0 0 0 1.764 1.779 1.77 1.77 0 0 0 1.764-1.778 1.77 1.77 0 0 0-1.764-1.778 1.77 1.77 0 0 0-1.764 1.778Zm3.27 12.67H5.063v-9.63h2.983v9.63Z"
    />
  </svg>
)

export default LinkedInIcon
