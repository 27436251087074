import { get } from '../../api/index'

export function searchRequest(
  searchParameters: { [key: string]: string },
  targetApi: string
) {
  const params = new URLSearchParams()
  Object.keys(searchParameters).forEach(key => {
    if (searchParameters[key]) {
      params.set(key, searchParameters[key])
    }
  })

  const queryPath = `${targetApi}?${decodeURIComponent(params.toString())}`
  return get(queryPath)
}
