import React from 'react'

import { Order } from '../../modules/orders/orders.types'

import OrderLinkListItem from './OrderLinkListItem'
import Button from '../Button/Button'
import Loader from '../Loader'
import StickySalesContact from './StickySalesContact'
import TextInput from 'Core/components/_inputs/TextInput'

import { useLinkList } from './LinkList.hooks'

import { classNames } from 'Core/utils/classNames'

import './link-list.scss'

export interface LinkListProps {
  linkListData: Order[]
  loadMoreLabel: string
  moduleId: number
  noOrdersLabel: string
  searchOrder: string
  title: string

  className: string
}

export default function LinkList({
  linkListData,
  loadMoreLabel,
  moduleId,
  noOrdersLabel,
  searchOrder,
  title,

  className
}: LinkListProps) {
  const {
    orders,
    searchValue,

    isLoading,
    canLoadMore,

    loadMore,
    onSearchChange
  } = useLinkList(linkListData)

  const hasOrders = orders.length > 0

  return (
    <section className={classNames('link-list', className)}>
      {title && <h2 className="link-list__title">{title}</h2>}

      <div className="link-list__container">
        <div className="link-list__list">
          <TextInput
            className="link-list__search"
            onChange={(value: string) => onSearchChange(value)}
            placeholder={searchOrder}
            value={searchValue}
          />

          {hasOrders && (
            <ul>
              {orders.map(item => {
                return <OrderLinkListItem key={item.title} item={item} />
              })}

              {canLoadMore && (
                <Button
                  className={'link-list__load-more-button'}
                  onClick={loadMore}
                >
                  {loadMoreLabel}
                </Button>
              )}

              {isLoading && (
                <div className="link-list__loader">
                  <Loader />
                </div>
              )}
            </ul>
          )}

          {!hasOrders && <p>{noOrdersLabel}</p>}
        </div>

        <StickySalesContact moduleId={moduleId} />
      </div>
    </section>
  )
}
