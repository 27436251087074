import React, { useState } from 'react'
import { HeaderNavigationLinks, MegaMenuFooterBlock } from '../types'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ArrowRight from '../../../../../Spa.Core/components/_svg/ArrowRight'
import './mobileHeader.scss'
import MenuLinkList from './MenuLinkList'
import { Drawer, Link } from '@material-ui/core'

type MenuListItemProps = {
  onClick: (item: HeaderNavigationLinks) => void
  navigationMenuBlocks: HeaderNavigationLinks
  megaMenuFooterBlocks: MegaMenuFooterBlock[]
  setMainMenuOpen: (arg: boolean) => void
  isSubMenuOpen: boolean
  setSubMenuOpen: (arg: boolean) => void
  history: string
}

const MenuListItem = ({
  history,
  isSubMenuOpen,
  navigationMenuBlocks,
  megaMenuFooterBlocks,
  onClick,
  setMainMenuOpen
}: MenuListItemProps) => {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    onClick(navigationMenuBlocks)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const noSubItems = !!navigationMenuBlocks.url?.length

  return (
    <>
      <ListItem
        button
        onClick={!noSubItems ? handleClick : () => null}
        className="listItem"
      >
        {noSubItems && (
          <Link
            href={navigationMenuBlocks.url}
            className="listItem__listItemText"
          >
            {navigationMenuBlocks.title}
          </Link>
        )}
        {navigationMenuBlocks.url === null && (
          <>
            <ListItemText
              className="listItem__listItemText"
              primary={navigationMenuBlocks.title}
            />
            <ListItemIcon className="menuContent__arrowIcon">
              <ArrowRight />
            </ListItemIcon>
          </>
        )}
      </ListItem>
      <Drawer open={open} onClose={handleClose} className="linkDrawer">
        <MenuLinkList
          megaMenuFooterBlocks={megaMenuFooterBlocks}
          navigationMenuBlocks={navigationMenuBlocks}
          megaMenuBlocks={navigationMenuBlocks.megaMenuBlocks}
          onClick={onClick}
          setMainMenuOpen={setMainMenuOpen}
          isSubMenuOpen={isSubMenuOpen}
          setSubMenuOpen={setOpen}
          history={history}
        />
      </Drawer>
    </>
  )
}

export default MenuListItem
