import React from 'react'

const YoutubeIcon = ({ ...props }) => (
  <svg width="24" height="24" fill="currentColor" {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.918 3c-3.676 0-7.128.275-8.966.66C1.727 3.935.668 4.76.446 6.025.224 7.345 0 9.325 0 11.8c0 2.475.222 4.4.5 5.775.224 1.21 1.282 2.09 2.506 2.365 1.951.385 5.29.66 8.967.66 3.676 0 7.015-.275 8.964-.66 1.227-.275 2.284-1.1 2.506-2.365.224-1.375.503-3.354.557-5.829 0-2.475-.278-4.456-.557-5.83-.222-1.21-1.279-2.091-2.506-2.366-1.949-.275-5.345-.55-9.019-.55Zm3.43 9.1-4.8 2.792a.8.8 0 0 1-1.2-.698V8.608a.8.8 0 0 1 1.2-.698l4.8 2.793a.81.81 0 0 1 0 1.396Z"
    />
  </svg>
)

export default YoutubeIcon
