import React, { useState } from 'react'
import Slider from 'react-slick'
import { Theme, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ImageBannerItem from './ImageBannerItem'
import { ImageBannerCarouselProps } from './types'
import './imageBanner.scss'
import { Box } from '@material-ui/core'
import { BrandCardProps } from '../BrandCard/BrandCard'
import BrandCard from '../BrandCard/BrandCard'
import { SectionBlock } from '../ContentArea/ContentArea'

export type BrandProps = {
  type: string | null
  properties: BrandCardProps[]
  displayOptionTag: string | null
}

type Props = {
  props?: ImageBannerCarouselProps
  brandCard?: SectionBlock[]
  displayAsCarouselMobile?: boolean
}

function NextArrow(props: any) {
  const { className, style, onClick } = props
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M6 12L17 12"
          stroke="inherit"
          stroke-linecap="square"
          stroke-linejoin="round"
        />
        <path
          d="M13 17L18 12L13 7"
          stroke="inherit"
          stroke-linecap="square"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  )
}

function PrevArrow(props: any) {
  const { className, style, onClick } = props
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M18 12L7 12"
          stroke="inherit"
          stroke-linecap="square"
          stroke-linejoin="round"
        />
        <path
          d="M11 7L6 12L11 17"
          stroke="inherit"
          stroke-linecap="square"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  )
}

export default function ImageBannerCarousel({
  props,
  brandCard,
  displayAsCarouselMobile
}: Props) {
  const theme = useTheme<Theme>()

  const isSmall = useMediaQuery('(max-width: 1140px)')
  const isMobile = useMediaQuery('(max-width: 768px)')
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  const isSmallScreen = useMediaQuery(
    '(min-width: 340px) and (max-width: 374px)'
  )

  const isOneSlide = props?.contentArea.length === 1

  const settings = {
    dots: true,
    autoplay: displayAsCarouselMobile ? false : true,
    autoplaySpeed: 4000,
    infinite: displayAsCarouselMobile ? false : true,
    centerPadding: '0px',
    arrows: isSmall ? false : true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 1,
    variableWidth: isSmall ? true : false,
    centerMode: isSmallScreen && isMobile ? true : false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  }

  return (
    <>
      <Box
        gridColumn="1/-1"
        width="100%"
        className="image-banner-carousel__container"
      >
        <Box
          width={isMobile ? '100%' : isTablet ? 'calc(100% - 32px)' : '1350px'}
          margin="auto"
        >
          {brandCard && (
            <Box
              style={{
                paddingTop: displayAsCarouselMobile ? '0' : '98px',
                paddingLeft: isSmall && !displayAsCarouselMobile ? '16px' : '',
                paddingRight: isSmall ? '0px' : '',
                paddingBottom: displayAsCarouselMobile ? '0' : '98px',
                maxWidth: '100vw',
                width: '100%',
                overflow: 'hidden'
              }}
            >
              <Slider {...settings}>
                {brandCard.map(item => {
                  return (
                    <Box width={isSmallScreen ? '' : '343px !important'}>
                      <BrandCard props={item.properties} />
                    </Box>
                  )
                })}
              </Slider>
            </Box>
          )}
          {props?.contentArea && props.contentArea.length > 1 ? (
            <Box
              style={{
                paddingTop: '98px',
                paddingLeft: isSmall ? '16px' : '',
                paddingRight: isSmall ? '0px' : '',
                paddingBottom: '98px'
              }}
            >
              <Slider {...settings}>
                {props.contentArea.map(item => (
                  <ImageBannerItem
                    buttonLink={item.properties.buttonLink}
                    buttonTitle={item.properties.buttonTitle}
                    description={item.properties.description}
                    heading={item.properties.heading}
                    imageUrl={item.properties.imageUrl}
                    subheading={item.properties.subheading}
                    imageAlignment={props.imageAlignment}
                  />
                ))}
              </Slider>
            </Box>
          ) : (
            props?.contentArea &&
            props?.contentArea.length === 1 && (
              <Box
                style={{
                  paddingTop: '98px',
                  paddingLeft: isSmall ? '16px' : '',
                  paddingRight: isSmall ? '16px' : '',
                  paddingBottom: '98px'
                }}
              >
                <ImageBannerItem
                  buttonLink={props.contentArea[0].properties.buttonLink}
                  buttonTitle={props.contentArea[0].properties.buttonTitle}
                  description={props.contentArea[0].properties.description}
                  heading={props.contentArea[0].properties.heading}
                  imageUrl={props.contentArea[0].properties.imageUrl}
                  subheading={props.contentArea[0].properties.subheading}
                  imageAlignment={props.imageAlignment}
                  isOneSlide={isOneSlide}
                />
              </Box>
            )
          )}
        </Box>
      </Box>
    </>
  )
}
