import React, { useRef, useContext, useState } from 'react'
import { ElementValuesMap } from 'Core/hooks/useForm/useForm.types'
import ReactFormPageModel from './types/ReactFormPageModel'
import PrimaryButton from 'Core/components/_buttons/PrimaryButton'
import ReactFormInput from './components/ReactFormInput'
import Loader from '../../components/Loader'
import PageModelContext from '../../contexts/PageModelContext'
import { useReactForm, useSubmitReactForm } from './ReactForm.hooks'
import { classNames } from 'Core/utils/classNames'
import { triggerElementEvent } from 'Core/utils/domEvents'
import CustomDomEventNames from 'Core/constants/CustomDomEventNames'
import GlobalCoreClasses from 'Core/constants/GlobalCoreClasses'
import './react-form.scss'

export default function ReactForm() {
  const formRef = useRef<HTMLFormElement>(null)
  const [hasSubmitedForm, setHasSubmitedForm] = useState(false)
  const [isPosting, setIsPosting] = useState(false)

  const pageModel = useContext(PageModelContext) as ReactFormPageModel
  const {
    inputElements,
    targetApi,
    successMessage,
    submitButtonText,
    defaultValues
  } = pageModel

  const { inputProps, isValid, getValues } = useReactForm(
    inputElements,
    defaultValues
  )

  const submitForm = useSubmitReactForm()

  async function onSubmit(event: React.FormEvent<HTMLElement>) {
    event.preventDefault()
    setIsPosting(true)

    if (!isValid) return

    await submitForm(getValues(), targetApi)

    setHasSubmitedForm(true)
    setIsPosting(false)

    if (formRef.current) {
      triggerElementEvent(formRef.current, CustomDomEventNames.OnFormSuccess)
    }
  }

  if (isPosting) {
    return <Loader />
  }

  if (hasSubmitedForm) {
    return <h2>{successMessage}</h2>
  }

  return (
    <form className="react-form" onSubmit={onSubmit} ref={formRef}>
      {inputElements.map(elementDefinition => (
        <ReactFormInput
          key={elementDefinition.name}
          formElement={elementDefinition}
          inputProps={inputProps}
        />
      ))}

      <PrimaryButton
        className={classNames(
          'react-form__submit',
          GlobalCoreClasses.ElementFullWidth
        )}
        type="submit"
        disabled={!isValid}
      >
        {submitButtonText}
      </PrimaryButton>
    </form>
  )
}
