import React from 'react'

const ZhihuIcon = ({ ...props }) => (
  <svg width="24" height="24" fill="currentColor" {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.778 22H4.222A2.222 2.222 0 0 1 2 19.778V4.222C2 2.994 2.994 2 4.222 2h15.556C21.006 2 22 2.994 22 4.222v15.556A2.222 2.222 0 0 1 19.778 22Zm-10.93-7.921h-.585V9.003h3.067v5.076h-1.238l-1.05.615-.193-.615Zm1.859-4.525h-1.82v3.974h.297l.127.408.697-.408h.699V9.554Zm-4.27 1.771h1.308c.188 0 .19.551.19.551h-1.54a3.877 3.877 0 0 1-.198.95l.366-.272c.212.198.7.735.902.957l.018.019c.228.305.03.933.03.933l-1.348-1.515c-.315 1.128-.89 1.615-.89 1.615-.252.21-.763.368-1.275.231 1.07-.775 1.661-1.755 1.771-2.918h-1.64s.096-.551.39-.551h1.306c.012-.154.042-1.47.042-1.714h-.721a6.502 6.502 0 0 1-.09.238l-.039.1c-.269.462-.715.5-.89.516a2.633 2.633 0 0 0-.03.003c.343-.642.568-1.273.69-1.614.033-.093.059-.164.077-.209.205-.503.807-.503.807-.503a9.64 9.64 0 0 0-.328.949h2.212c.264-.006.214.52.214.52H6.492c0 .51-.011 1.082-.055 1.714ZM19.17 9.921s-.604.769-1.124 1.367l-.481-.337s.77-.934.92-1.124c.219-.25.685.094.685.094Zm-5.524.038s.422-.242.647-.05c.226.193.867 1.07.867 1.07l-.486.32c-.04-.056-1.028-1.34-1.028-1.34Zm6.352 1.616H20Zm-3.225.021c.376-.003 2.768-.021 3.225-.021 0 .01.022.554-.217.554h-3.06v1.711c.02.654-.382 1.057-1.121 1.057-.246 0-.404-.04-.65-.153-.301-.16-.416-.381-.471-.487l-.012-.022c.138.005.265.012.387.02.222.012.427.023.655.022.332.02.612-.167.612-.493v-1.655h-2.699c-.567-.012-.637-.533-.637-.533h3.337V9.267c-.321 0-.793.02-1.413.057-.163.005-.3.01-.419.016-.364.016-.56.025-.81-.037a.59.59 0 0 1-.395-.456c1.679-.036 5.81-.42 5.81-.42s.502-.135.579-.108c.32.146.014.78.014.78-.44.02-1.172.057-2.194.113a8.85 8.85 0 0 1-.571.028v2.357h.05Z"
    />
  </svg>
)

export default ZhihuIcon
