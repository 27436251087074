import React from 'react'
import PropTypes from 'prop-types'

import './product-list.scss'

const ProductList = ({
  title,
  products,
  className,
  ProductItemComponent,
  onChange
}) => (
  <section className={`product-list ${className || ''}`}>
    {title ? (
      <h3 className={`product-list-title ${className + '-title' || ''}`}>
        {title}
      </h3>
    ) : (
      ''
    )}
    <ul className={`product-list__list ${className + '__list' || ''}`}>
      {products.map((product, index) => (
        <ProductItemComponent key={index} onChange={onChange} {...product} />
      ))}
    </ul>
  </section>
)

ProductList.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  ProductItemComponent: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  onChange: PropTypes.func
}

export default ProductList
