import React, { useState } from 'react'
import Slider from 'react-slick'
import Box from '@material-ui/core/Box'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import classNames from 'classnames'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { CarouselBlockProps } from './types'
import ContentCard from '../../components/ContentCard/ContentCard'

type CarouselProps = {
  props: CarouselBlockProps
}

type ArrowProps = {
  direction: 'left' | 'right'
}

function Arrow({ direction }: ArrowProps) {
  return (
    <svg
      style={{ transform: direction === 'right' ? 'scaleX(-1)' : 'scaleX(1)' }}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path d="M13 6L7.90882 12L13 18" stroke="black" />
    </svg>
  )
}

export default function CarouselBlockComponent({ props }: CarouselProps) {
  const [sliderRef, setSliderRef] = useState<any>(null) //Cursed legacy typing
  const [sliderIndex, setSliderIndex] = useState<number>(0)

  const isMobile = useMediaQuery('(max-width: 768px)')
  const isTablet = useMediaQuery('(min-width: 768px) and (max-width: 1200px)')
  const classes = useStyles()

  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    variableWidth: true,
    beforeChange: (_: number, newIndex: number) => {
      if (newIndex < props.contentArea.length + 1) {
        setSliderIndex(newIndex)
      }
    }
  }

  if (props.contentArea === null || undefined) return null

  const disablePrevBtn = sliderIndex === 0
  const disableNextBtn = sliderIndex === props.contentArea.length - 3

  return (
    <Box
      style={{
        backgroundColor: props.backgroundColor === 0 ? '#ffffff' : '#fafafa'
      }}
      display={isMobile ? 'block' : 'flex'}
      justifyContent={isMobile ? 'left' : 'center'}
      width="100%"
      py="48px"
      overflow="hidden"
    >
      <Box
        className={classes.root}
        width={isMobile ? '100%' : isTablet ? 'calc(100% - 32px)' : '1350px'}
        ml={isMobile || isTablet ? '16px' : ''}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          pb="16px"
          alignItems="flex-end"
        >
          <Box>
            {props.title && <h3 className={classes.title}>{props.title}</h3>}
            {props.description && (
              <p className={classes.description}>{props.description}</p>
            )}
          </Box>
          {!isMobile && !isTablet && (
            <Box>
              <button
                className={classNames({
                  [classes.disabledBtn]: disablePrevBtn
                })}
                disabled={disablePrevBtn}
                onClick={sliderRef?.slickPrev}
              >
                <Arrow direction="left" />
              </button>
              <button
                className={classNames({
                  [classes.disabledBtn]: disableNextBtn
                })}
                disabled={disableNextBtn}
                onClick={sliderRef?.slickNext}
              >
                <Arrow direction="right" />
              </button>
            </Box>
          )}
        </Box>
        <Slider ref={setSliderRef} {...settings}>
          {props.contentArea &&
            props.contentArea.map((item, index) => (
              <ContentCard
                item={item.properties}
                index={index}
                cardContainerClass={classes.cardContainer}
                childrenBackgroundColor={props.childrenBackgroundColor}
              />
            ))}
          <div>
            <Box
              style={{
                backgroundColor:
                  props.childrenBackgroundColor === 0 ? '#ffffff' : '#FAFAFA'
              }}
              className={classes.seeAllCard}
            >
              <a href={props.seeAllUrl}>{props.seeAllText}</a>
            </Box>
          </div>
        </Slider>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    '& .slick-slide': {
      height: '100%',
      [breakpoints.down('sm')]: {
        width: 'auto !important'
      }
    },
    '& .slick-list': {
      overflow: 'hidden',
      margin: '0 -1px',
      [breakpoints.down('md')]: {
        marginRight: '-16px'
      }
    }
  },
  cardContainer: {
    marginRight: '16px',
    maxWidth: '326px',
    minWidth: '326px',
    borderRadius: '2px'
  },
  disabledBtn: {
    opacity: 0.5
  },
  title: {
    color: '#009de0',
    textTransform: 'uppercase',
    lineHeight: '34px',
    fontWeight: 400,
    fontSize: '24px',
    padding: ' 8px 0px 8px 0px',
    marginBottom: '0px',
    [breakpoints.down('sm')]: {
      fontSize: '21px'
    }
  },
  seeAllCard: {
    cursor: 'pointer',
    marginRight: '16px',
    maxWidth: '326px',
    minWidth: '326px',
    borderRadius: '2px',
    height: '460px',
    display: 'flex !important',
    justifyContent: 'center',
    alignItems: 'center',
    '& > a': {
      textTransform: 'uppercase',
      fontFamily: 'Oswald',
      fontSize: '18px',
      fontWeight: 400
    },
    ' &:hover': {
      backgroundColor: '#009de0 !important',
      '& > a': {
        color: '#ffffff'
      }
    }
  },
  description: {
    maxWidth: '600px',
    margin: '0px',
    padding: '8px 0px',
    [breakpoints.down('sm')]: {
      width: '100%'
    }
  }
}))
