import React from 'react'
import PropTypes from 'prop-types'
import CompanyIcon from '../../../CompanyIcon'

import './default-product-info.scss'

const DefaultProductInfo = ({
  imageUrl,
  name,
  itemNumber,
  quantity,
  unit,
  itemsTranslation,
  company
}) => (
  <div className="default-product-info">
    <div className="default-product-info__image">
      {imageUrl ? (
        <img src={imageUrl} />
      ) : (
        <span className="default-product-info__icon">
          <CompanyIcon company={company} />
        </span>
      )}
    </div>
    <div className="default-product-info__container">
      <span className="default-product-info__title">{itemNumber}</span>
      <span className="default-product-info__name">{name}</span>
      <span className="default-product-info__quantity">
        {quantity} {unit || itemsTranslation}
      </span>
    </div>
  </div>
)

DefaultProductInfo.propTypes = {
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  itemNumber: PropTypes.string,
  quantity: PropTypes.number,
  unit: PropTypes.string,
  itemsTranslation: PropTypes.string,
  company: PropTypes.string.isRequired
}

export default DefaultProductInfo
