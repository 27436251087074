import React from 'react'
import Box from '@material-ui/core/Box'
import { FooterSectionBlockProps } from './types'
import { Typography, Link } from '@material-ui/core'

const FooterListDesktop = ({ ...props }) => {
  const data = props.props as FooterSectionBlockProps

  return (
    <Box className="footer-list-deskop">
      <Typography className="footer-list-deskop__title">
        {data.title}
      </Typography>
      <Box className="footer-list-deskop__list">
        {data.links.map((link, i) => (
          <Link key={i} href={link.href}>
            {link.text}
          </Link>
        ))}
      </Box>
    </Box>
  )
}

export default FooterListDesktop
