import PropTypes from 'prop-types'

export const inputPropsPropType = {
  name: PropTypes.string,
  value: PropTypes.any,
  required: PropTypes.bool,

  isValid: PropTypes.bool,
  isTouched: PropTypes.bool,

  forwardRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),

  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func
}
