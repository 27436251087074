import React from 'react'
import PropTypes from 'prop-types'
import BaseButton from '../BaseButton'
import { classNames } from '../../../utils/classNames'

PrimaryButton.propTypes = {
  className: PropTypes.string
}

export default function PrimaryButton({ className, ...buttonProps }) {
  return (
    <BaseButton
      className={classNames('btn--is-primary', className)}
      {...buttonProps}
    />
  )
}
