import { toggleClass } from './domHelper'

export const isHeaderSolid = () => {
  const HEADER = document.getElementsByTagName('header')[0]
  const className = HEADER.attributes.getNamedItem('data-solid-class-name')
    .value
  return HEADER.classList.contains(className)
}

export const makeHeaderSolid = boolean => {
  const HEADER = document.getElementsByTagName('header')[0]
  const className = HEADER.attributes.getNamedItem('data-solid-class-name')
    .value

  toggleClass(HEADER, className, boolean)
}
