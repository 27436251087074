import React from 'react'
import { Link } from 'react-router-dom'
import SliderArrowRightSvg from '../../_svg/SliderArrowRight'
import CompanyIcon from '../../CompanyIcon'

import './order-link-list-item.scss'

interface Item {
  created: string
  description: string
  image: string
  path: string
  title: string
}

interface OrderLinkListItemProps {
  item: Item
  orderLabel?: string
  placedOnLabel?: string
}

export default function OrderLinkListItem({
  item,
  orderLabel,
  placedOnLabel
}: OrderLinkListItemProps) {
  const { created, description, image, path, title } = item
  const formattedCreatedDate = new Date(created).toLocaleDateString()

  return (
    <li key={title} className="order-link-list-item">
      <Link className="order-link-list-item__btn" to={path}>
        {image && (
          <img className="order-link-list-item__btn-image" src={image} />
        )}

        <span className="order-link-list-item__btn-title">{`${orderLabel} #${title}`}</span>

        <span className="order-link-list-item__btn-created">
          {`${placedOnLabel} ${formattedCreatedDate}`}
        </span>

        <div className="order-link-list-item__btn-icon">
          <CompanyIcon company={description} />
        </div>
      </Link>

      <div className="order-link-list-item__arrow">
        <SliderArrowRightSvg />
      </div>
    </li>
  )
}
