import React, { useState, useRef, useEffect } from 'react'
import ReactPlayer from 'react-player'
import Box from '@material-ui/core/Box'
import Controls from '../VideoComponent/Controls'
import { makeStyles } from '@material-ui/core/styles'
import { OnProgressProps } from 'react-player/base'
import { Typography, useMediaQuery } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { VideoBlockProps, VideoState } from './type'
import { formatTime } from '../Utils/VideoUtils'

type Props = {
  props: VideoBlockProps
  videoContainerHeight: number
}

export default function VideoBlock({
  props: {
    videoUrl,
    useControls,
    playButtonText,
    thumbnailUrl,
    socialMediaUrl,
    useFullSize
  },
  videoContainerHeight
}: Props) {
  const [videoState, setVideoState] = useState<VideoState>({
    playing: false,
    muted: true,
    volume: 0,
    played: 0,
    seeking: false
  })

  const [showControls, setShowControls] = useState(false)
  const [hasStarted, setHasStarted] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const { playing, muted, volume, played, seeking } = videoState
  const playerRef = useRef<ReactPlayer>(null)
  const isMobile = useMediaQuery('(max-width: 600px)')
  const isTablet = useMediaQuery('(min-width: 601px) and (max-width: 960px)')
  const classes = useStyles()

  const video_id = socialMediaUrl?.match(
    /(?:youtube\.com.*(?:\?v=|\/embed\/)|youtu.be\/)(.{11})/
  )?.[1]

  const [socialThumbnailUrl, setSocialThumbnailUrl] = useState(
    `http://img.youtube.com/vi/${video_id}/maxresdefault.jpg`
  )

  useEffect(() => {
    const img = new Image()
    img.src = socialThumbnailUrl
    img.onload = function() {
      if (img.naturalWidth === 120 && img.naturalHeight === 90) {
        setSocialThumbnailUrl(thumbnailUrl ? thumbnailUrl : '')
      }
    }
  }, [socialThumbnailUrl, video_id])

  const handlePlayPause = () => {
    setHasStarted(true)
    setVideoState({ ...videoState, playing: !videoState.playing })
  }

  //Handle the start of the video via center play button
  const handleStart = () => {
    setHasStarted(true)
    setVideoState({ ...videoState, playing: true })
    if (useControls) {
      setShowControls(true)
    }
  }

  //Handle the progress of the video
  const progressHandler = (e: OnProgressProps) => {
    if (!seeking) {
      setVideoState({ ...videoState, ...e })
    }
  }
  //Handle the seek of the video
  const seekHandler = (e: React.ChangeEvent, value: any) => {
    setVideoState({ ...videoState, played: parseFloat(value) / 100 })
  }
  //Handle the seek of the video when releasing mouse
  const seekMouseUpHandler = (e: React.ChangeEvent, value: any) => {
    setVideoState({ ...videoState, seeking: false })
    playerRef.current?.seekTo(value / 100)
  }
  //Handle the volume of the video
  const volumeChangeHandler = (e: any, value: any) => {
    const newVolume = parseFloat(value) / 100
    setVideoState({
      ...videoState,
      volume: newVolume,
      muted: Number(newVolume) === 0 ? true : false // volume === 0 then muted
    })
  }
  //Handle the volume of the video when releasing mouse
  const volumeSeekUpHandler = (e: any, value: any) => {
    const newVolume = parseFloat(value) / 100
    setVideoState({
      ...videoState,
      volume: newVolume,
      muted: newVolume === 0 ? true : false
    })
  }
  //Handle the mute of the video
  const muteHandler = () => {
    setVideoState({
      ...videoState,
      muted: !videoState.muted,
      volume: videoState.volume > 0 ? 0 : 0.5
    })
  }
  //Get the current time of the video
  const currentTime = playerRef.current
    ? playerRef.current.getCurrentTime()
    : '00:00'
  //Get the duration of the video
  const duration = playerRef.current ? playerRef.current.getDuration() : '00:00'

  //variables for the current time and duration of the video to pass to controls
  const formatCurrentTime = formatTime(currentTime)
  const formatDuration = formatTime(duration)

  return (
    <Box
      className={classes.boxContainer}
      onMouseEnter={
        useControls && hasStarted ? () => setShowControls(true) : undefined
      }
      onMouseLeave={() => setShowControls(false)}
    >
      <ReactPlayer
        width="100%"
        height={
          isTablet
            ? '469px'
            : useFullSize && !isMobile
            ? '760px'
            : isMobile
            ? '193px'
            : !socialMediaUrl
            ? 'auto'
            : videoContainerHeight
        }
        className={classes.reactPlayer}
        ref={playerRef}
        url={videoUrl || socialMediaUrl}
        loop
        playing={playing}
        muted={muted}
        volume={volume}
        onProgress={progressHandler}
        config={{
          youtube: {
            playerVars: { controls: 0 }
          }
        }}
      />
      {showControls && (
        <Controls
          playing={playing}
          muted={muted}
          handlePlayPause={handlePlayPause}
          played={played}
          seekMouseUpHandler={seekMouseUpHandler}
          seekHandler={seekHandler}
          volume={volume}
          onVolumeChangeHandler={volumeChangeHandler}
          onVolumeSeekUp={volumeSeekUpHandler}
          muteHandler={muteHandler}
          duration={formatDuration}
          currentTime={formatCurrentTime}
        />
      )}
      {!hasStarted && (
        <div className={classes.overlay}>
          <img
            src={socialThumbnailUrl !== '' ? socialThumbnailUrl : thumbnailUrl}
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              maxWidth: '100%',
              marginLeft: 'unset'
            }}
          />
          <IconButton
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={classes.playButton}
            onClick={handleStart}
          >
            <Typography
              style={{
                margin: 'unset',
                color: isHovered ? '#000000' : '#ffffff',
                fontSize: '16px'
              }}
            >
              {playButtonText}
            </Typography>
          </IconButton>
        </div>
      )}
    </Box>
  )
}
const useStyles = makeStyles(theme => ({
  boxContainer: {
    position: 'relative',
    marginBottom: '0px',
    maxWidth: '1350px',
    margin: 'auto'
  },
  reactPlayer: {
    '&>video': {
      marginBottom: '-5px'
    },
    [theme.breakpoints.down('xs')]: {
      '&>div>iframe': {
        height: '193px !important',
        marginLeft: 'unset !important',
        width: '100% !important'
      }
    }
  },
  overlay: {
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: 'rgba(0, 0, 0, 0.5)'
    }
  },
  playButton: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '27px 24px 28px 24px',
    width: '79px',
    height: '79px',
    borderRadius: '100px',
    background: 'rgba(255, 255, 255, 0.30)',
    zIndex: 1,
    '&:hover': {
      background: '#ffffff'
    }
  }
}))
