import React from 'react'
import PropTypes from 'prop-types'
import DetailedProdutInfo from '../ProductInfo/DetailedProdutInfo'
import IconButton from '../../IconButton'
import ExternalLinkSvg from '../../_svg/ExternalLink'

import './product-item.scss'

const RegularProductItem = ({ product }) => (
  <li className="product-item">
    <div className="product-item-left-section">
      <DetailedProdutInfo {...product} />
    </div>
    {product.productUrl && (
      <div className="product-item-right-section">
        <IconButton
          SvgIcon={ExternalLinkSvg}
          onClick={() => window.open(product.productUrl, '_blank')}
        >
          Go to
        </IconButton>
      </div>
    )}
  </li>
)

RegularProductItem.propTypes = {
  product: PropTypes.object.isRequired
}

export default RegularProductItem
