import React from 'react'
import './productCardPage.scss'
import ContentArea from '../../blocks/ContentArea'
import {
  ContentAreaProps,
  SectionBlock
} from '../../blocks/ContentArea/ContentArea'

export type ProductCardPageProps = {
  contentAreaItems: SectionBlock[]
}

type Props = {
  props: ProductCardPageProps
}

const ProductCardPage = ({ props }: Props) => {
  const { contentAreaItems } = props
  const contentAreaProps = {
    sectionBlock: contentAreaItems
  } as ContentAreaProps

  return (
    contentAreaItems &&
    contentAreaItems.length > 0 && <ContentArea props={contentAreaProps} />
  )
}

export default ProductCardPage
