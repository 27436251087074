import React from 'react'

const MaterialArrowDown = () => (
  <svg className="icon">
    <use
      xlinkHref={`/Static/Images/icons.svg?=${
        process.env.BUILD_VERSION_ID
      }#icon--keyboard_arrow_down`}
    />
  </svg>
)

export default MaterialArrowDown
