import React from 'react'

const NordLockBrand = () => (
  <svg className="icon">
    <use
      xlinkHref={`/Static/Images/icons.svg?=${process.env.BUILD_VERSION_ID}#icon--nord-lock-brand`}
    />
  </svg>
)

export default NordLockBrand
