import { connect } from 'react-redux'
import LinkList from './LinkList'
import { getOrderListingCommonTranslations } from '../../modules/translations/translations.selectors'
import { getTranslation } from '../../modules/translations/translations.utils'

const mapStateToProps = (state: object) => {
  const translations = getOrderListingCommonTranslations(state)

  return {
    noOrdersLabel: getTranslation(translations, 'noOrdersLabel'),
    searchOrder: getTranslation(translations, 'searchOrder'),
    loadMoreLabel: getTranslation(translations, 'loadMore')
  }
}

export default connect(mapStateToProps)(LinkList)
