import { connect } from 'react-redux'
import InvoiceSummary from './InvoiceSummary'
import { getTranslation } from '../../../modules/translations/translations.utils'

const mapStateToProps = ({ customerOrders, translations }) => {
  const portalOrderListingTranslations =
    translations.commonTranslations.portalOrderListingTranslationsBlock

  const { invoiceSummary } = customerOrders.currentOrderDetails
  const invoiceDate = new Date((invoiceSummary || {}).invoiceDate)
  const isInvoiceOverdue = invoiceDate < new Date()
  const invoiceDateLabel = isInvoiceOverdue
    ? getTranslation(portalOrderListingTranslations, 'overDue')
    : getTranslation(portalOrderListingTranslations, 'due')

  return {
    invoiceDateLabel,
    invoiceDate,
    isInvoiceOverdue,
    invoiceTranslation: getTranslation(
      portalOrderListingTranslations,
      'invoice'
    ),
    hasInvoice: !!invoiceSummary,
    noInvoiceText: getTranslation(portalOrderListingTranslations, 'noInvoice')
  }
}

export default connect(mapStateToProps)(InvoiceSummary)
