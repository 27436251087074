import React, { useState, useEffect, useRef } from 'react'
import Box from '@material-ui/core/Box'
import { Model, TopHeroProps } from './types'
import TopHeroBackground from './TopHeroBackground'
import './top-hero.scss'
import MainContent from './MainContent'
import ScrollDownArrow from './ScrollDownArrow'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const TopHero = ({ ...props }: Model) => {
  const data = props.prop as TopHeroProps

  const [isVisible, setIsVisible] = useState(true)
  const [topHeroHeight, setTopHeroHeight] = useState<number>()
  const isMobile = useMediaQuery('(max-width:600px)')

  useEffect(() => {
    const topHeroElement = document.getElementById('hero-block')
    if (topHeroElement) {
      const height = topHeroElement.getBoundingClientRect().height
      setTopHeroHeight(height)
    }
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsVisible(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isVisible])

  const handleArrowClick = () => {
    window.scrollTo({
      top: window.scrollY + (topHeroHeight || 760),
      behavior: 'smooth'
    })
  }

  return (
    <>
      <Box
        id="hero-block"
        className="hero-block"
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
        height="100%"
        maxHeight={data.nlgHero && !isMobile ? '500px' : '100%'}
        flexDirection={
          data.useHeadingInsideMediaMobile && isMobile
            ? 'column-reverse'
            : data.useHeadingUnderMedia || isMobile
            ? 'column'
            : 'column-reverse'
        }
      >
        <TopHeroBackground
          imageUrl={data.imageUrl}
          nlgHero={data.nlgHero}
          videoUrl={data.videoUrl}
          mobileImageUrl={data.mobileImageUrl}
          mobileVideoUrl={data.mobileVideoUrl}
          fullHeight={data.fullHeight}
          fullWidth={data.fullWidth}
          isAutoPlay={data.isAutoPlay}
          useCompactLayout={data.useCompactLayout}
          useHeadingInsideMediaMobile={data.useHeadingInsideMediaMobile}
        />
        <MainContent
          textAlignment={data.textAlignment}
          buttonVariant={data.buttonVariant}
          textColor={data.textColor}
          nlgHero={data.nlgHero}
          title={data.title}
          ctaText={data.ctaText}
          ctaUrl={data.ctaUrl}
          description={data.description}
          useHeadingInsideMediaMobile={data.useHeadingInsideMediaMobile}
          useCompactLayout={data.useCompactLayout}
          useHeadingUnderMedia={data.useHeadingUnderMedia}
        />
        {isVisible && !data.useCompactLayout && (
          <ScrollDownArrow
            onClick={handleArrowClick}
            topHeroHeight={topHeroHeight}
          />
        )}
      </Box>
    </>
  )
}

export default TopHero
