import { connect } from 'react-redux'
import OrderTrackingStatus from './OrderTrackingStatus'
import { getTranslation } from '../../../modules/translations/translations.utils'
import {
  getOrderListingCommonTranslations,
  getCommonTranslations
} from '../../../modules/translations/translations.selectors'
import { getCurrentOrder } from '../../../modules/orders/orders.selectors'
import { getOrder } from '../../../modules/orders/orders.utils'
import { isDateMinValue } from 'Core/utils/dateUtils'
import { addUrlProtocol } from 'Core/utils/url'

const mapStateToProps = state => {
  const currentOrderDetails = getCurrentOrder(state)
  const portalTranslations = getOrderListingCommonTranslations(state)
  const commonTranslations = getCommonTranslations(state)
  let orderData = getOrder(state, currentOrderDetails.orderNumber)

  const confirmedShippingDateObj = new Date(orderData.confirmedShippingDate)
  const hasConfirmedShippingDate =
    isDateMinValue(confirmedShippingDateObj) === false

  let dateLabel = null
  if (orderData) {
    if (hasConfirmedShippingDate && confirmedShippingDateObj < Date.now()) {
      dateLabel = getTranslation(portalTranslations, 'shipped')
    } else {
      dateLabel = orderData
        ? hasConfirmedShippingDate
          ? getTranslation(portalTranslations, 'confirmedShippingDate')
          : getTranslation(portalTranslations, 'requestedShippingDate')
        : null
    }
  }

  return {
    statusLabel: getTranslation(commonTranslations, 'status'),
    status: orderData.status || '',
    dateLabel: dateLabel || '',
    date: hasConfirmedShippingDate
      ? orderData.confirmedShippingDate
      : orderData.requestedShippingDate,
    carrierLabel: getTranslation(portalTranslations, 'carrier'),
    carrier: orderData.carrier,

    // TEMPORARY!!!
    trackingLabel: getTranslation(portalTranslations, 'tracking'),
    trackingLinkLabel: getTranslation(portalTranslations, 'track'),
    trackingInfo: currentOrderDetails.trackingInfo.map(item => ({
      ...item,
      trackingUrl: addUrlProtocol(item.trackingUrl)
    }))
  }
}

export default connect(mapStateToProps)(OrderTrackingStatus)
