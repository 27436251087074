import React from 'react'
import BaseInput from '../BaseInput'
import { classNames } from '../../../utils/classNames'

TextAreaInput.propTypes = {
  ...BaseInput.propTypes
}

export default function TextAreaInput({ className, ...baseInputProps }) {
  return (
    <BaseInput
      className={classNames('text-area', className)}
      element="textarea"
      {...baseInputProps}
    />
  )
}
