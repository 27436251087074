import React from 'react'
import BaseProductList from '../BaseProductList'
import RegularProductItem from '../../ProductListItems/RegularProductItem'
import PropTypes from 'prop-types'

const RegularProductList = ({ title, products }) => (
  <BaseProductList
    className="regular-product-list"
    title={title}
    products={products}
    ProductItemComponent={RegularProductItem}
  />
)

RegularProductList.propTypes = {
  title: PropTypes.string,
  products: PropTypes.array
}

export default RegularProductList
