import React from 'react'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'

type MenuFooterProps = {
  heading: string
  description: string
  imageUrl: string
  link: string
}

const MenuFooter = ({
  heading,
  description,
  imageUrl,
  link
}: MenuFooterProps) => {
  return (
    <>
      <Link href={link} className="menuContent__footerLink">
        <Card className="menuContent__footerCard">
          <img src={imageUrl} width="81px" height="81px" />
          <Box className="menuContent__footerCard-textContainer">
            <Typography
              component="h4"
              className="menuContent__footerCard-heading"
            >
              {heading}
            </Typography>
            <Typography
              component="p"
              className="menuContent__footerCard-description"
            >
              {description}
            </Typography>
          </Box>
        </Card>
      </Link>
    </>
  )
}

export default MenuFooter
