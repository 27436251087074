import React from 'react'
import PropTypes from 'prop-types'
import BaseInput from '../BaseInput'
import { getUpdatedFiles } from './FileUploadInput.utils'
import { classNames } from '../../../utils/classNames'

export type FileUploadInputValue = File[]

interface FileUploadInputProps {
  value: FileUploadInputValue

  className?: string

  onChange: (updatedFiles: File[]) => void
}

export default function FileUploadInput({
  value,

  className,

  onChange,

  ...baseInputProps
}: FileUploadInputProps) {
  const selectedFiles = value

  function onBaseInputChange(
    elementValue: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const elementFiles = Array.from(event.target.files!)

    const updatedFiles = getUpdatedFiles(selectedFiles, elementFiles)

    onChange(updatedFiles)
  }

  return (
    <BaseInput<HTMLInputElement>
      className={classNames('file-input', className)}
      type="file"
      value="" // Always reset to allow re-selecting
      onChange={onBaseInputChange}
      {...baseInputProps}
    />
  )
}
