import React from 'react'
import { EnumType, GridTranslationsType } from './types'
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Popover
} from '@material-ui/core'
import ArrowDown from '../../../../Spa.Core/components/_svg/ArrowDown'
import './filter.scss'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { makeStyles } from '@material-ui/core/styles'
import {
  RadioCheckedIcon,
  RadioIcon
} from '../../components/_svg/RadioButtonIcon.js'

type FilterSortProps = {
  isClicked: boolean
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  handleClose: () => void
  anchorEl: null | HTMLElement
  sort: number
  handleChange: (
    name: string
  ) => (
    event:
      | React.ChangeEvent<{
          value: unknown
        }>
      | number
  ) => void
  gridTranslations: GridTranslationsType
  sortByEnum: EnumType[]
}

const FilterSortBy = ({
  isClicked,
  handleClick,
  handleClose,
  anchorEl,
  sort,
  handleChange,
  gridTranslations,
  sortByEnum
}: FilterSortProps) => {
  const isMobile = useMediaQuery('(max-width:600px)')

  const classes = useStyles()

  return (
    <>
      <Button
        style={{
          width: isMobile ? '50%' : 'auto',
          fontSize: isMobile ? '16px' : '14px',
          marginLeft: isMobile ? '4px' : '0'
        }}
        className={isClicked ? 'popover-open' : ''}
        aria-controls="sort-menu"
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={
          <ArrowDown
            style={{
              transform: isClicked ? 'rotate(180deg)' : 'rotate(0deg)'
            }}
          />
        }
      >
        {gridTranslations.sortBy}
      </Button>
      <Popover
        style={{ padding: '16px' }}
        id="sort-menu"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <RadioGroup
          key={sort}
          name="sort"
          value={sort?.toString()}
          onChange={handleChange('sort')}
          style={{ margin: '16px' }}
        >
          {[...sortByEnum]
            .sort((a, b) => a.id - b.id)
            .map(value => (
              <FormControlLabel
                className={classes.radioLabel}
                key={value.id}
                value={value.id.toString()}
                control={
                  <Radio
                    className={classes.radio}
                    icon={<RadioIcon />}
                    checkedIcon={<RadioCheckedIcon />}
                  />
                }
                label={value.name}
              />
            ))}
        </RadioGroup>
      </Popover>
    </>
  )
}

const useStyles = makeStyles({
  radioLabel: {
    '& .MuiFormControlLabel-label': {
      fontSize: '14px'
    }
  },
  radio: {
    '&.MuiIconButton-colorSecondary:hover': {
      backgroundColor: 'transparent !important'
    }
  }
})

export default FilterSortBy
