/** @typedef { import('./useForm.types').ElementValue } ElementValue */
/** @typedef { import('./useForm.types').ElementStatesMap } ElementStatesMap */
/** @typedef { import('./useForm.types')._InnerFormState } _InnerFormState */

import { useReducer } from 'react'
import useConstant from '../useConstant'
import { isFormValid } from './useForm.utils'

/**
 * @param {ElementStatesMap} initialElementStates
 * @param {(name: string, value: ElementValue) => boolean} isValidElementFunc
 * @returns {_InnerFormState}
 */
export default function useFormStateReducer(
  initialElementStates,
  isValidElementFunc
) {
  const initialFormState = useConstant({
    isValid: false,
    elementStates: initialElementStates
  })

  return useReducer(
    /**
     * @param {_InnerFormState} state
     * @param {object} action
     * @returns {_InnerFormState}
     * */
    function formStateReducer(state, action) {
      const { name } = action
      const elementState = state.elementStates[name]

      switch (action.type) {
        case 'change': {
          const { value } = action
          const isValid = isValidElementFunc(name, value)
          if (
            elementState.value === value &&
            elementState.isValid === isValid
          ) {
            return state // Avoid re-render
          }

          const newState = updateElement(name, { value: value, isValid })
          return {
            ...newState,
            isValid: isFormValid(newState.elementStates)
          }
        }

        case 'focus':
          if (elementState.isTouched) {
            return state // Avoid re-render
          }
          return updateElement(name, { isTouched: true })

        case 'set-valid':
          if (elementState.isValid === action.isValid) {
            return state // Avoid re-render
          }
          return updateElement(name, { isValid: action.isValid })

        case 'set-hidden':
          if (elementState.isHidden === action.isHidden) {
            return state // Avoid re-render
          }
          return updateElement(name, { isHidden: action.isHidden })

        case 'set-form-valid':
          if (state.isValid === action.isValid) {
            return state
          }
          return { ...state, isValid: action.isValid }

        default:
          return state
      }

      function updateElement(name, update) {
        return {
          ...state,
          elementStates: {
            ...state.elementStates,
            [name]: { ...state.elementStates[name], ...update }
          }
        }
      }
    },
    initialFormState
  )
}
