import React from 'react'

export default function Checkmark(svgProps) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M15.6667 6.66663L9.06702 12.9333L5.00002 9.99995"
        stroke="white"
      />
    </svg>
  )
}
