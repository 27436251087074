import React from 'react'

const WechatIcon = ({ ...props }) => (
  <svg width="24" height="24" fill="currentColor" {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.778 22H4.222A2.222 2.222 0 0 1 2 19.778V4.222C2 2.994 2.994 2 4.222 2h15.556C21.006 2 22 2.994 22 4.222v15.556A2.222 2.222 0 0 1 19.778 22ZM20 14.45c0 1.21-.65 2.299-1.665 3.035a.32.32 0 0 0-.135.262l.017.102.217.81.026.118a.16.16 0 0 1-.16.16l-.093-.03-1.056-.609a.51.51 0 0 0-.256-.074l-.141.021a5.688 5.688 0 0 1-1.573.22c-2.662 0-4.82-1.798-4.82-4.016 0-2.217 2.158-4.015 4.82-4.015 2.661 0 4.819 1.798 4.819 4.015ZM4 10.82C4 8.156 6.59 6 9.783 6c2.885 0 5.276 1.76 5.712 4.063l-.314-.008c-2.913 0-5.274 1.968-5.274 4.395 0 .409.068.805.194 1.18l-.318.009c-.66 0-1.297-.095-1.888-.265l-.17-.025a.606.606 0 0 0-.306.088l-1.266.732-.112.036a.193.193 0 0 1-.193-.194l.031-.14.261-.972.02-.123a.384.384 0 0 0-.162-.314C4.778 13.579 4 12.272 4 10.819Zm8.932 2.345a.642.642 0 1 0 1.285 0 .642.642 0 0 0-1.285 0Zm3.213 0a.643.643 0 1 0 1.285 0 .643.643 0 0 0-1.285 0Zm-9.06-3.888a.77.77 0 1 0 1.54 0 .77.77 0 0 0-1.54 0Zm3.855 0a.77.77 0 1 0 1.541 0 .77.77 0 0 0-1.541 0Z"
    />
  </svg>
)

export default WechatIcon
