const { toString } = Object.prototype

export const isObject = obj => toString.call(obj) === '[object Object]'

export const isNumber = nbr =>
  typeof nbr === 'number' && !isNaN(nbr) && isFinite(nbr)

export const isString = str => typeof str === 'string'

export const isValue = value => isNumber(value) || isString(value)

export const undefinedToNull = value => (value === undefined ? null : value)
