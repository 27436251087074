import { searchRequest } from '../../components/Search/Search.hook'

export function initialSearch(
  searchParameters: {},
  targetApi: string,
  onSearch: (request: Promise<any>, scrollInto?: boolean | null) => void,
  scrollInto?: boolean | null
) {
  onSearch(searchRequest(searchParameters, targetApi), scrollInto)
}
