import React from 'react'
import { classNames } from '../../utils/classNames'

interface NordLockInputProps {
  inputComponent: React.ComponentType<any>
  className?: string
}

function NordLockInput({
  inputComponent: InputComponent,
  className,
  ...inputProps
}: NordLockInputProps) {
  return (
    <InputComponent
      className={classNames('nord-lock-input', className)}
      {...inputProps}
    />
  )
}

export default React.memo(NordLockInput)
