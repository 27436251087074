import React from 'react'

const ArrowRight = props => (
  <svg width="28" height="29" fill="none" {...props}>
    <path
      d="M6 14.5h15M15 7.5l7 7-7 7"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
  </svg>
)

export default ArrowRight
