import React from 'react'

const NaverIcon = ({ ...props }) => (
  <svg width="24" height="24" fill="currentColor" {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.778 22H4.222A2.222 2.222 0 0 1 2 19.778V4.222C2 2.994 2.994 2 4.222 2h15.556C21.006 2 22 2.994 22 4.222v15.556A2.222 2.222 0 0 1 19.778 22ZM6 17V7h4.179l3.636 4.997V7H18v10h-4.185l-3.636-5.003V17H6Z"
    />
  </svg>
)

export default NaverIcon
