import React from 'react'
import { GridTranslationsType } from './types'
import { Box } from '@material-ui/core'

import './filter.scss'

import { makeStyles } from '@material-ui/core/styles'

type PaginationProps = {
  currentPage: number
  setCurrentPage: (arg: number) => void
  totalPages: number
  gridTranslations: GridTranslationsType
  classes: any
}

const Pagination = ({
  currentPage,
  setCurrentPage,
  totalPages,
  gridTranslations
}: PaginationProps) => {
  const classes = useStyles()

  //Pagination logic
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1)
  let start = currentPage - 2
  let end = currentPage + 1

  // If the current page is less than 2, show the first three pages
  if (currentPage < 2) {
    start = 0
    end = 3
  }

  // If the current page is the last page, show the last three pages
  if (currentPage === totalPages) {
    start = totalPages - 3
    end = totalPages
  }

  // Slice the pageNumbers array to get only the pages to display
  const pagesToDisplay = pageNumbers.slice(start, end)

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      margin="auto"
    >
      <button
        className={classes.prevNextButton}
        disabled={currentPage === 1}
        onClick={() => setCurrentPage(currentPage - 1)}
      >
        {gridTranslations.prev}
      </button>
      {pagesToDisplay.map(number => (
        <button
          className={`${classes.pageNumberButton} ${
            number === currentPage ? classes.activeButton : ''
          }`}
          key={number}
          onClick={() => setCurrentPage(number)}
          disabled={number === currentPage}
        >
          {number}
        </button>
      ))}
      <button
        className={classes.prevNextButton}
        onClick={() => setCurrentPage(currentPage + 1)}
        disabled={currentPage >= totalPages}
      >
        {gridTranslations.next}
      </button>
    </Box>
  )
}

const useStyles = makeStyles({
  activeButton: {
    backgroundColor: '#009DE0',
    color: '#FFF !important'
  },
  pageNumberButton: {
    width: '32px',
    height: '32px',
    margin: '0px 2px',
    color: '#64666D',
    borderRadius: '2px',
    '&:hover': {
      backgroundColor: '#009DE0',
      color: '#fff'
    }
  },
  prevNextButton: {
    width: 'fit-content',
    padding: '0px 8px',
    margin: '0px 2px',
    height: '32px',
    borderRadius: '2px',
    '&:hover': {
      backgroundColor: '#009DE0',
      color: '#fff'
    }
  }
})

export default Pagination
