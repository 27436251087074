import React from 'react'

const ArrowDown = () => (
  <svg className="icon">
    <use
      xlinkHref={`/Static/Images/icons.svg?=${
        process.env.BUILD_VERSION_ID
      }#icon--arrow-down`}
    />
  </svg>
)

export default ArrowDown
