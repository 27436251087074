import React from 'react'
import Box from '@material-ui/core/Box'
import { FooterFollowerBlockProps } from './types'
import { IconButton, Typography } from '@material-ui/core'
import FacebookIcon from '../../../../Spa.Core/components/_svg/FacebookIcon'
import LinkedInIcon from '../../../../Spa.Core/components/_svg/LinkedInIcon'
import TwitterIcon from '../../../../Spa.Core/components/_svg/TwitterIcon'
import YoutubeIcon from '../../../../Spa.Core/components/_svg/YoutubeIcon'
import KakaoTalkIcon from '../../../../Spa.Core/components/_svg/KakaoTalkIcon'
import NaverIcon from '../../../../Spa.Core/components/_svg/NaverIcon'
import WeiboIcon from '../../../../Spa.Core/components/_svg/WeiboIcon'
import XingIcon from '../../../../Spa.Core/components/_svg/XingIcon'
import WechatIcon from '../../../../Spa.Core/components/_svg/WechatIcon'
import YoukuIcon from '../../../../Spa.Core/components/_svg/YoukuIcon'
import ZhihuIcon from '../../../../Spa.Core/components/_svg/ZhihuIcon'

const FooterSocial = ({ ...props }) => {
  const data = props.props as FooterFollowerBlockProps

  const socialLinks = [
    { url: data.facebookUrl, Icon: FacebookIcon },
    { url: data.linkedInUrl, Icon: LinkedInIcon },
    { url: data.twitterUrl, Icon: TwitterIcon },
    { url: data.youTubeUrl, Icon: YoutubeIcon },
    { url: data.kakaoUrl, Icon: KakaoTalkIcon },
    { url: data.naverUrl, Icon: NaverIcon },
    { url: data.weiboUrl, Icon: WeiboIcon },
    { url: data.xingUrl, Icon: XingIcon },
    { url: data.wechatUrl, Icon: WechatIcon },
    { url: data.youkuUrl, Icon: YoukuIcon },
    { url: data.zhihuUrl, Icon: ZhihuIcon }
  ]

  return (
    <Box className="footer-social">
      <Typography>{data.title}</Typography>

      <Box className="footer-social__links">
        {socialLinks.map(
          (link, index) =>
            link.url && (
              <IconButton key={index} href={link.url}>
                <link.Icon />
              </IconButton>
            )
        )}
      </Box>
    </Box>
  )
}

export default FooterSocial
