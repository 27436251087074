import React from 'react'
import PropTypes from 'prop-types'
import { classNames } from '../../utils/classNames'

const SpeechBubble = ({ className }) => (
  <svg className={classNames('icon', className)}>
    <use
      xlinkHref={`/Static/Images/icons.svg?=${
        process.env.BUILD_VERSION_ID
      }#icon--speech-bubble`}
    />
  </svg>
)

SpeechBubble.propTypes = {
  className: PropTypes.string
}

export default SpeechBubble
