import { connect } from 'react-redux'
import { fetchOrderDetails } from '../../modules/orders/orders.action'
import { getTranslation } from '../../modules/translations/translations.utils'
import { getCommonTranslations } from '../../modules/translations/translations.selectors'
import FetchContainer from '../FetchContainer'

const mapStateToProps = (state, ownProps) => ({
  fetchFnArgs: {
    orderNumber: ownProps.currentOrderId,
    orderType: ownProps.currentOrderType,
    moduleId: ownProps.moduleId
  },
  isFetching: state.customerOrders.isFetchingOrderDetails,
  fetchingError: state.customerOrders.fetchingOrderDetailsError,
  errorMessageTranslation: getTranslation(
    getCommonTranslations(state),
    'somethingWentWrong'
  ),
  redirectUserErrorMessage: getTranslation(
    getCommonTranslations(state),
    'redirectUserErrorMessage'
  )
})

const mapDispatchToProps = { fetchFn: fetchOrderDetails }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FetchContainer)
