import React from 'react'
import Box from '@material-ui/core/Box'
import './footer.scss'
import FooterListDesktop from './FooterListDesktop'
import FooterSocial from './FooterSocial'
import FooterContact from './FooterContact'
import FooterLegal from './FooterLegal'
import ColorStripe from '../../../../Spa.Core/components/ColorStripe'
import { Props } from './types'
import { useMediaQuery } from '@material-ui/core'
import FooterListMobile from './FooterListMobile'

const FooterMenu = ({ props }: Props) => {
  const isMobile = useMediaQuery('(max-width: 1000px)')

  const blockToComponentMapper = () => {
    return (props.footerBlocks || []).map(block => {
      if (block.type === 'FooterSectionBlock' && isMobile) {
        return <FooterListMobile props={block.properties} />
      } else if (block.type === 'FooterSectionBlock') {
        return <FooterListDesktop props={block.properties} />
      } else if (block.type === 'FooterFollowerBlock') {
        return <FooterSocial props={block.properties} />
      } else if (block.type === 'FooterContactBlock') {
        return <FooterContact props={block.properties} />
      } else {
        return null
      }
    })
  }

  return (
    <>
      <ColorStripe className="color-stripe-footer" />
      <Box className="footer-container">{blockToComponentMapper()}</Box>
      <FooterLegal
        copyright={props.copyright}
        bottomLinks={props.bottomLinks}
      />
    </>
  )
}

export default FooterMenu
