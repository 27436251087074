import React from 'react'
import { EpiFormSectionContainer } from 'Core/types/epi/form'
import { InputPropsMap } from 'Core/hooks/useForm/useForm.types'
import BoltightFormInput from '../../ReactFormInput'
import PageBreak from '../../../../../components/pageBreak'
import InformationBox from 'Core/components/InformationBox'
import useIsContainerElementHidden from '../../../hooks/useIsContainerElementHidden'
import { classNames } from 'Core/utils/classNames'
import './section-container.scss'

interface SectionContainerProps {
  formElement: EpiFormSectionContainer
  inputProps: InputPropsMap
}

export default function SectionContainer({
  formElement,
  inputProps
}: SectionContainerProps) {
  const {
    number,
    label,
    image,
    elementsArea,
    imageFullSize,
    printIsPageBreak,
    description,
    popupAlignment
  } = formElement

  const isHidden = useIsContainerElementHidden(formElement, inputProps)

  if (isHidden) {
    return null
  }

  const containerClass = `boltight-section-container ${
    imageFullSize ? 'boltight-section-container--image-full-size' : ''
  }`

  return (
    <>
      {printIsPageBreak && <PageBreak />}

      <section className={containerClass}>
        {label && (
          <header className="boltight-section-container__header">
            <h3 className="boltight-section-container__title">
              {number && (
                <span className="boltight-section-container__title-number">
                  {number}
                </span>
              )}

              {label && (
                <span className="boltight-section-container__title-label">
                  {label}
                </span>
              )}

              {description && (
                <InformationBox
                  className={classNames(
                    'boltight-section-container__title-box',
                    popupAlignment === 1 ? 'align-bottom' : ''
                  )}
                  infoHtml={description}
                >
                  <button className="boltight-section-container__title-infobox">
                    ?
                  </button>
                </InformationBox>
              )}
            </h3>
          </header>
        )}

        <div className="boltight-section-container__content">
          {image && (
            <img
              className="boltight-section-container__image"
              src={image.url}
            />
          )}

          {elementsArea && (
            <div className="boltight-section-container__fields">
              {elementsArea.map(formElementChild => (
                <BoltightFormInput
                  key={formElementChild.name}
                  formElement={formElementChild}
                  inputProps={inputProps}
                />
              ))}
            </div>
          )}
        </div>
      </section>
    </>
  )
}
