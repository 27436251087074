import {
  ChoiceItem,
  SelectionItem,
  SubSelectionItem,
  EpiFormElementsArea,
  EpiFormSectionContainer,
  EpiFormColumnsContainer,
  EpiFormElementBase
} from '../types/epi/form'
import { InputOption, InputOptionValue } from '../types/inputs/options'
import EpiFormInputTypes from '../constants/EpiFormInputTypes'

export function transformChoiceItemsToOptions(
  items: ChoiceItem[]
): InputOption[] {
  return items.map(item => ({
    name: item.caption,
    value: item.value
  }))
}

export function transformSelectionItemsToOptions(
  items: SelectionItem[]
): InputOption[] {
  return items.map(item => ({
    name: item.key || item.caption || '',
    value: item.value
  }))
}

export function transformSubSelectionItemsToOptions(
  items: SubSelectionItem[],
  parentValue: InputOptionValue
): InputOption[] | null {
  const parentRelatedItem = items.find(
    item => item.key === parentValue || item.caption === parentValue
  )
  if (!parentRelatedItem) {
    return null
  }

  const parsedRelatedItems = JSON.parse(parentRelatedItem.value)

  return transformSelectionItemsToOptions(parsedRelatedItems)
}

export function transformElementsAreaToFormElements(
  elementsArea: EpiFormElementsArea
): EpiFormElementBase[] {
  const formElements: EpiFormElementsArea = []
  const epiFormInputTypeValues = Object.keys(EpiFormInputTypes).map(
    item => item
  )

  elementsArea.forEach(elementDefinition => {
    if (epiFormInputTypeValues.includes(elementDefinition.inputType)) {
      formElements.push(elementDefinition)
    }

    const sectionContainer = elementDefinition as EpiFormSectionContainer
    if (sectionContainer.elementsArea) {
      formElements.push(
        ...transformElementsAreaToFormElements(sectionContainer.elementsArea)
      )
    }

    const columnsContainer = elementDefinition as EpiFormColumnsContainer
    if (columnsContainer.column1ElementsArea) {
      formElements.push(
        ...transformElementsAreaToFormElements(
          columnsContainer.column1ElementsArea
        )
      )
    }

    if (columnsContainer.column2ElementsArea) {
      formElements.push(
        ...transformElementsAreaToFormElements(
          columnsContainer.column2ElementsArea
        )
      )
    }
  })

  return formElements
}
