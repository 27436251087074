import React, { useEffect, useState, useRef } from 'react'
import Box from '@material-ui/core/Box'
import './top-hero.scss'
import useMediaQuery from '@material-ui/core/useMediaQuery'

type TopHeroBackgroundProps = {
  fullHeight?: boolean
  fullWidth?: boolean
  imageUrl?: string
  videoUrl?: string
  mobileImageUrl?: string
  mobileVideoUrl?: string
  isAutoPlay?: boolean
  useCompactLayout?: boolean
  useHeadingInsideMediaMobile?: boolean
  nlgHero?: boolean
}

const TopHeroBackground = ({
  mobileVideoUrl,
  mobileImageUrl,
  imageUrl,
  fullWidth,
  videoUrl,
  useCompactLayout,
  nlgHero = false,
  useHeadingInsideMediaMobile
}: TopHeroBackgroundProps) => {
  const [isOverlay, setIsOverlay] = useState(true)
  const [imageWidth, setImageWidth] = useState('100%')
  const isMobile = useMediaQuery('(max-width:600px)')

  useEffect(() => {
    const handleScroll = () => {
      const newWidth = Math.max(100 - window.scrollY / 3, 95) + '%'
      setImageWidth(newWidth)
      setIsOverlay(window.scrollY === 0)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Box
      width={useCompactLayout ? '100%' : fullWidth ? '100%' : imageWidth}
      className={`hero-media ${isOverlay ? 'overlay' : ''}`}
      position="relative"
      display="flex"
      justifyContent="center"
      height={useCompactLayout ? '400px' : '100%'}
    >
      {isMobile ? (
        <>
          {mobileImageUrl && (
            <img
              style={{
                width: '100%',
                objectFit: useCompactLayout ? 'cover' : 'contain',
                height: '100%'
              }}
              src={mobileImageUrl}
            />
          )}
          {mobileVideoUrl && (
            <video
              src={mobileVideoUrl}
              style={{
                width: '100%',
                objectFit: useCompactLayout
                  ? 'cover'
                  : nlgHero
                  ? 'cover'
                  : 'contain',
                height: '100%'
              }}
              controls={false}
              autoPlay
              muted
              playsInline
              loop
            />
          )}
        </>
      ) : (
        <>
          {imageUrl && (
            <img
              style={{
                width: '100%',
                objectFit: useCompactLayout
                  ? 'cover'
                  : nlgHero
                  ? 'cover'
                  : 'contain',
                height: useCompactLayout ? 'unset' : '100%'
              }}
              src={imageUrl}
            />
          )}
          {videoUrl && (
            <video
              src={videoUrl}
              style={{
                width: '100%',
                objectFit: useCompactLayout
                  ? 'cover'
                  : nlgHero
                  ? 'cover'
                  : 'contain',
                height: useCompactLayout ? 'unset' : '100%'
              }}
              controls={false}
              autoPlay
              muted
              playsInline
              loop
            />
          )}
        </>
      )}
    </Box>
  )
}

export default TopHeroBackground
