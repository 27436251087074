import React from 'react'
import PropTypes from 'prop-types'

import './invoice-item-details.scss'

const InvoiceItemDetails = ({
  itemPriceLabel,
  itemPrice,
  discountPercentageLabel,
  discountPercentage,
  invoicePriceLabel,
  invoicePrice,
  statusLabel,
  status
}) => (
  <div className="invoice-item-details">
    <table className="invoice-item-details__table">
      <tbody>
        <tr className="invoice-item-details__table_row">
          <td className="invoice-item-details__table_row-label">
            {`${itemPriceLabel}: `}
          </td>
          <td className="invoice-item-details__table_row-value">{itemPrice}</td>
        </tr>
        {discountPercentage && (
          <tr className="invoice-item-details__table_row">
            <td className="invoice-item-details__table_row-label">
              {`${discountPercentageLabel}: `}
            </td>
            <td className="invoice-item-details__table_row-value">
              {discountPercentage}
            </td>
          </tr>
        )}
        <tr className="invoice-item-details__table_row">
          <td className="invoice-item-details__table_row-label">
            {`${invoicePriceLabel}: `}
          </td>
          <td className="invoice-item-details__table_row-value">
            {invoicePrice}
          </td>
        </tr>
        {/* {status && (
          <tr className="invoice-item-details__table_row">
            <td className="invoice-item-details__table_row-label">
              {`${statusLabel}: `}
            </td>
            <td className="invoice-item-details__table_row-value">{status}</td>
          </tr>
        )} */}
      </tbody>
    </table>
  </div>
)

InvoiceItemDetails.propTypes = {
  itemPriceLabel: PropTypes.string.isRequired,
  itemPrice: PropTypes.string.isRequired,
  discountPercentageLabel: PropTypes.string.isRequired,
  discountPercentage: PropTypes.string,
  invoicePriceLabel: PropTypes.string.isRequired,
  invoicePrice: PropTypes.string.isRequired,
  statusLabel: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired
}

export default InvoiceItemDetails
