import React from 'react'
import {
  EpiFormElementBase,
  EpiFormInputElement,
  EpiFormColumnsContainer,
  EpiFormSectionContainer
} from 'Core/types/epi/form'
import { InputPropsMap } from 'Core/hooks/useForm/useForm.types'
import ReactFormTypes from '../../constants/ReactFormTypes'
import EpiFormInputTypes from 'Core/constants/EpiFormInputTypes'

import ColumnContainer from '../_react-form-types/ColumnContainer'
import SectionContainer from '../_react-form-types/SectionContainer'
import FileUpload from '../_react-form-types/FileUpload'
import EpiFormInput from 'Core/components/EpiFormInput'

interface ReactFormInputProps {
  formElement: EpiFormElementBase
  inputProps: InputPropsMap
}

export default function ReactFormInput({
  formElement,
  inputProps
}: ReactFormInputProps) {
  const { name } = formElement
  const elementInputProps = inputProps[name]

  if (elementInputProps && elementInputProps.isHidden) {
    return null
  }

  switch (formElement.inputType) {
    case ReactFormTypes.ColumnContainer:
      return (
        <ColumnContainer
          formElement={formElement as EpiFormColumnsContainer}
          inputProps={inputProps}
        />
      )

    case ReactFormTypes.SectionContainer:
      return (
        <SectionContainer
          formElement={formElement as EpiFormSectionContainer}
          inputProps={inputProps}
        />
      )

    case EpiFormInputTypes.FileUploadElementBlock:
      return (
        <FileUpload
          formElement={formElement as EpiFormInputElement}
          inputProps={inputProps}
        />
      )

    default:
      return (
        <EpiFormInput
          formElement={formElement as EpiFormInputElement}
          inputProps={inputProps}
        />
      )
  }
}
