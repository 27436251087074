import React from 'react'

const XingIcon = ({ ...props }) => (
  <svg width="24" height="24" fill="currentColor" {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.222 22h15.556A2.222 2.222 0 0 0 22 19.778V4.222A2.222 2.222 0 0 0 19.778 2H4.222A2.222 2.222 0 0 0 2 4.222v15.556C2 21.006 2.994 22 4.222 22Zm3.626-6.788c.245 0 .454-.149.628-.445a4020.3 4020.3 0 0 0 2.487-4.412L9.376 7.59c-.168-.29-.38-.435-.638-.435H6.426c-.149 0-.252.048-.31.145-.07.096-.068.216.01.358l1.557 2.698c.006.007.006.01 0 .01L5.236 14.7a.338.338 0 0 0 0 .348c.064.11.164.165.3.165h2.312ZM16.128 4h2.331c.142 0 .242.048.3.145.071.104.071.223 0 .358l-5.107 9.035v.01l3.25 5.949c.07.129.074.248.01.358-.065.097-.168.145-.31.145H14.29c-.27 0-.484-.145-.638-.435l-3.28-6.017.147-.26 4.99-8.853c.161-.29.368-.435.62-.435Z"
    />
  </svg>
)

export default XingIcon
