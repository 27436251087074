import React from 'react'
import PropTypes from 'prop-types'
import SalesContact from '../../SalesContact'

import './sticky-sales-contact.scss'

const StickySalesContact = ({ contacts, moduleId }) => {
  if (!contacts || contacts.length === 0) {
    return null
  }

  return (
    <ul className="sticky-sales-contacts">
      {contacts.map(contact => (
        <li key={contact.header} className="sticky-sales-contacts__item">
          <div className="sticky-sales-contacts__item-header">
            {contact.header}
          </div>

          <SalesContact
            className="sticky-sales-contacts__contact"
            moduleId={moduleId}
            salesRepresentative={contact.salesRepresentative}
          />
        </li>
      ))}
    </ul>
  )
}

StickySalesContact.propTypes = {
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      salesRepresentative: PropTypes.shape({
        profileImageUrl: PropTypes.string,
        name: PropTypes.string.isRequired,
        title: PropTypes.string,
        phoneNumber: PropTypes.string,
        email: PropTypes.string
      })
    })
  ),

  moduleId: PropTypes.number
}

export default StickySalesContact
