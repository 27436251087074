import React from 'react'
import Box from '@material-ui/core/Box'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import { makeStyles } from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'
import { Typography } from '@material-ui/core'
import VolumeUpIcon from '@material-ui/icons/VolumeUp'
import VolumeOffIcon from '@material-ui/icons/VolumeOff'
import PauseIcon from '@material-ui/icons/Pause'
type ControlProps = {
  playing: boolean
  muted: boolean
  played: number
  volume: number
  duration: string
  currentTime: string
  handlePlayPause: () => void
  seekHandler?: (e: any, value: any) => void
  seekMouseUpHandler?: (e: any, value: any) => void
  onVolumeChangeHandler?: (e: any, value: any) => void
  onVolumeSeekUp?: (e: any, value: any) => void
  muteHandler: () => void
}
export default function Controls({
  playing,
  muted,
  handlePlayPause,
  played,
  seekHandler,
  seekMouseUpHandler,
  volume,
  onVolumeChangeHandler,
  onVolumeSeekUp,
  muteHandler,
  duration,
  currentTime
}: ControlProps) {
  const classes = useStyles()
  return (
    <div className={classes.controlsContainer}>
      <Box className={classes.controlsInner}>
        {playing ? (
          <PauseIcon onClick={handlePlayPause} className={classes.icon} />
        ) : (
          <PlayArrowIcon onClick={handlePlayPause} className={classes.icon} />
        )}
        <Slider
          className={classes.slider}
          min={0}
          max={1000}
          value={played * 1000}
          onChange={(e, value) => seekHandler && seekHandler(e, value)}
          onChangeCommitted={(e, value) =>
            seekMouseUpHandler && seekMouseUpHandler(e, value)
          }
        />
        <Box display="flex" gridGap={8}>
          <Box className={classes.label}>{currentTime}</Box>
          <Box className={classes.label}>:</Box>
          <Box className={classes.label}>{duration}</Box>
        </Box>
        <Box className={classes.volumeContainer}>
          {muted ? (
            <VolumeOffIcon onClick={muteHandler} className={classes.icon} />
          ) : (
            <VolumeUpIcon onClick={muteHandler} className={classes.icon} />
          )}
          <Slider
            className={classes.slider}
            min={0}
            max={100}
            value={volume * 100}
            onChange={(e, value) =>
              onVolumeChangeHandler && onVolumeChangeHandler(e, value)
            }
            onChangeCommitted={(e, value) =>
              onVolumeSeekUp && onVolumeSeekUp(e, value)
            }
          />
        </Box>
      </Box>
    </div>
  )
}
const useStyles = makeStyles({
  controlsContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 9999,
    width: '100%',
    background: 'rgba(0,0,0,0.5)'
  },
  controlsInner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1rem',
    padding: '1rem'
  },
  volumeContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100px'
  },
  icon: {
    color: 'white',
    cursor: 'pointer'
  },
  slider: {
    color: 'white',
    marginLeft: '0.5rem',
    marginRight: '0.5rem'
  },
  label: {
    color: 'white !important' //override style from SASS
  }
})

