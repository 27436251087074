import {
  EpiFormConditionOperator,
  EpiFormElementPredefinedValue,
  EpiFormConditionCombination,
  EpiFormElementBase,
  EpiFormChoiceElement
} from '../../types/epi/form'
import { ElementValue, InputPropsMap } from '../useForm/useForm.types'
import EpiFormInputTypes from '../../constants/EpiFormInputTypes'
import EpiFormConditionOperators from '../../constants/EpiFormConditionOperators'
import EpiFormConditionCombinations from '../../constants/EpiFormConditionCombinations'

export function getFormInputTypeDefaultValue(formElement: EpiFormElementBase) {
  switch (formElement.inputType) {
    case EpiFormInputTypes.ChoiceElementBlock:
      const choiceFormElement = formElement as EpiFormChoiceElement
      const defaultValues = choiceFormElement.items
        .filter(item => item.checked === true)
        .map(item => item.value)

      return choiceFormElement.allowMultiSelect
        ? defaultValues
        : defaultValues[0] || ''

    case EpiFormInputTypes.FileUploadElementBlock:
      return []

    case EpiFormInputTypes.NumberElementBlock:
      return null

    default:
      return ''
  }
}

export function resolveConditionOperator(
  operator: EpiFormConditionOperator,
  expectedValue: EpiFormElementPredefinedValue,
  stateValue: ElementValue
) {
  const lowerCaseExpectedValue = String(expectedValue).toLowerCase()
  const lowerCaseValue = String(stateValue).toLowerCase()

  switch (operator) {
    case EpiFormConditionOperators.Equals:
      return lowerCaseValue === lowerCaseExpectedValue

    case EpiFormConditionOperators.NotEquals:
      return lowerCaseValue !== lowerCaseExpectedValue

    case EpiFormConditionOperators.Contains:
      return lowerCaseValue.includes(lowerCaseExpectedValue)

    case EpiFormConditionOperators.NotContains:
      return !lowerCaseValue.includes(lowerCaseExpectedValue)

    case EpiFormConditionOperators.MatchRegularExpression:
      return new RegExp(String(expectedValue)).test(lowerCaseValue)

    default:
      return true
  }
}

export function resolveConditionCombination(
  conditionCombination: EpiFormConditionCombination,
  conditionStates: boolean[]
) {
  switch (conditionCombination) {
    case EpiFormConditionCombinations.All:
      return conditionStates.every(Boolean)

    case EpiFormConditionCombinations.Any:
      return conditionStates.some(Boolean)

    default:
      return true
  }
}

export function isElementConditionsSatisfied(
  formElement: EpiFormElementBase,
  inputProps: InputPropsMap
) {
  const { conditions, conditionCombination } = formElement

  const conditionStates = conditions!.map(condition => {
    const { field, fieldValue, operator } = condition
    const fieldInputProps = inputProps[field.name]

    return fieldInputProps
      ? resolveConditionOperator(operator, fieldValue, fieldInputProps.value)
      : false
  })

  const isSatisfied = resolveConditionCombination(
    conditionCombination as EpiFormConditionCombination,
    conditionStates
  )

  return isSatisfied
}
