import React from 'react'
import BaseProductList from '../BaseProductList'
import ExpandableProductItem from '../../ProductListItems/ExpandableProductItem'

const ExpandProductList = ({ title, products }) => (
  <BaseProductList
    className="expand-product-list"
    title={title}
    products={products}
    ProductItemComponent={ExpandableProductItem}
  />
)

export default ExpandProductList
