import React from 'react'

const ExpanderBrand = () => (
  <svg className="icon">
    <use
      xlinkHref={`/Static/Images/icons.svg?=${process.env.BUILD_VERSION_ID}#icon--expander-brand`}
    />
  </svg>
)

export default ExpanderBrand
