import React, { useState, useContext, useRef } from 'react'
import PageModelContext from '../../contexts/PageModelContext'
import FetchStatus from '../../constants/fetchStatus'
import Search from '../../components/Search'
import { SearchInput } from '../../components/Search/search'
import FileList from '../../components/FileList'
import CertificateDownloadPageModel from './types/CertificateDownloadPageModel'
import { initialSearch } from './CertificateDownload.hook'
import useOnMount from 'Core/hooks/useOnMount'
import {
  fileListTransform,
  TransformFileObject
} from './CertificateDownload.transform'

interface CertificateDownload {
  productNumber: string
  batchNumber: string
}

export default function CertificateDownload({
  productNumber,
  batchNumber
}: CertificateDownload) {
  const [searchStatus, setSearchStatus] = useState(FetchStatus.Initial)
  const [searchResult, setSearchResult] = useState<TransformFileObject[]>([])

  const certificateDownloadRef = useRef<HTMLDivElement>(null)

  let pageModel = useContext(PageModelContext) as CertificateDownloadPageModel

  const {
    batchNumberLabel,
    batchNumberPlaceholder,
    productNumberLabel,
    productNumberPlaceholder
  } = pageModel

  const targetApi = '/api/Certificate/nordLock'

  const searchInputs: SearchInput[] = [
    {
      id: 'productNumber',
      label: productNumberLabel,
      placeholder: productNumberPlaceholder,
      type: 'text',
      defaultValue: productNumber
    },
    {
      id: 'batchNumber',
      label: batchNumberLabel,
      placeholder: batchNumberPlaceholder,
      type: 'text',
      defaultValue: batchNumber
    }
  ]

  useOnMount(() => {
    if (searchStatus === FetchStatus.Initial && productNumber && batchNumber) {
      const searchParameters = {
        productNumber,
        batchNumber
      }

      initialSearch(searchParameters, targetApi, searchCallback, true)
    }
  })

  async function searchCallback(
    searchPromise: Promise<any>,
    scrollInto: boolean | null | undefined
  ) {
    setSearchStatus(FetchStatus.Loading)

    try {
      const result = await searchPromise
      await setSearchResult(fileListTransform(result))
      await setSearchStatus(FetchStatus.Complete)

      if (scrollInto) {
        scrollToPosition()
      }
    } catch {
      setSearchStatus(FetchStatus.Error)
    }
  }

  function scrollToPosition() {
    if (certificateDownloadRef && certificateDownloadRef.current) {
      const yOffset = -77.5
      const top =
        certificateDownloadRef.current.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset

      window.scrollTo({ top: top, behavior: 'smooth' })
    }
  }

  return (
    <div ref={certificateDownloadRef} className="certificate-download">
      <Search
        searchInputs={searchInputs}
        onSearch={searchCallback}
        targetApi={targetApi}
        {...pageModel}
      />
      <FileList status={searchStatus} listData={searchResult} {...pageModel} />
    </div>
  )
}
