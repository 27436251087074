import { getCurrentLocale } from '../translations/translations.selectors'

export const submitReactForm = (formValues, target) => async (
  dispatch,
  getState
) => {
  const formData = new FormData()

  for (let name in formValues) {
    const value = formValues[name]

    if (Array.isArray(value)) {
      value.forEach(valueItem => {
        formData.append(name, valueItem)
      })
    } else {
      formData.append(name, value)
    }
  }

  try {
    return await fetch(target, {
      method: 'post',
      body: formData,
      headers: {
        Accept: 'application/json',
        'X-Locale': getCurrentLocale(getState())
      }
    })
  } catch (error) {
    console.error('submitBoltightForm', { error })
    return error
  }
}
