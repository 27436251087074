import MegaMenuContentContainer from './MegaMenuContentContainer'
import { ButtonProps, ItemProp } from './useMegaMenuButton'
import React, { useEffect, useRef, useState } from 'react'
import {
  HeaderNavigationLinks,
  MegaMenuFooterBlock,
  MegaMenuLinks,
  SearchSettingsBlock
} from '../Header/types'
import Box from '@material-ui/core/Box'
import { HEADER_HEIGHT } from '../Header/DesktopHeader/DesktopHeader'
import { debounce } from '../../utils/debounce'
import MenuSearch from './MenuSearch'

type Props = {
  item: HeaderNavigationLinks | null
  content?: React.ReactNode
  isOpen: boolean
  isSearch: boolean
  itemProps: ItemProp[]
  allProductsButtonProps?: ButtonProps<HTMLButtonElement>
  buttonRef: React.RefObject<HTMLButtonElement>
  menuFooterBlock?: MegaMenuFooterBlock[] | undefined
  searchSettingsBlock?: SearchSettingsBlock
}

const MegaMenuContent = ({
  item,
  isOpen,
  isSearch,
  buttonRef,
  menuFooterBlock,
  searchSettingsBlock
}: Props) => {
  const menuRef = useRef<HTMLDivElement>(null)
  const [buttonPosition, setButtonPosition] = useState(0)
  const [firstButtonPosition, setFirstButtonPosition] = useState(0)
  const [menuPosition, setMenuPosition] = useState(0)
  const [selectedNavigationItem, setSelectedNavigationItem] = useState<number>(
    0
  )

  const handleSelectedNavigationItem = (menuBlock: MegaMenuLinks) => {
    if (item && item.megaMenuBlocks) {
      const selectedNavigationItem = item.megaMenuBlocks.findIndex(
        item => item.title === menuBlock.title
      )
      setSelectedNavigationItem(selectedNavigationItem)
    }
  }

  useEffect(() => {
    function handleResize() {
      setTimeout(() => {
        menuRef.current?.style.setProperty('transform', 'unset')
        const buttonRect = buttonRef.current?.getBoundingClientRect()
        const menuRect = menuRef.current?.getBoundingClientRect()
        const firstButton = document.querySelectorAll('.megamenu-button')[0]
        setButtonPosition(buttonRect?.left!)
        setMenuPosition(menuRect?.left!)
        setFirstButtonPosition(firstButton.getBoundingClientRect().left)
      }, 300)
    }

    handleResize() // initial call to get position of the element on mount
    window.addEventListener('resize', debounce(handleResize))
    return () => window.removeEventListener('resize', handleResize)
  }, [menuRef, buttonRef])

  return (
    <MegaMenuContentContainer
      menuFooterBlock={menuFooterBlock}
      navigationItem={item && item.megaMenuBlocks}
      handleSelectedNavigationItem={handleSelectedNavigationItem}
      activeNavigationItem={selectedNavigationItem}
      top={HEADER_HEIGHT}
      flexDirection="column"
      visibility={isOpen ? 'visible' : 'hidden'}
      position="absolute"
      className="megaMenuContent"
      role="menu"
      isSearch={isSearch}
      translateContent={firstButtonPosition - menuPosition}
      style={{
        left: '0',
        padding: '40px 24px 0 24px',
        opacity: isOpen ? 1 : 0,
        zIndex: isOpen ? 2100 : -1,
        transform: isOpen ? 'none' : 'translateZ(0)', // Prevents flickering in Safari
        backgroundColor: 'white',
        // transition: `opacity .3s ease-in`,
        // animation: isOpen ? `.5s ease-in-out` : 'none',
        // animationDelay: '.2s',
        animationFillMode: 'forwards',
        width: '100vw'
      }}
      containerProps={{
        maxWidth: '1350px',
        position: 'relative',
        padding: '0 0 20px 0'
      }}
      aria-hidden={!isOpen}
      isOpen={isOpen}
    >
      {(isSearch && (
        <Box
          {...({ ref: menuRef } as any)}
          style={{
            transform: `translateX(${firstButtonPosition - menuPosition}px)`,
            paddingLeft: '22px'
          }}
          my={6}
          width={1.0}
          ml="20px"
        >
          <MenuSearch searchSettingsBlock={searchSettingsBlock} />
        </Box>
      )) || (
        <Box
          {...({ ref: menuRef } as any)}
          style={{
            transform: `translateX(${buttonPosition - menuPosition}px)`,
            paddingLeft: '22px'
          }}
          position="relative"
          mb="40px"
          display="flex"
          height={1.0}
          className="megamenu-linkitemcontainer"
        >
          <Box component="div" className="megamenu-linkitemwrapper">
            {item &&
              item.megaMenuBlocks &&
              item?.megaMenuBlocks[selectedNavigationItem].megaMenuLinks?.map(
                (menu, i) => {
                  return (
                    <a href={menu.href} key={i} className="megamenu-linkitems">
                      <span>{menu.text}</span>
                    </a>
                  )
                }
              )}
          </Box>
        </Box>
      )}
    </MegaMenuContentContainer>
  )
}

export default MegaMenuContent
