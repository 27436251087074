import React from 'react'
import { getBackgroundOrForegroundColor } from '../ContentArea/ContentArea'
import { classNames } from 'Core/utils/classNames'
import { BackgroundOrForegroundColorEnum } from '../ContentArea/ContentArea'
import { NLGForegroundColor } from '../NlgBlock/NlgBlock'

const HtmlContent = ({
  html,
  children,
  textColor,
  contrastOnBlue,
  contentAreaFgColor
}: {
  html: string
  children: React.ReactNode
  textColor?: NLGForegroundColor
  contrastOnBlue: boolean
  contentAreaFgColor?: BackgroundOrForegroundColorEnum
}) => {
  const getTextColor = (color: NLGForegroundColor | string) => {
    const colors = {
      0: '--none',
      1: '--white',
      2: '--blue',
      3: '--dark-blue',
      4: '--gray',
      5: '--black'
    }
    if (typeof color === 'number' && color >= 0 && color <= 5) {
      return colors[color]
    } else {
      return '--none'
    }
  }

  let finalColor: string

  if (textColor !== undefined) {
    finalColor = getTextColor(textColor)
  } else if (contentAreaFgColor !== undefined) {
    finalColor = getBackgroundOrForegroundColor(contentAreaFgColor)
  } else {
    finalColor = '--none'
  }

  return (
    <div
      className={classNames(
        'htmlContent',
        `color${finalColor}`,
        contrastOnBlue && 'contrastOnBlue'
      )}
    >
      <div dangerouslySetInnerHTML={{ __html: html }} />
      {children}
    </div>
  )
}

export default HtmlContent
