import React from 'react'
import { classNames } from '../../utils/classNames'

const Envelope = ({ className, ...svgProps }) => (
  <svg className={classNames('icon', className)} {...svgProps}>
    <use
      xlinkHref={`/Static/Images/icons.svg?=${
        process.env.BUILD_VERSION_ID
      }#icon--envelope`}
    />
  </svg>
)

export default Envelope
