import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import useResize from '../../hooks/useResize'
import { classNames } from '../../utils/classNames'

import './tabs.scss'

const Tabs = ({ children }) => {
  useResize()

  const [activeTabIndex, setActiveTab] = useState(0)

  const listRef = React.createRef()
  const tabsBarRef = React.createRef()

  const setTabsBarStyles = index => {
    const listElement = listRef.current
    const tabElement = listElement.children[index]
    const boundingRect = tabElement.getBoundingClientRect()

    tabsBarRef.current.style.width = `${boundingRect.width}px`
    tabsBarRef.current.style.left = `${boundingRect.left}px`
  }

  const setCurrentTabIndex = index => {
    setActiveTab(index)
  }

  useEffect(() => {
    setTabsBarStyles(activeTabIndex)
  })

  return (
    <div className="swipeable-tabs">
      <ol className="swipeable-tabs__list" ref={listRef}>
        {children.map((child, index) => {
          const { label } = child.props
          return (
            <li
              key={index}
              className={classNames(
                'swipeable-tabs__list-item',
                activeTabIndex === index &&
                  'swipeable-tabs__list-item--is-active'
              )}
              onClick={() => setCurrentTabIndex(index)}
            >
              {label}
            </li>
          )
        })}

        <span className="swipeable-tabs__list-bar" ref={tabsBarRef} />
      </ol>

      <SwipeableViews
        className="swipeable-tabs__content"
        index={activeTabIndex}
        onChangeIndex={setCurrentTabIndex}
        // animateHeight
      >
        {children}
      </SwipeableViews>
    </div>
  )
}

Tabs.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element)
}

export default Tabs
