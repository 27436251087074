export default {
  FileUploadElementBlock: 'FileUploadElementBlock',
  TextboxElementBlock: 'TextboxElementBlock',
  ChoiceElementBlock: 'ChoiceElementBlock',
  NumberElementBlock: 'NumberElementBlock',
  SelectionElementBlock: 'SelectionElementBlock',
  SubSelectionElementBlock: 'SubSelectionElementBlock',
  CountrySelectionElementBlock: 'CountrySelectionElementBlock',
  TextareaElementBlock: 'TextareaElementBlock',
  RichTextField: 'ParagraphTextElementBlock',
  HoneypotInput: 'HoneypotInput'
}
