import React from 'react'
import { classNames } from 'Core/utils/classNames'
import './color-stripe.scss'

interface ColorStripeProps {
  stickToTop?: boolean
  className?: string
}

export default function ColorStripe({
  stickToTop,
  className
}: ColorStripeProps) {
  return (
    <div
      className={classNames(
        'color-stripe',
        stickToTop && 'color-stripe--top-fixed',
        className
      )}
    >
      <div className="color-stripe__item" />
      <div className="color-stripe__item" />
      <div className="color-stripe__item" />
      <div className="color-stripe__item" />
      <div className="color-stripe__item" />
    </div>
  )
}
