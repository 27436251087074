import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox'
import {
  CheckboxIcon,
  CheckedIcon
} from '../../../../Spa.Core/components/_svg/Checkbox.js'

const FilterCheckbox = withStyles({
  root: {
    color: '#64666D',
    '&$checked': {
      color: '#009DE0'
    },
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  checked: {}
})((props: CheckboxProps) => (
  <Checkbox
    icon={<CheckboxIcon />}
    checkedIcon={<CheckedIcon />}
    color="default"
    {...props}
  />
))

export default FilterCheckbox
