import React, { useState, useEffect, useRef } from 'react'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'

type ScrollProps = {
  window?: () => Window
  children: React.ReactElement
}

const ColorScroll = (props: ScrollProps) => {
  const prevScrollPos = useRef(window.scrollY)
  const [visible, setVisible] = useState(true)

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: props.window ? props.window() : undefined
  })

  useEffect(() => {
    const handleScroll = () => {
      const heroBlock = document.getElementById('hero-block')
      if (heroBlock) {
        const currentScrollPos = window.scrollY
        const scrollingDown = prevScrollPos.current < currentScrollPos
        const scrollingUp = prevScrollPos.current > currentScrollPos
        const shouldShow =
          (scrollingDown && currentScrollPos > 70) ||
          (scrollingUp && currentScrollPos > 70) ||
          currentScrollPos < 5

        prevScrollPos.current = currentScrollPos
        setVisible(shouldShow)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return React.cloneElement(props.children, {
    style: {
      position: 'fixed',
      transform: visible ? 'translateY(4px)' : 'translateY(-100%)',
      backgroundColor: trigger ? 'white' : 'transparent',
      color: trigger ? 'black' : 'white',
      transition:
        'transform 0.5s ease, backgroundColor 0.5s ease, color 0.5s ease',
      boxShadow: 'none'
    }
  })
}

export default ColorScroll
