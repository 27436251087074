import React from 'react'
import PropTypes from 'prop-types'
import CheckboxInput from '../CheckboxInput'
import { classNames, addChildClassName } from '../../../utils/classNames'

import './checkboxes.scss'

CheckboxesInput.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool,

  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      disabled: PropTypes.bool
    })
  ),

  className: PropTypes.string,

  onChange: PropTypes.func.isRequired
}

export default function CheckboxesInput({
  value,
  disabled,

  options,

  className,
  forwardRef, // Don't pass on for these

  onChange
}) {
  function onCheckboxChange(isChecked, event) {
    const elementId = event.currentTarget.id
    const isInValue = value.includes(elementId)

    let updatedValue = value
    if (isChecked && !isInValue) {
      updatedValue = [...value, elementId].sort()
    } else if (!isChecked && isInValue) {
      updatedValue = value.filter(item => item !== elementId)
    }

    onChange(updatedValue, event)
  }

  return (
    <ul className={classNames('checkboxes', className)}>
      {options.map(option => (
        <li
          key={option.value}
          className={classNames(
            'checkboxes__item',
            addChildClassName(className, 'item')
          )}
        >
          <CheckboxInput
            id={option.value}
            name={option.name}
            value={value.includes(option.value)}
            disabled={disabled || options.disabled}
            onChange={onCheckboxChange}
          >
            {option.name}
          </CheckboxInput>
        </li>
      ))}
    </ul>
  )
}
