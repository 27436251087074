/** @typedef { import('./useForm.types').ElementHandlersMap } ElementHandlersMap */

import { useMemo } from 'react'

/**
 * @returns {ElementHandlersMap}
 **/
export default function useElementHandlers(
  elementNames,
  dispatch,
  elementRefsContainer
) {
  return useMemo(
    () =>
      elementNames.reduce(
        (elementHandlers, name) => ({
          ...elementHandlers,
          [name]: {
            forwardRef: refElement => {
              elementRefsContainer.current[name] = refElement
            },
            onChange: value => dispatch({ type: 'change', name, value }),
            onBlur: () => dispatch({ type: 'blur', name }),
            onFocus: () => dispatch({ type: 'focus', name }),
            onHiddenChange: isHidden =>
              dispatch({ type: 'set-hidden', name, isHidden })
          }
        }),
        {}
      ),
    [elementNames]
  )
}
