import React, { useEffect, useState } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import NordLockGroupIcon from '../../../components/_svg/NordLockGroupIcon'
import Box from '@material-ui/core/Box'
import ColorStripe from '../../../../../Spa.Core/components/ColorStripe'
import Button from '@material-ui/core/Button'
import './desktopHeader.scss'
import ColorScroll from '../ColorScroll'
import MegaMenuButton from '../../MegaMenu/MegaMenuButton'
import Search from '../../../../../Spa.Core/components/_svg/Search'
import {
  HeaderNavigationLinks,
  MegaMenuFooterBlock,
  LanguageSelections,
  SearchSettingsBlock
} from '../types'
import DesktopLanguagePicker from '../../../components/LanguagePicker/DesktopLanguagePicker'
import { getCookie } from '../../../../../Brand/js/core/utils/Cookies'
import ArrowDown from '../../../../../Spa.Core/components/_svg/ArrowDown'
import '../../../components/LanguagePicker/language-picker.scss'
import { IconButton } from '@material-ui/core'
import Cart from '../../../components/Cart'

type DesktopHeaderProps = {
  contactButtonText: string
  contactButtonUrl: string
  navigationMenuBlocks: HeaderNavigationLinks[]
  megaMenuFooterBlock: MegaMenuFooterBlock[]
  languageSelections: LanguageSelections
  hasHero: boolean
  searchSettingsBlock?: SearchSettingsBlock
  iconUrl: string
  currentLanguageSelected: string
  headerCartActionButtonText: string
  checkoutPageUrl: string
  quantityShort: string
  cartIsEmpty: string
  showBasket: boolean
  isShopAvailable: boolean
}

export const HEADER_HEIGHT = '65px'

export default function DesktopHeader({
  contactButtonText,
  contactButtonUrl,
  navigationMenuBlocks,
  languageSelections,
  megaMenuFooterBlock,
  hasHero,
  searchSettingsBlock,
  iconUrl,
  currentLanguageSelected,
  headerCartActionButtonText,
  checkoutPageUrl,
  quantityShort,
  cartIsEmpty,
  showBasket,
  isShopAvailable
}: DesktopHeaderProps) {
  const [isMouseOver, setIsMouseOver] = useState(false)
  const [open, setOpen] = useState(false)
  const [scrolled, setScrolled] = useState(false)
  const [showBackdrop, setShowBackdrop] = useState(false)
  const [hover, setHover] = useState(false)

  const handleMouseEnter = () => {
    setIsMouseOver(true)
    setShowBackdrop(window.scrollY > 0)
  }

  const handleMouseLeave = () => {
    setIsMouseOver(false)
    setShowBackdrop(false)
  }

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY
      if (offset > 1) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <ColorStripe />
      <ColorScroll>
        <AppBar
          className={`header ${scrolled ? 'scrolled' : ''} ${
            hasHero ? '' : 'light-background'
          }`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{ height: HEADER_HEIGHT }}
        >
          <Toolbar className="header__toolbar">
            <Box className="header__toolbar-container">
              <a href={iconUrl} className="header__iconLink">
                <NordLockGroupIcon />
              </a>
              <Box className="header__links" color={isMouseOver && '#000000'}>
                {navigationMenuBlocks?.map((item, i) => {
                  return (
                    <MegaMenuButton
                      key={i}
                      item={item}
                      menuFooterBlock={megaMenuFooterBlock}
                      searchSettingsBlock={searchSettingsBlock}
                      isEmpty={
                        item.megaMenuBlocks === null ||
                        item.megaMenuBlocks.length === 0 ||
                        !!item.url?.length
                      }
                    />
                  )
                })}
              </Box>
              <Box className="header__right-side">
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  pr="30px"
                >
                  <Button
                    onClick={handleClickOpen}
                    endIcon={<ArrowDown />}
                    className={`header__language-picker-desktop ${
                      scrolled ? 'scrolled' : ''
                    } ${isMouseOver ? 'hovered' : ''}`}
                  >
                    {currentLanguageSelected}
                  </Button>
                  <DesktopLanguagePicker
                    open={open}
                    handleClose={handleClose}
                    languageSelections={languageSelections}
                  />
                  <MegaMenuButton
                    isSearch
                    menuFooterBlock={megaMenuFooterBlock}
                    searchSettingsBlock={searchSettingsBlock}
                  >
                    <Search
                      color={
                        hasHero && !scrolled && !isMouseOver
                          ? '#ffffff'
                          : '#000000'
                      }
                    />
                  </MegaMenuButton>
                  <Cart
                    hasHero={hasHero}
                    scrolled={scrolled}
                    isMouseOver={isMouseOver} 
                    headerCartActionButtonText={headerCartActionButtonText} 
                    checkoutPageUrl={checkoutPageUrl} 
                    quantityShort={quantityShort} 
                    cartIsEmpty={cartIsEmpty}    
                    showBasket={showBasket}     
                    isShopAvailable={isShopAvailable}    
                  />
                </Box>
                <Button
                  style={{
                    color: hover ? '#ffffff' : '#ffffff',
                    backgroundColor: hover ? '#007ac0' : '#009de0'
                  }}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                  href={contactButtonUrl}
                  className="header__contact-button"
                >
                  {contactButtonText}
                </Button>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      </ColorScroll>
      {showBackdrop && (
        <div className={`backdrop ${showBackdrop ? 'show' : ''}`}></div>
      )}
    </>
  )
}
