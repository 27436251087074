import React from 'react'

const CartIcon = ({ color }) => {
  console.log('color', color)
  return (
    <svg width="24" height="24" fill="none" stroke={color}>
      <path d="M20 19V5H4v14h16Z" stroke={color} strokeLinecap="round" />
      <path d="M15 9a3 3 0 0 1-6 0" stroke={color} strokeLinejoin="round" />
    </svg>
  )
}

export default CartIcon
