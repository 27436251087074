import React from 'react'

const SuperboltIcon = () => (
  <svg className="icon">
    <use
      xlinkHref={`/Static/Images/icons.svg?=${
        process.env.BUILD_VERSION_ID
      }#icon--superbolt_icon`}
    />
  </svg>
)

export default SuperboltIcon
