import React from 'react'

const SuperBoltBrand = () => (
  <svg className="icon">
    <use
      xlinkHref={`/Static/Images/icons.svg?=${process.env.BUILD_VERSION_ID}#icon--super-bolt-brand`}
    />
  </svg>
)

export default SuperBoltBrand
