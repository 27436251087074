import Box from '@material-ui/core/Box'
import React, { useRef, useEffect } from 'react'
import './contentArea.scss'
import { classNames } from '../../utils/classNames'
import { RichTextBlockProps } from '../../blocks/RichTextBlock/types'
import ProductCard, {
  ProductCardProps
} from '../../blocks/ProductCard/ProductCard'
import RichTextBlock from '../../blocks/RichTextBlock'
import BrandCard, { BrandCardProps } from '../../blocks/BrandCard/BrandCard'
import ContentCard from '../../components/ContentCard/ContentCard'
import { ContentCardProps } from '../../components/ContentCard/types'
import ContentMenu, {
  ContentMenuProps
} from '../../blocks/ContentMenu/ContentMenu'
import NlgBlock, { NlgBlockProps } from '../../blocks/NlgBlock/NlgBlock'
import {
  FlexContainerBlock,
  FlexContainerBlockProps
} from '../../blocks/FlexContainerBlock/FlexContainerBlock'
import { ContainerArticlePageProps } from '../ContainerArticlePage/types'
import ContainerArticlePage from '../ContainerArticlePage'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ImageBannerCarousel from '../ImageBannerCarousel/ImageBannerCarousel'

export type DisplayOption =
  | 'Automatic'
  | 'Full'
  | '12'
  | '9'
  | '8'
  | '6'
  | '4'
  | '3'
  | '1'
export type BackgroundOrForegroundColorEnum = 0 | 1 | 2 | 3 | 4 | 5
export type AlignmentEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8
export type PaddingEnum = 0 | 1 | 2 | 3 | 4 | 5

type ContentType =
  | 'BrandCardBlock'
  | 'ProductCardBlock'
  | 'RichTextBlock'
  | 'ArticlePage'
  | 'ProductPage'
  | 'NLGBlock'
  | 'FlexContainerBlock'
  | 'Container_ArticlePage'

export type SectionBlock = {
  type: ContentType
  properties:
    | RichTextBlockProps
    | ProductCardProps
    | BrandCardProps
    | NlgBlockProps
    | FlexContainerBlockProps
    | ContainerArticlePageProps
    | any
  displayOptionTag: DisplayOption
}

export type ContentAreaProps = {
  title?: string | null
  sectionBlock: SectionBlock[]
  contain?: boolean
  spacing?: boolean
  padding?: PaddingEnum
  alignment?: AlignmentEnum
  description?: string | null
  backgroundColor?: BackgroundOrForegroundColorEnum
  foregroundColor?: BackgroundOrForegroundColorEnum
  overlayColor?: BackgroundOrForegroundColorEnum
  type?: string | null
  displayAsCarouselMobile?: boolean
  matchHeights?: boolean | null
  locale: string
}

type Props = {
  props: ContentAreaProps
  menu?: ContentMenuProps[]
  isTopContent?: boolean
}

export const getDisplayOption = (displayOption: DisplayOption | null) => {
  const displayOptions = {
    Automatic: '--auto',
    Full: '--full-width',
    '12': '--12',
    '9': '--9',
    '8': '--8',
    '6': '--6',
    '4': '--4',
    '3': '--3',
    '1': '--1'
  }
  return displayOptions[displayOption || 'Automatic'] || '--auto'
}

export const getBackgroundOrForegroundColor = (
  color: BackgroundOrForegroundColorEnum | string
) => {
  const colors = {
    0: '--white',
    1: '--blue',
    2: '--dark-blue',
    3: '--gray',
    4: '--black',
    5: '--none'
  }
  if (typeof color === 'number' && color >= 0 && color <= 5) {
    return colors[color]
  } else {
    return '--none'
  }
}

export const getPadding = (padding: PaddingEnum) => {
  const paddings = {
    0: '-none',
    1: '-xs',
    2: '-sm',
    3: '-md',
    4: '-lg',
    5: '-xl'
  }
  return paddings[padding] || '-none'
}

export const getAlignment = (alignment: AlignmentEnum) => {
  const alignments = {
    0: '-left',
    1: '-center',
    2: '-right',
    3: '-top-left',
    4: '-top-center',
    5: '-top-right',
    6: '-bottom-left',
    7: '-bottom-center',
    8: '-bottom-right'
  }
  return alignments[alignment] || '-left'
}

const getBlockToRender = (
  block: SectionBlock,
  backgroundColor: BackgroundOrForegroundColorEnum,
  foregroundColor: BackgroundOrForegroundColorEnum,
  isTopContent?: boolean,
  locale?: string
) => {
  switch (block.type) {
    case 'RichTextBlock':
      return (
        <RichTextBlock
          props={{
            ...(block.properties as RichTextBlockProps),
            contentAreaFgColor: isTopContent ? undefined : foregroundColor
          }}
        />
      )
    case 'BrandCardBlock':
      return <BrandCard props={block.properties as BrandCardProps} />
    case 'ProductPage' || 'ProductCardBlock':
      return <ProductCard props={block.properties as ProductCardProps} />
    case 'ArticlePage':
      return <ContentCard item={block.properties as ContentCardProps} />
    case 'NLGBlock':
      return (
        <NlgBlock
          props={{
            ...(block.properties as NlgBlockProps),
            contentAreaBgColor: backgroundColor
          }}
        />
      )
    case 'FlexContainerBlock':
      return (
        <FlexContainerBlock
          props={block.properties as FlexContainerBlockProps}
        />
      )
    case 'Container_ArticlePage':
      return (
        <ContainerArticlePage
          props={{
            ...(block.properties as ContainerArticlePageProps),
            locale: locale || 'en'
          }}
        />
      )
    default:
      return null
  }
}

const ContentArea = ({ props, menu, isTopContent }: Props) => {
  const {
    title,
    sectionBlock,
    contain = true,
    spacing = false,
    backgroundColor = 0,
    foregroundColor = 0,
    overlayColor = 0,
    padding = 0,
    alignment = 0,
    description,
    type,
    displayAsCarouselMobile,
    matchHeights = false,
    locale
  } = props

  const isMobile = useMediaQuery('(max-width: 1000px)')
  const spacingGap = spacing ? '32px' : '16px'
  const refs = useRef<Array<React.RefObject<HTMLElement>>>(
    matchHeights ? sectionBlock.map(() => React.createRef<HTMLElement>()) : []
  )

  useEffect(() => {
    const calculateHeights = () => {
      if (matchHeights) {
        // Reset all heights to their natural heights before recalculating
        refs.current.forEach(item => {
          const headline = item.current?.querySelector(
            '.htmlContent > div > h3'
          )
          const content = item.current?.querySelector('.htmlContent')
          if (headline instanceof HTMLElement) {
            headline.style.height = 'auto'
          }
          if (content instanceof HTMLElement) {
            content.style.height = 'auto'
          }
        })

        const headlineHeights = refs.current.map(
          item =>
            item.current?.querySelector('.htmlContent > div > h3')
              ?.clientHeight ?? 0
        )
        const biggestHeadlineHeight = Math.max(...headlineHeights)

        // Set all headlines to the height of the tallest headline
        refs.current.forEach(item => {
          const headline = item.current?.querySelector(
            '.htmlContent > div > h3'
          )
          if (headline instanceof HTMLElement) {
            headline.style.height = `${biggestHeadlineHeight}px`
          }
        })

        // After setting the headline height, calculate the content height
        const contentHeights = refs.current.map(
          item => item.current?.querySelector('.htmlContent')?.clientHeight ?? 0
        )
        const biggestContentHeight = Math.max(...contentHeights)

        // Set all contents to the height of the tallest content
        refs.current.forEach(item => {
          const content = item.current?.querySelector('.htmlContent')
          if (content instanceof HTMLElement) {
            content.style.height = `${biggestContentHeight}px`
            content.style.display = 'flex'
            content.style.flexDirection = 'column'
            content.style.justifyContent = 'space-between'
          }
        })
      }
    }

    calculateHeights()

    window.addEventListener('resize', calculateHeights)

    return () => {
      window.removeEventListener('resize', calculateHeights)
    }
  }, [refs, matchHeights])

  return (
    <Box
      className={classNames(
        'contentArea',
        contain && 'contain',
        spacing && 'spacing',
        backgroundColor &&
          `bg${getBackgroundOrForegroundColor(backgroundColor)}`,
        foregroundColor &&
          `fg${getBackgroundOrForegroundColor(foregroundColor)}`,
        `contentArea--padding${getPadding(padding)}`
      )}
    >
      <Box
        className={classNames('contentArea-container')}
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridGap={isMobile ? `${spacingGap} 0` : spacingGap}
      >
        {(title || description) && (
          <div className="contentArea-heading-container">
            {title && <h2 className="contentArea-heading">{title}</h2>}
            {description && (
              <p
                className={classNames(
                  'contentArea-description',
                  backgroundColor === 2 && 'description-white'
                )}
              >
                {description}
              </p>
            )}
          </div>
        )}

        {isMobile &&
        displayAsCarouselMobile &&
        sectionBlock[0].type === 'BrandCardBlock' ? (
          <ImageBannerCarousel
            brandCard={sectionBlock}
            displayAsCarouselMobile={displayAsCarouselMobile}
          />
        ) : (
          sectionBlock.map((block, i) => {
            if (!block) return null
            const element = getBlockToRender(
              block,
              backgroundColor,
              foregroundColor,
              isTopContent,
              locale
            )
            return (
              <Box
                key={i}
                {...(matchHeights && { ref: refs.current[i] })}
                className={classNames(
                  'contentArea-content',
                  block.type === 'Container_ArticlePage'
                    ? 'contentArea-content--12'
                    : `contentArea-content${getDisplayOption(
                        block.displayOptionTag
                      )}`
                )}
              >
                {element}
              </Box>
            )
          })
        )}
        {menu && menu.length > 0 && (
          <Box
            className={classNames(
              'contentMenu',
              'contentArea-content',
              'contentArea-content--3'
            )}
          >
            <ContentMenu menu={menu} />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default ContentArea
