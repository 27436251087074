import { createSelector } from 'reselect'
import { convertOrdersToLinkListItems } from './orders.utils'

export const getAllOrders = state => state.customerOrders.orders

export const getOrdersAsLinkListItem = createSelector(
  getAllOrders,
  _orders => convertOrdersToLinkListItems(_orders)
)

export const getCurrentOrder = state => state.customerOrders.currentOrderDetails

export const getUserOrders = state => state.customerOrders.orders
