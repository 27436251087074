import React from 'react'
import Box from '@material-ui/core/Box'

import './nlgBlock.scss'

type IconListProps = {
  icons: string[]
}

const IconListComponent = ({ icons }: IconListProps) => {
  return (
    <Box className="icon-list">
      {icons.map((icon, index) => (
        <Box width="67px" height="67px" display="flex" alignItems="center">
          <img key={index} src={icon} alt="icon" className="icon-list__icon" />
        </Box>
      ))}
    </Box>
  )
}

export default IconListComponent
