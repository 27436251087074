import React, { useState, useEffect } from 'react'
import { ContainerArticlePageProps } from './types'
import { Grid, Box, Button, Typography, Drawer } from '@material-ui/core'
import './filter.scss'
import ContentCard from '../../components/ContentCard/ContentCard'
import { ArticleCardProps } from './types'
import { makeStyles } from '@material-ui/core/styles'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import FilterMobile from './FilterMobile'

import FilterSortBy from './FilterSortBy'
import Pagination from './Pagination'
import FilterComponent from './FilterComponent'

type Props = {
  props: ContainerArticlePageProps
}

const ContainerArticlePage = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [
    categoryAnchorEl,
    setCategoryAnchorEl
  ] = React.useState<null | HTMLElement>(null)
  const [brandAnchorEl, setBrandAnchorEl] = React.useState<null | HTMLElement>(
    null
  )

  const [category, setCategory] = useState<number[]>()
  const [brand, setBrand] = useState<number[]>()
  const [sort, setSort] = useState<number>(0)
  const [isClicked, setIsClicked] = useState(false)
  const [isBrandClicked, setIsBrandClicked] = useState(false)
  const [isCategoryClicked, setIsCategoryClicked] = useState(false)
  const [filterResponse, setFilterResponse] = useState<ArticleCardProps[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [results, setResults] = useState<number>(props.props.results)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const classes = useStyles()
  const isMobile = useMediaQuery('(max-width:600px)')
  const itemsPerPage = isMobile ? 4 : 12

  const toggleMobileFilter = (open: boolean) => {
    setDrawerOpen(open)
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setIsClicked(true)
  }

  const handleCategoryClick = (event: React.MouseEvent<HTMLElement>) => {
    setCategoryAnchorEl(event.currentTarget)
    setIsCategoryClicked(true)
  }

  const handleBrandClick = (event: React.MouseEvent<HTMLElement>) => {
    setBrandAnchorEl(event.currentTarget)
    setIsBrandClicked(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setCategoryAnchorEl(null)
    setBrandAnchorEl(null)
    setIsClicked(false)
    setIsBrandClicked(false)
    setIsCategoryClicked(false)
  }

  const handleChange = (name: string) => (
    event: React.ChangeEvent<{ value: unknown }> | number
  ) => {
    let value: unknown

    if (typeof event === 'number') {
      value = event
    } else {
      value = event.target.value
    }

    switch (name) {
      case 'category':
        setCategory((prev = []) => {
          if (prev.includes(value as number)) {
            return prev.filter(v => v !== value)
          } else {
            return [...prev, value as number]
          }
        })
        break
      case 'brand':
        setBrand((prev = []) => {
          if (prev.includes(value as number)) {
            return prev.filter(v => v !== value)
          } else {
            return [...prev, value as number]
          }
        })
        break
      case 'sort':
        setSort(Number(value))
        handleClose()
        break
      default:
        break
    }
  }

  useEffect(() => {
    filterArticles()
  }, [category, brand, sort, currentPage])

  const filterArticles = async () => {
    const skip = (currentPage - 1) * itemsPerPage
    const take = itemsPerPage

    try {
      const response = await fetch(
        '/api/SearchArticles/GetArticlePagesFromFilter',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Locale': props.props.locale
          },
          body: JSON.stringify({
            searchParameters: {
              skip: skip,
              take: take,
              pageNodeIds: category,
              brands: brand,
              sort: sort
            }
          })
        }
      )
      const data = await response.json()

      if (data) {
        setFilterResponse(data.properties)
        setResults(data.results)
        setTotalPages(Math.ceil(data.results / itemsPerPage))
      }
    } catch (error) {
      console.error('An error occurred:', error)
    }
  }

  return (
    <Box className="filter-container">
      <Grid container style={{ width: '100%', marginTop: '-20px' }}>
        <Grid item xs={12}>
          <Box mb={2}>
            <Typography
              style={{ textTransform: 'lowercase', fontSize: '12px' }}
            >
              {results} {props.props.gridTranslations.results}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            {isMobile ? (
              <>
                <Button
                  className={classes.mobileFilterButton}
                  onClick={() => toggleMobileFilter(true)}
                >
                  {props.props.gridTranslations.filters}
                </Button>
                <Drawer
                  anchor="right"
                  open={drawerOpen}
                  onClose={() => toggleMobileFilter(false)}
                >
                  <FilterMobile
                    handleClose={() => toggleMobileFilter(false)}
                    categoriesByPageNode={props.props.categoriesByPageNode}
                    gridTranslations={props.props.gridTranslations}
                    handleChange={handleChange}
                    category={category ? category : []}
                    brandsByEnum={props.props.brandsByEnum}
                    brand={brand ? brand : []}
                  />
                </Drawer>
              </>
            ) : (
              <Box width="214px" display="flex" justifyContent="space-between">
                <FilterComponent
                  items={props.props.categoriesByPageNode}
                  handleChange={handleChange}
                  selectedItems={category ? category : []}
                  isClicked={isCategoryClicked}
                  handleClick={handleCategoryClick}
                  handleClose={handleClose}
                  anchorEl={categoryAnchorEl}
                  filterName={props.props.gridTranslations.category}
                  filterType="category"
                />

                <FilterComponent
                  items={props.props.brandsByEnum}
                  handleChange={handleChange}
                  selectedItems={brand ? brand : []}
                  isClicked={isBrandClicked}
                  handleClick={handleBrandClick}
                  handleClose={handleClose}
                  anchorEl={brandAnchorEl}
                  filterName={props.props.gridTranslations.brand}
                  filterType="brand"
                />
              </Box>
            )}
            <FilterSortBy
              isClicked={isClicked}
              handleClick={handleClick}
              handleClose={handleClose}
              anchorEl={anchorEl}
              sort={sort}
              handleChange={handleChange}
              gridTranslations={props.props.gridTranslations}
              sortByEnum={props.props.sortByEnum}
            />
          </Box>
          <Box width="100%" pt={3}>
            <Grid container spacing={!isMobile ? 3 : 0}>
              {(filterResponse && filterResponse.length > 0
                ? filterResponse
                : props.props.properties
              ).map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  key={index}
                  style={{ margin: isMobile ? '8px 0px 8px 0px' : '' }}
                >
                  <ContentCard item={item.properties} index={index} />
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box height="32px" pt={3} pb={12}>
            {(isMobile ? results > 4 : results > 12) && (
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
                gridTranslations={props.props.gridTranslations}
                classes={classes}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  mobileFilterButton: {
    width: '50%',
    fontSize: '16px',
    marginRight: '4px'
  },
  paper: {
    '& .MuiPopover-root .MuiPopover-paper': {
      display: 'flex',
      width: '217px',
      padding: '16px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      borderRadius: '2px',
      border: '1px solid  #FAFAFA'
    }
  }
}))

export default ContainerArticlePage
