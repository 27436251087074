import React from 'react'
import BaseInput from '../BaseInput'
import './honeypot-input.scss'

Honeypot.propTypes = BaseInput.propTypes

export default function Honeypot(baseInputProps) {
  return (
    <div className="nl-ewrapper-one-1 hide">
      <span className="spanwrapper-1">
        <BaseInput
          autoComplete="off"
          type="text"
          className="form__input-1"
          {...baseInputProps}
        />
      </span>
    </div>
  )
}
