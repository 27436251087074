import { Box, BoxProps, Divider } from '@material-ui/core'
import React from 'react'
import MenuFooter from '../Header/MobileHeader/MenuFooter'
import {
  HeaderNavigationLinks,
  MegaMenuFooterBlock,
  MegaMenuLinks
} from 'src/blocks/Header/types'
import '../Header/MobileHeader/mobileHeader.scss'
import { classNames } from 'Core/utils/classNames'

export type MegaMenuContentContainerProps = BoxProps & {
  navigationItem: MegaMenuLinks[] | null
  handleSelectedNavigationItem: (item: MegaMenuLinks) => void
  activeNavigationItem: number
  isOpen?: boolean
  containerProps?: BoxProps
  menuFooterBlock?: MegaMenuFooterBlock[]
  menuDisplacement?: { x: number; y: number }
  isSearch: boolean
  translateContent: number
}

const MegaMenuContentContainer = ({
  navigationItem,
  handleSelectedNavigationItem,
  activeNavigationItem,
  isOpen,
  children,
  margin,
  padding,
  containerProps,
  menuFooterBlock,
  menuDisplacement,
  isSearch,
  translateContent = 0,
  ...props
}: MegaMenuContentContainerProps) => {
  const MegaMenuContentFooter = ({ isSearch }: { isSearch?: boolean }) => {
    return (
      <Box style={{ paddingLeft: isSearch ? '22px' : '' }}>
        <Divider
          className="menuContent__divider"
          style={{
            transform: `translateX(${translateContent}px)`,
            marginLeft: '20px',
            padding: '0 20px',
            width: 'calc(100% - 40px)'
          }}
        />
        <Box
          className="menuContent__footerContainer"
          style={{
            transform: `translateX(${translateContent}px)`,
            marginLeft: '12px'
          }}
        >
          {menuFooterBlock &&
            menuFooterBlock.map(item => (
              <MenuFooter
                heading={item.heading}
                description={item.description}
                imageUrl={item.imageUrl}
                link={item.link}
              />
            ))}
        </Box>
      </Box>
    )
  }

  return (
    <Box
      display="flex"
      style={{
        transform: isOpen ? 'scale(1)' : 'scale(0)'
      }}
      {...props}
    >
      <Box
        py="40"
        px="40"
        height="100%"
        display="flex"
        mx="auto"
        width="100%"
        maxWidth="100%"
        {...containerProps}
      >
        {(isSearch && (
          <>
            <Box className="boxleft" />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-start"
              width={1.0}
            >
              {children}
              <MegaMenuContentFooter isSearch />
            </Box>
          </>
        )) || (
          <>
            <Box className="boxleft">
              <Box
                position="relative"
                display="flex"
                flexDirection={'column'}
                gridGap="16px"
                className="megamenu-linkitemcontainer"
              >
                {navigationItem &&
                  navigationItem.map((navItem, i) => {
                    return (
                      <Box
                        component="div"
                        display="flex"
                        height="26px"
                        onMouseOver={e => handleSelectedNavigationItem(navItem)}
                      >
                        <span
                          key={i}
                          className={classNames(
                            'navigation-categories',
                            activeNavigationItem === i ? 'active' : null
                          )}
                        >
                          {navItem.title}
                        </span>
                      </Box>
                    )
                  })}
              </Box>
              {navigationItem && navigationItem.length > 1 && (
                <Divider
                  className="menuContent__divider"
                  orientation="vertical"
                />
              )}
            </Box>
            <Box className="boxright">
              <Box>{children}</Box>
              <MegaMenuContentFooter />
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}

export default MegaMenuContentContainer
