import React from 'react'
import {
  MegaMenuFooterBlock,
  LanguageSelections
} from '../../blocks/Header/types'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import MenuHeader from '../../blocks/Header/MobileHeader/MenuHeader'
import MenuFooter from '../../blocks/Header/MobileHeader/MenuFooter'
import '../../../src/blocks/Header/MobileHeader/mobileHeader.scss'
import ListItemLink from './ListItemLink'
import { Typography } from '@material-ui/core'
import MenuLanguagePickerTabs from './MobileLanguagePickerTabs'

type MobileLanguagePickerProps = {
  setLanguagePickerOpen?: (arg: boolean) => void
  megaMenuFooterBlocks: MegaMenuFooterBlock[]
  languageSelections: LanguageSelections
  isLanguagePickerOpen: boolean
}

const MobileLanguagePicker = ({
  megaMenuFooterBlocks,
  languageSelections,
  setLanguagePickerOpen
}: MobileLanguagePickerProps) => {
  return (
    <>
      <Box className="menuContent">
        <Box className="menuContent__listWrapper" component="nav">
          <MenuHeader setLanguagePickerOpen={setLanguagePickerOpen} />
          <Typography className="menuContent__languageTitle">
            {languageSelections.title}
          </Typography>
          <Box className="tabContainer">
            <List className="tabContainer__languageList">
              {Array.isArray(languageSelections.additionalLanguageSelections) &&
              languageSelections.additionalLanguageSelections.length > 0 ? (
                <MenuLanguagePickerTabs
                  languageSelections={languageSelections}
                />
              ) : (
                Array.isArray(
                  languageSelections.fullVersionLanguageSelections
                ) &&
                languageSelections.fullVersionLanguageSelections.map(item => (
                  <ListItemLink
                    imageUrl={item.imageUrl}
                    displayName={item.displayName}
                    urlTarget={item.urlTarget}
                  />
                ))
              )}
            </List>
          </Box>
        </Box>
      </Box>
      <Divider className="menuContent__divider" />
      <Box className="menuContent__footerContainer">
        {megaMenuFooterBlocks.map(item => (
          <MenuFooter
            heading={item.heading}
            description={item.description}
            imageUrl={item.imageUrl}
            link={item.link}
          />
        ))}
      </Box>
    </>
  )
}

export default MobileLanguagePicker
