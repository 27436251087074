import React from 'react'
import EpiFormInputTypes from '../../constants/EpiFormInputTypes'
import {
  transformChoiceItemsToOptions,
  transformSelectionItemsToOptions,
  transformSubSelectionItemsToOptions
} from '../../utils/epiForms'

import DropDownInput from '../_inputs/DropDownInput'
import EmailInput from '../_inputs/EmailInput'
import FileUploadInput from '../_inputs/FileUploadInput'
import MultipleCheckboxesInput from '../_inputs/MultipleCheckboxesInput'
import NumberInput from '../_inputs/NumberInput'
import RadioButtonsInput from '../_inputs/RadioButtonsInput'
import TextAreaInput from '../_inputs/TextAreaInput'
import TextInput from '../_inputs/TextInput'
import Honeypot from '../_inputs/Honeypot'

import InvalidInput from './_epi-inputs/InvalidInput'
import SubSelectionInput from './_epi-inputs/SubSelectionInput'

export function getInputComponent(formElement, formElementProps) {
  const { inputType } = formElement
  const validators = (formElement.validators || '').toLowerCase()

  switch (inputType) {
    case EpiFormInputTypes.ChoiceElementBlock:
      if (formElement.allowMultiSelect) {
        return MultipleCheckboxesInput
      } else {
        return RadioButtonsInput
      }

    case EpiFormInputTypes.FileUploadElementBlock:
      return FileUploadInput

    case EpiFormInputTypes.NumberElementBlock:
      return NumberInput

    case EpiFormInputTypes.SelectionElementBlock:
    case EpiFormInputTypes.CountrySelectionElementBlock:
      return DropDownInput

    case EpiFormInputTypes.SubSelectionElementBlock:
      if (!formElementProps.options) {
        return null
      }
      return SubSelectionInput

    case EpiFormInputTypes.TextareaElementBlock:
      return TextAreaInput

    case EpiFormInputTypes.TextboxElementBlock:
      if (validators.indexOf('email') !== -1) {
        return EmailInput
      } else {
        return TextInput
      }

    case EpiFormInputTypes.HoneypotInput:
      return Honeypot

    default:
      return function CurriedInvalidInput() {
        return (
          <InvalidInput
            formElement={formElement}
            formElementProps={formElementProps}
          />
        )
      }
  }
}

export function getElementComponentProps(formElement, inputProps) {
  const { inputType } = formElement

  switch (inputType) {
    case EpiFormInputTypes.ChoiceElementBlock:
      return {
        options: transformChoiceItemsToOptions(formElement.items)
      }

    case EpiFormInputTypes.FileUploadElementBlock:
      return {
        accept: formElement.fileTypes,
        multiple: formElement.allowMultiple === true
      }

    case EpiFormInputTypes.CountrySelectionElementBlock:
    case EpiFormInputTypes.SelectionElementBlock:
      return {
        options: transformSelectionItemsToOptions(formElement.items)
      }

    default:
      return null
  }
}

export function getElementComponentDynamicProps(formElement, parentState) {
  const { inputType } = formElement

  switch (inputType) {
    case EpiFormInputTypes.SubSelectionElementBlock: {
      if (!parentState) {
        return null
      }

      const parentValue = parentState.value

      return {
        options: transformSubSelectionItemsToOptions(
          formElement.items,
          parentValue
        )
      }
    }

    default:
      return null
  }
}
