import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import ArrowRight from '../../../../Spa.Core/components/_svg/ArrowRight'
import Search from '../../../../Spa.Core/components/_svg/Search'
import SearchService from '../../../../Brand/js/services/SearchService'
import React, { useRef, useState } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { useMediaQuery } from '@material-ui/core'
import './megamenu.scss'
import { debounce } from '../../utils/debounce'
import { SearchSettingsBlock } from '../Header/types'
import { HighlightedQuery } from '../Utils/HighlightedQuery'

const QUERY_MIN_LENGTH = 3

type SearchProps = {
  searchSettingsBlock?: SearchSettingsBlock
}

type QuickResult = {
  date: Date
  imageUrl: string
  isFile: boolean
  text: string
  title: string
  typeName: string
  url: string
}

const MenuSearch = ({ searchSettingsBlock }: SearchProps) => {
  const [query, setQuery] = useState<string>('')
  const [searchResults, setSearchResults] = useState<QuickResult[]>([])
  const isMobile = useMediaQuery('(max-width: 600px)')

  const autoComplete = useRef(
    debounce(async (query: string) => {
      const brandIds = ''
      const catIds = ''
      const pageNumber = 0
      const pageSize = 3
      const request = SearchService.search(
        query,
        brandIds,
        catIds,
        pageNumber,
        pageSize
      )
      const response = await request.catch(() => ({ items: [] }))
      setSearchResults(response.items)
    }, 500)
  ).current

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = e.target.value
    setQuery(newQuery)
    if (newQuery.length < QUERY_MIN_LENGTH) {
      return
    }

    autoComplete(newQuery)
  }

  const handleQuerySubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (MediaQueryList.length > QUERY_MIN_LENGTH) {
      return
    }

    const urlQuery = encodeURIComponent(query.trim())

    window.location.href = `${searchSettingsBlock?.internalUrl}?q=${urlQuery}&query=${urlQuery}&brandIds=&catIds=`
  }

  const handleOnFocus = () => {
    // console.log('handleOnFocus')
  }

  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      height={1.0}
      width={isMobile ? '650px' : '70%'}
      maxWidth="100%"
      className="megaMenu-searchContainer"
    >
      <form onSubmit={handleQuerySubmit} role="search">
        <TextField
          className="search-input"
          autoFocus
          value={query}
          fullWidth
          onChange={handleQueryChange}
          onFocus={handleOnFocus}
          aria-owns="search-autocomplete"
          id="input-with-icon-textfield"
          placeholder={searchSettingsBlock?.searchPlaceHolder}
          style={{
            width: '100%'
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search color="#000000" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <ArrowRight onClick={handleQuerySubmit} />
              </InputAdornment>
            )
          }}
          variant="standard"
        />
      </form>
      {searchResults && searchResults.length > 0 && (
        <>
          <Box mt={isMobile ? '48px' : '38px'}>
            <Typography className="search-results-title">
              {searchSettingsBlock?.quickResultsText}
            </Typography>
          </Box>
          <Box component="div" className="search-results" mt={3}>
            {searchResults.map((item, i) => {
              return (
                <a href={item.url} key={i}>
                  <HighlightedQuery text={item.title} highlight={query} />
                </a>
              )
            })}
          </Box>
        </>
      )}
    </Box>
  )
}

export default MenuSearch
