import React from 'react'

const SuperBoltBrandInvert = () => (
  <svg className="icon">
    <use
      xlinkHref={`/Static/Images/icons.svg?=${process.env.BUILD_VERSION_ID}#icon--super-bolt-brand-invert`}
    />
  </svg>
)

export default SuperBoltBrandInvert
