import React from 'react'
import PropTypes from 'prop-types'
import BaseInput from '../BaseInput'
import RadioButton from '../../_elements/RadioButton'
import { classNames, addChildClassName } from '../../../utils/classNames'
import './radio-buttons-input.scss'

RadioButtonsInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,

  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      disabled: PropTypes.bool
    })
  ),

  className: PropTypes.string
}

export default function RadioButtonsInput({
  name,
  value,
  disabled = false,

  options,

  className = '',

  ...baseInputProps
}) {
  return (
    <ul className={classNames('radio-buttons', className)}>
      {options.map(option => (
        <li
          key={option.value}
          className={classNames(
            'radio-buttons__item',
            addChildClassName(className, 'item')
          )}
        >
          <RadioButton
            className={classNames(
              'radio-buttons__item-radio-button',
              addChildClassName(className, 'radio-button')
            )}
            element={BaseInput}
            name={name}
            value={option.value}
            checked={option.value === value}
            disabled={disabled || options.disabled}
            {...baseInputProps}
          >
            {option.name}
          </RadioButton>
        </li>
      ))}
    </ul>
  )
}
