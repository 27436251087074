import { useEffect, useState, useMemo } from 'react'
import { Order } from '../../modules/orders/orders.types'

export function useLinkList(orders: Order[]) {
  const incrementCount = 5
  const initialShowCount = 5

  const [showOrdersCount, setShowOrdersCount] = useState(initialShowCount)
  const [isLoading, setIsLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const canLoadMore = orders.length > showOrdersCount && !isLoading

  let loadMoreTimeoutId: ReturnType<typeof setTimeout>

  const slicedOrders = useMemo(() => orders.slice(0, showOrdersCount), [
    orders,
    showOrdersCount
  ])

  const filteredAndSlicedOrders = useMemo(
    () =>
      slicedOrders.filter(order => {
        const searchValueEscaped = searchValue.replace('#', '').toLowerCase()

        return order.title.toLowerCase().includes(searchValueEscaped)
      }),
    [slicedOrders, searchValue]
  )

  useEffect(() => {
    return () => {
      clearTimeout(loadMoreTimeoutId)
      setIsLoading(false)
    }
  }, [orders])

  function onSearchChange(searchTerm: string) {
    setSearchValue(searchTerm)
  }

  function loadMore() {
    setIsLoading(true)

    loadMoreTimeoutId = setTimeout(() => {
      setShowOrdersCount(showOrdersCount + incrementCount)
      setIsLoading(false)
    }, 1000)
  }

  return {
    orders: filteredAndSlicedOrders,
    searchValue,

    canLoadMore,
    isLoading,

    loadMore,
    onSearchChange
  }
}
