import React from 'react'
import PropTypes from 'prop-types'

import './label-with-value.scss'

const LabelWithValue = ({ label, value, className }) => {
  return (
    <div className={`label-with-value ${className || ''}`}>
      <span className="label-with-value__label">{label}: </span>
      <span className="label-with-value__value">{value}</span>
    </div>
  )
}

LabelWithValue.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string
}

export default LabelWithValue
