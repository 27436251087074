import React from 'react'
import Button from '@material-ui/core/Button'
import { ListItem, Typography } from '@material-ui/core'
import classNames from 'classnames'

type ListItemProps = {
  imageUrl: string
  displayName: string
  urlTarget: string
  className?: string
}

const ListItemLink = ({
  imageUrl,
  displayName,
  urlTarget,
  className
}: ListItemProps) => {
  return (
    <ListItem style={{ padding: '8px 0px' }}>
      <Button className={classNames('list_item', className)} href={urlTarget}>
        {imageUrl && <img className="list_item__img" src={imageUrl} />}
        <Typography
          className="list_item__country"
          style={{
            textAlign: 'left',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          {displayName}
        </Typography>
      </Button>
    </ListItem>
  )
}

export default ListItemLink
