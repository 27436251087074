import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReorderProductList from '../../blocks/OrderListing/OrderDetails/ReorderProductList'
// import DatePicker from '../DatePicker'
import Button from '../Button'
import { requestQuote } from '../../modules/orders/orders.fetch'

import './quote-request.scss'
import Loader from '../Loader'

class QuoteRequest extends Component {
  static propTypes = {
    currentOrderDetails: PropTypes.shape({
      orderNumber: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          description: PropTypes.string,
          quantity: PropTypes.number.isRequired,
          imageUrl: PropTypes.string,
          trackUrl: PropTypes.string,
          productUrl: PropTypes.string,
          confirmedShippingDate: PropTypes.string,
          invoiceNumber: PropTypes.string
        })
      ).isRequired
    }).isRequired,
    salesRepresentative: PropTypes.object,
    locale: PropTypes.string.isRequired,
    moduleId: PropTypes.number.isRequired,
    sendQuoteText: PropTypes.string.isRequired,
    quoteQuestion: PropTypes.string.isRequired,
    quoteInformation: PropTypes.string.isRequired,
    quoteSuccessTitle: PropTypes.string.isRequired,
    quoteSuccessInformation: PropTypes.string.isRequired,
    quoteErrorMessage: PropTypes.string.isRequired,
    quoteRequestedDeliveryDateLabel: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props)
    const { currentOrderDetails } = props

    this.state = {
      requestedDateForDelivery: new Date(),
      items: currentOrderDetails.items.map(x => ({
        name: x.name,
        quantity: x.quantity,
        itemNumber: x.itemNumber
      })),
      quoteSubmitted: false,
      quoteError: false,
      isLoading: false
    }
  }

  onChangeDate = newDate => {
    this.setState({ requestedDateForDelivery: newDate })
  }

  onChangeItem = ({ name, quantity }) => {
    let arr = this.state.items
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].name === name) {
        arr[i].quantity = quantity
        this.setState({ items: arr })
        return
      }
    }
  }

  onSumbit = () => {
    const { locale, moduleId, currentOrderDetails } = this.props
    const { orderNumber } = currentOrderDetails
    const { items, requestedDateForDelivery } = this.state

    this.setState({ isLoading: true })

    setTimeout(() => {
      this.setState({ isLoading: false })
    }, 1500)

    requestQuote(
      locale,
      orderNumber,
      moduleId,
      requestedDateForDelivery,
      items
    ).then(
      () => {
        this.setState({ quoteSubmitted: true })
      },
      () => {
        this.setState({ quoteError: true })
      }
    )
  }

  addSaleRepresentativeText = (qouteInformation, saleRepresentative) => {
    if (qouteInformation) {
      return qouteInformation.replace(
        '{salesRepresentative}',
        saleRepresentative
      )
    }
    return qouteInformation
  }

  render() {
    const {
      quoteQuestion,
      quoteInformation,
      quoteSuccessTitle,
      quoteSuccessInformation,
      sendQuoteText,
      quoteErrorMessage,
      salesRepresentative,
      quoteRequestedDeliveryDateLabel
    } = this.props

    const { quoteSubmitted, quoteError, isLoading } = this.state

    return (
      <div className="quote-request">
        <div className="quote-request-wrapper">
          <div className="quote-request-products-container">
            <ReorderProductList onChange={this.onChangeItem} />
          </div>
          <div className="quote-request-submit-container">
            <div className="quote-request-submit-container__text">
              {isLoading ? (
                <Loader />
              ) : quoteError ? (
                <span className="quote-request-error">{quoteErrorMessage}</span>
              ) : quoteSubmitted ? (
                <div className="quote-request-success">
                  <h3>{quoteSuccessTitle}</h3>
                  <span>{quoteSuccessInformation}</span>
                </div>
              ) : (
                <div>
                  <h3>{quoteQuestion}</h3>
                  <p>
                    {this.addSaleRepresentativeText(
                      quoteInformation,
                      salesRepresentative ? salesRepresentative.name : ''
                    )}
                  </p>
                </div>
              )}
            </div>
            <div className="quote-request-submit-container__submit">
              {/* Only uncommented here because it might be used again */}

              {/* <span className="quote-request-submit-container__submit-label">
                {quoteRequestedDeliveryDateLabel}
              </span>
              <DatePicker
                date={this.state.requestedDateForDelivery}
                onChange={this.onChangeDate}
                className="quote-request-submit-container__submit-date-picker"
              /> */}

              <Button
                className="btn-small quote-request-submit-container__submit-button"
                onClick={this.onSumbit}
              >
                {sendQuoteText}
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default QuoteRequest
