import { connect } from 'react-redux'
import RegularProductList from '../../../../components/ProductList/RegularProductList'
import { getTranslation } from '../../../../modules/translations/translations.utils'

const mapStateToProps = ({ customerOrders, translations }) => {
  const portalOrderListingTranslations =
    translations.commonTranslations.portalOrderListingTranslationsBlock

  return {
    products: customerOrders.currentOrderDetails.items.map(product => ({
      product
    })),
    title: getTranslation(portalOrderListingTranslations, 'orderContents')
  }
}

export default connect(mapStateToProps)(RegularProductList)
