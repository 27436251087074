import React, { useState } from 'react'
import BaseInput from '../BaseInput'
import { classNames, addChildClassName } from '../../../utils/classNames'
import { DropDownInputProps } from './DropDownInput.types'

export default function DropDownInput({
  options,
  value,
  groups,
  icon: Icon,
  className,
  onChange,
  ...baseInputProps
}: DropDownInputProps) {
  const baseInputValue = value === null ? '' : value

  function onBaseInputChange(
    elementValue: string,
    event: React.ChangeEvent<HTMLSelectElement>
  ) {
    if (onChange) {
      const { selectedIndex } = event.target
      const optionValue = options[selectedIndex].value
      onChange(optionValue, event)
    }
  }

  function renderOptions(group: string | null = null) {
    const filteredOptions =
      group === null
        ? options
        : options.filter(option => option.group === group)

    return filteredOptions.map(option => (
      <option key={option.value} value={option.value}>
        {option.name}
      </option>
    ))
  }

  return (
    <div className={classNames('drop-down', className)}>
      <BaseInput
        className={classNames(
          'drop-down__select',
          addChildClassName(className, 'select')
        )}
        element="select"
        value={baseInputValue}
        onChange={onBaseInputChange}
        {...baseInputProps}
      >
        {groups && groups.length
          ? groups.map(group => (
              <optgroup key={group.value} label={group.name}>
                {renderOptions(group.value)}
              </optgroup>
            ))
          : renderOptions()}
      </BaseInput>

      {Icon && (
        <Icon
          className={classNames(
            'drop-down__icon',
            addChildClassName(className, 'icon')
          )}
        />
      )}
    </div>
  )
}
