import React, { useState } from 'react'
import PropTypes from 'prop-types'
import IconButton from '../../IconButton'
import DefaultProductInfo from '../ProductInfo/DefaultProductInfo'
import MaterialArrowDownSvg from '../../_svg/MaterialArrowDown'
import MaterialArrowUpSvg from '../../_svg/MaterialArrowUp'

import './expandable-product-item.scss'

const ProductItem = ({
  product,
  detailsComponentArgs,
  DetailsComponent,
  viewTranslation,
  hideTranslation
}) => {
  const isExpandable = !!DetailsComponent

  // States
  const [isExpanded, toggleExpand] = useState(false)

  return (
    <li className="expandable-product-item">
      <div className="expandable-product-item__container">
        <DefaultProductInfo {...product} showDate={true} />
        {isExpandable ? (
          <div className="expandable-product-item__action">
            <IconButton
              SvgIcon={MaterialArrowDownSvg}
              ToggledSvgIcon={MaterialArrowUpSvg}
              ToggledText={hideTranslation}
              onClick={() => toggleExpand(!isExpanded)}
            >
              {viewTranslation}
            </IconButton>
          </div>
        ) : (
          ''
        )}
      </div>
      <div
        className={`expandable-product-item__expand-container
          ${
            isExpanded
              ? 'expandable-product-item__expand-container--active'
              : ''
          }`}
      >
        {isExpandable ? <DetailsComponent {...detailsComponentArgs} /> : ''}
      </div>
    </li>
  )
}

ProductItem.propTypes = {
  viewTranslation: PropTypes.string,
  hideTranslation: PropTypes.string,
  DetailsComponent: PropTypes.func,
  detailsComponentArgs: PropTypes.object,
  product: PropTypes.shape({
    imageUrl: PropTypes.string,
    name: PropTypes.string,
    quantity: PropTypes.number,
    confirmedShippingDate: PropTypes.string,
    link: PropTypes.string
  })
}

export default ProductItem
