import { LOAD_INITIAL_HTML_STARTUP_DATA } from '../app/app.actions'

const initialState = {
  BUILD_VERSION_ID: null,
  currentLocale: null,
  availableCurrencies: {},
  commonTranslations: {},
  currentCurrency: {},
  pageModels: {}
}

export default function translationsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_INITIAL_HTML_STARTUP_DATA: {
      const { htmlStartupData } = action
      return {
        ...state,
        ...htmlStartupData
      }
    }

    default:
      return state
  }
}
