import React from 'react'

export default function AddIcon(svgProps) {
  return (
    <svg width="28px" height="28px" {...svgProps}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="m14.77 15.12 8.875.005-.001-1.5-8.874-.005-.005-8.264h-1.5l.005 8.263L5 13.614v1.5l8.27.005.006 8.88 1.5.001-.005-8.88Z"
          fill="#000"
        />{' '}
      </g>
    </svg>
  )
}
