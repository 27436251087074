import React from 'react'

const CaretRight = props => (
  <svg width="28px" height="36px" viewBox="0 0 28 36" fill="none" {...props}>
    <path
      d="m12.05 25.102 5.982-7.05-5.982-7.05"
      stroke="currentColor"
      stroke-width="1.5"
    />
  </svg>
)

export default CaretRight
