import React from 'react'

const NordLockGroupIcon = () => (
  <svg className="icon">
    <use
      xlinkHref={`/Static/Images/icons.svg?=${
        process.env.BUILD_VERSION_ID
      }#icon--nord-lock-group`}
    />
  </svg>
)

export default NordLockGroupIcon