import React, { useEffect, useRef } from 'react'
import './nlgBlock.scss'
import { classNames } from 'Core/utils/classNames'
import {
  BackgroundOrForegroundColorEnum,
  AlignmentEnum,
  PaddingEnum,
  getBackgroundOrForegroundColor,
  getPadding,
  getAlignment
} from '../../blocks/ContentArea/ContentArea'
import Box from '@material-ui/core/Box'
import RichTextBlock from '../../blocks/RichTextBlock'
import VideoBlock from '../../blocks/VideoBlock'
import { VideoBlockProps } from '../../blocks/VideoBlock/type'
import { TopHeroProps } from '../TopHero/types'
import HeroBlock from '../TopHero'

export type NlgBlockProps = {
  backgroundColor: BackgroundOrForegroundColorEnum
  contentAreaBgColor: BackgroundOrForegroundColorEnum
  textColor: NLGForegroundColor
  buttonAlignment: AlignmentEnum
  buttonText: string | null
  buttonUrl: string | null
  heroBlock: Pick<
    TopHeroProps,
    | 'ctaText'
    | 'ctaUrl'
    | 'description'
    | 'imageUrl'
    | 'mobileImageUrl'
    | 'isAutoPlay'
    | 'mobileVideoUrl'
    | 'textAlignment'
    | 'textColor'
    | 'videoUrl'
    | 'title'
    | 'fullWidth'
    | 'buttonVariant'
    | 'nlgHero'
  >
  imageUrl: string | null
  imageUrls: string[] | null
  padding: PaddingEnum
  text: string | null
  videoBlock: VideoBlockProps | null
  textAlignLeft: boolean
}

type Props = {
  props: NlgBlockProps
}

export type NLGForegroundColor = 0 | 1 | 2 | 3 | 4 | 5

export const NlgBlock = ({
  props: {
    contentAreaBgColor,
    backgroundColor,
    textColor,
    buttonAlignment,
    buttonText,
    buttonUrl,
    heroBlock,
    padding,
    text,
    videoBlock,
    imageUrl,
    imageUrls,
    textAlignLeft
  }
}: Props) => {
  const [videoContainerHeight, setVideoContainerHeight] = React.useState(0)
  const videoContainerRef = useRef<HTMLDivElement>(null)

  const formattedHeroProps = {
    ...heroBlock,
    fullWidth: true,
    nlgHero: true
  }

  useEffect(() => {
    if (videoContainerRef.current) {
      const height = videoContainerRef.current.offsetHeight
      setVideoContainerHeight(height)
    }
  }, [])

  return (
    <Box
      alignItems={textAlignLeft ? 'flex-start' : 'center'}
      className={classNames(
        'nlgblock',
        `nlgblock${getBackgroundOrForegroundColor(backgroundColor)}`,
        `nlgblock--padding${getPadding(padding)}`,
        `nlgblock--buttonAlignment${getAlignment(buttonAlignment)}`
      )}
    >
      {heroBlock?.imageUrl && heroBlock?.mobileImageUrl && (
        <HeroBlock prop={formattedHeroProps} />
      )}

      {heroBlock?.videoUrl && heroBlock?.mobileVideoUrl && (
        <HeroBlock prop={formattedHeroProps} />
      )}

      {imageUrl && (
        <img src={imageUrl} alt={'image'} className="nlgblock-image" />
      )}
      {(videoBlock?.videoUrl || videoBlock?.socialMediaUrl) && (
        <div ref={videoContainerRef} className="nlgblock-video">
          <VideoBlock
            props={videoBlock}
            videoContainerHeight={videoContainerHeight}
          />
        </div>
      )}
      {text && (
        <RichTextBlock
          props={{
            contentAreaBgColor: contentAreaBgColor,
            text: text,
            textAlignLeft: textAlignLeft,
            textColor: textColor,
            buttonUrl: buttonUrl,
            buttonText: buttonText,
            imageUrls: imageUrls,
            transparent: backgroundColor === 0 ? true : false
          }}
        />
      )}
      {buttonUrl && !buttonText && (
        <a href={buttonUrl} className="nlgblock--block-link" />
      )}
    </Box>
  )
}

export default NlgBlock
