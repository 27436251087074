import React from 'react'
import './flexContainerBlock.scss'
import { classNames } from 'Core/utils/classNames'
import Box from '@material-ui/core/Box'
import NlgBlock, { NlgBlockProps } from '../NlgBlock/NlgBlock'
import {
  DisplayOption,
  getDisplayOption
} from '../../blocks/ContentArea/ContentArea'

type FlexContainerSection = {
  type: 'NLGBlock'
  properties: NlgBlockProps
  displayOptionTag: DisplayOption
}

export type FlexContainerBlockProps = {
  contentArea: FlexContainerSection[]
}

type Props = {
  props: FlexContainerBlockProps
}

export const FlexContainerBlock = ({ props }: Props) => {
  const { contentArea } = props

  const displayOption = getDisplayOption(contentArea[0].displayOptionTag)

  return (
    <Box
      className={classNames(
        'flexContainer',
        displayOption === '0' && 'flexContent-nowrap'
      )}
    >
      {contentArea.map((block: FlexContainerSection, index: number) => {
        return (
          <Box
            key={index}
            className={classNames(
              'flexContent',
              `flexContent${getDisplayOption(block.displayOptionTag)}`
            )}
          >
            <NlgBlock props={block.properties} />
          </Box>
        )
      })}
    </Box>
  )
}

export default FlexContainerBlock
