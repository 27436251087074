import { connect } from 'react-redux'
import OrderLinkListItem from './OrderLinkListItem'
import { getOrderListingCommonTranslations } from '../../../modules/translations/translations.selectors'
import { getTranslation } from '../../../modules/translations/translations.utils'

const mapStateToProps = (state: object) => {
  const translations = getOrderListingCommonTranslations(state)

  return {
    orderLabel: getTranslation(translations, 'order'),
    placedOnLabel: getTranslation(translations, 'placedOn')
  }
}

export default connect(mapStateToProps)(OrderLinkListItem)
