import { connect } from 'react-redux'
import DefaultProductInfo from './DefaultProductInfo'
import { getOrderListingCommonTranslations } from '../../../../modules/translations/translations.selectors'
import { getTranslation } from '../../../../modules/translations/translations.utils'

const mapStateToProps = state => {
  const { customerOrders } = state

  return {
    itemsTranslation: getTranslation(
      getOrderListingCommonTranslations(state),
      'items'
    ),
    company: customerOrders.currentOrderDetails.orderType
  }
}

export default connect(mapStateToProps)(DefaultProductInfo)
