import React, { useState } from 'react'
import {
  MegaMenuFooterBlock,
  MegaMenuLinks,
  HeaderNavigationLinks
} from '../types'
import Box from '@material-ui/core/Box'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Link from '@material-ui/core/Link'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import MenuHeader from './MenuHeader'
import MenuFooter from './MenuFooter'
import './mobileHeader.scss'

type MenuLinkListProps = {
  megaMenuFooterBlocks: MegaMenuFooterBlock[]
  megaMenuBlocks: MegaMenuLinks[]
  navigationMenuBlocks: HeaderNavigationLinks
  setMainMenuOpen: (arg: boolean) => void
  onClick: (item: HeaderNavigationLinks) => void
  isSubMenuOpen: boolean
  setSubMenuOpen: (arg: boolean) => void
  history: string
} & TabPanelProps

type TabPanelProps = {
  children?: React.ReactNode
  index?: any
  value?: any
}

const MenuLinkList = ({
  history,
  setSubMenuOpen,
  isSubMenuOpen,
  megaMenuBlocks,
  megaMenuFooterBlocks,
  setMainMenuOpen
}: MenuLinkListProps) => {
  const [currentCategory, setCurrentCategory] = useState(0)

  const handleBackClick = () => {
    setSubMenuOpen(false)
    setMainMenuOpen(true)
  }

  const handleTabChange = (_e: any, newValue: number) => {
    setCurrentCategory(newValue)
  }

  return (
    <>
      <Box className="menuContent">
        <Box className="menuContent__listWrapper" component="nav">
          <MenuHeader
            onBackClick={handleBackClick}
            isSubMenuOpen={isSubMenuOpen}
            setMainMenuOpen={setMainMenuOpen}
            setSubMenuOpen={setSubMenuOpen}
            history={history}
          />

          <Box className="tabContainer">
            {megaMenuBlocks && megaMenuBlocks.length > 1 && (
              <Tabs value={currentCategory} onChange={handleTabChange} centered>
                {megaMenuBlocks.map((category, index) => (
                  <Tab
                    key={index}
                    label={category.title}
                    className="tabContainer__tab"
                  />
                ))}
              </Tabs>
            )}
            <List>
              {megaMenuBlocks &&
                megaMenuBlocks[currentCategory].megaMenuLinks?.map(
                  (item, index) => (
                    <ListItem key={index}>
                      <Link
                        href={item.href}
                        className="tabContainer__linkItems"
                      >
                        {item.text}
                      </Link>
                    </ListItem>
                  )
                )}
            </List>
          </Box>
        </Box>
      </Box>
      <Divider className="menuContent__divider" />
      <Box className="menuContent__footerContainer">
        {megaMenuFooterBlocks.map(item => (
          <MenuFooter
            heading={item.heading}
            description={item.description}
            imageUrl={item.imageUrl}
            link={item.link}
          />
        ))}
      </Box>
    </>
  )
}

export default MenuLinkList
