import React, { useState, ChangeEvent } from 'react'
import Box from '@material-ui/core/Box'
import { FooterSectionBlockProps } from './types'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { Typography, Link } from '@material-ui/core'
import AddIcon from '../../../../Spa.Core/components/_svg/AddIcon'
import ExpandedIcon from '../../../../Spa.Core/components/_svg/ExpandedIcon'

const FooterListMobile = ({ ...props }) => {
  const data = props.props as FooterSectionBlockProps

  const [expanded, setExpanded] = useState(false)

  const handleAccordionChange = (
    _event: ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded)
  }

  return (
    <Box className="footer-list-mobile">
      <Accordion onChange={handleAccordionChange}>
        <AccordionSummary
          expandIcon={expanded ? <ExpandedIcon /> : <AddIcon />}
        >
          <Typography className="footer-list-mobile__title">
            {data.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box className="footer-list-mobile__list">
            {data.links.map((link, i) => (
              <Link key={i} href={link.href}>
                {link.text}
              </Link>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default FooterListMobile
